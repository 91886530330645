import React, {useEffect, useState} from 'react';
import Navbar from '../components/globalComponents/navbar';
import Footer from '../components/globalComponents/footer';
import Banner from '../components/banner/subBannerProfile';
import User from '../components/myProfile/myProfile';
import constants from "../constants/constants";
import {createUser} from "../constants/commonFunc";
import * as services from "../service/auth";
import Cookies from "js-cookie";

const MyProfile = (props) => {

    useEffect(() => {
        document.querySelector('body').scrollTo(0, 0)
        updateCoverImage()
        if (Cookies.get(constants.ACCESS_TOKEN) !== undefined) {
            getUserDetails()
        }
    }, [])

    const [cover, setCover] = useState("")

    const updateCoverImage = () => {
        let user = JSON.parse(localStorage.getItem(constants.USER_OBJ));
        setCover(user.coverImage)
    }

    const getUserDetails = async () => {
        await services.getExpireData().then(async (res) => {
            await localStorage.setItem(constants.USER_OBJ, JSON.stringify(createUser(res.body)))
            await localStorage.setItem(constants.USER_ORIGINAL_OBJ, JSON.stringify(res.body))
        })
    }

    return <div>
        <Navbar/>
        <Banner cover={cover}/>
        <User history={props.history} location={props.location} updateCover={updateCoverImage}/>
        <Footer/>
    </div>
}

export default MyProfile
