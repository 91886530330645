import React, {Component} from 'react';
import '../globalComponents/globalComponents.scss'
import {Link} from 'react-router-dom';
import strings from "../../locals/string.json";
import constants, {breakPoint2, tenders} from "../../constants/constants";
import routes from "../../constants/routes";
import {Assets} from "../../assets";
import {checkAvailability, dateDiff, numberWithCommas} from "../../constants/commonFunc";
import {connect} from "react-redux";
import {withRouter} from "react-router";
import Slider from "react-slick";
import moment from "moment";
import {ToastUtil} from "../../constants/toastUtil";
import {getRequest} from "../../service/request";
import Text from "antd/es/typography/Text";

const landing = strings.landing;
const request = strings.purchaseRequest;

class LatestRequests extends Component {

    state = {
        requests: [],
    }

    componentWillMount() {
        getRequest(0, 10, {
            action: "LATEST",
            location: null,
            name: null,
            category: null,
            subCategory: null,
            publishedDate: null,
            closingDate: null
        })
            .then(response => {
                if (response.success) {
                    this.setState({
                        requests: response.body.content,
                    })
                } else {
                    ToastUtil.showErrorToast(response.msg);
                }
            })
    }

    onView = (item) => {
        if (this.props.loggedInStatus && this.props.userType === constants.USER_SELLER) {
            if (item.owner || item.availability) {
                this.props.history.push(routes.viewRequest + `/${item.tenderUrl}`, item)
            } else {
                checkAvailability(true)
            }
        } else {
            checkAvailability()
        }
    }

    render() {
        const inlineStyle = {
            // backgroundImage: `url(${Assets.cardBg3})`
        }
        const settings = {
            dots: false,
            infinite: true,
            speed: 500,
            autoplay: true,
            autoplaySpeed: 3000,
            slidesToShow: 3,
            swipeToSlide: true,
            slidesToScroll: 1,
            responsive: breakPoint2
        };
        const maxTimeLimit = 150;
        let downTime = 0;

        return <>
            {
                this.state.requests.length >= 4 ? <div className="properties-area pd-top-60 popularSupplier">
                    <div className="container">
                        <div className="custom-section section-title">
                            <span className="txt-sub-title">LATEST</span>
                            <h2 className="title">{landing.latestTenders}</h2>
                        </div>
                        <Slider {...settings}>
                            {this.state.requests.map((item, i) =>
                                <div className="p-2" key={i}
                                     onMouseDown={() => downTime = new Date().getTime()}
                                     onTouchStart={() => downTime = new Date().getTime()}
                                     onClick={() => {
                                         const time = new Date().getTime() - downTime;
                                         if (time < maxTimeLimit)
                                             this.onView(item)
                                     }}>
                                    <div className="single-feature requestHolder">
                                        <div className="p-3" style={inlineStyle}>
                                            <h6 className="title readeal-top">
                                                {/*<Link>{item.title.length > 30 ? item.title.substr(0, 30) + '...' : item.title}</Link>*/}
                                                <Link><label
                                                    className={'latest-tender-title'}>{item.title}</label></Link>
                                            </h6>
                                            <h6 className="category readeal-top">{
                                                item.subCategory.map((item, index) => index === 0 ? item.name : ", " + item.name)
                                            }</h6>
                                            <ul className="info-list mb-2">
                                                <li><i
                                                    className="fa fa-map-marker"/> {item.location !== null ? item.location : 'N/A'}
                                                </li>
                                                <li><b>Tender Value</b>: {item.tenderValue === 0 ? 'N/A' :
                                                    "Rs. " + numberWithCommas(item.tenderValue.toFixed(2))}</li>
                                            </ul>
                                            <li className="date">
                                                <b>Source</b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;: {
                                                (item.source !== undefined && item.source !== null) ? item.source : "N/A"}
                                            </li>
                                            <li className="date">
                                                <b>Published
                                                    On</b>&nbsp;&nbsp;: {moment(item.publishedDate).format(constants.DATE_FORMAT_EXTEND)}
                                            </li>
                                            <li className="date"><b>Closing
                                                On</b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;: {moment(item.closingDate).format(constants.DATE_FORMAT_EXTEND)}
                                            </li>
                                            {/*<li className="remaining" style={{*/}
                                            {/*    background: dateDiff(item) <= 0 ? "var(--main-color-two)" :*/}
                                            {/*        dateDiff(item) > 0 && dateDiff(item) <= 5 ? "var(--main-color-four)" : "var(--main-color-three)"*/}
                                            {/*}}>{*/}
                                            {/*    dateDiff(item) <= 0 ? 'Expired' : dateDiff(item) === 1 ? '1 Day Remaining' : dateDiff(item) + " Days Remaining"*/}
                                            {/*}*/}
                                            {/*</li>*/}
                                        </div>
                                        <div className={'landing-latest-request-footer'} style={{
                                            background: dateDiff(item) < 0 ? "var(--main-color-two)" :
                                                dateDiff(item) === 0 || dateDiff(item) > 0 && dateDiff(item) <= 5 ? "var(--main-color-four)" : "var(--main-color-one)"
                                        }}>
                                            {
                                                dateDiff(item) < 0 ? request.expired : dateDiff(item) === 0 ? request.lessThanADay : dateDiff(item) + 1 + request.daysRemaining
                                            }
                                        </div>
                                    </div>
                                </div>
                            )}
                        </Slider>

                        <div className="display-flex justify-content-center" style={{width: '100%'}}>
                            <button className="btn btn-yellow mt-3"
                                    onClick={() => this.props.history.push(routes.requests)}>{landing.seeMore}</button>
                        </div>

                    </div>
                </div> : null
            }
        </>
    }
}

const mapStateToProps = (state) => ({
    loggedInStatus: state.user.loggedInStatus,
    userType: state.user.userType,
});

export default connect(mapStateToProps, null)(withRouter(LatestRequests));
