/**
 * @author Sahan Dinuka
 * @CreatedBy IntelliJ IDEA
 * @created 04/11/2021 - 7:08 PM
 */
import React, { Component } from 'react'
import { Modal } from "react-bootstrap";
import Payment from "../oauth/payment";
import { validatePromoCode } from "../../constants/validation";
import * as services from "../../service/auth";
import { ToastUtil } from "../../constants/toastUtil";
import constants from "../../constants/constants";
import { handleErrors, WebXPayTokenizeInit } from "../../constants/payment";
import $ from "jquery";
import routes from "../../constants/routes";
import { createUser } from "../../constants/commonFunc";
import { getB2bDiscount } from "../../service/auth";
import swal from "sweetalert";

class RenewPackage extends Component {

    state = {
        percentage: 0,
        maximumAmount: 0,
        promoCodeId: 0,
        promo: '',
        subscription_plan: 0,
        packages: [],
        etUserDiscount: 0,
        nameOnTHeCard: '',
        cardNumber: 0,
        expMonth: '',
        expYear: '',
        cvv: 0,
        packageId: 0,
        suitableForDiscount: false
    }

    componentWillReceiveProps(nextProps, nextContext) {
        // this.setState({packages: nextProps.state.packages})
        this.setState({
            packages: [{
                'categoryCount': nextProps.user.categoryCount,
                'id': nextProps.state.packages[0].id,
                'packageName': nextProps.state.packages[0].packageName,
                'price': nextProps.state.packages[0].price,
                'userCount': nextProps.user.emailCount,
                'validityDays': nextProps.state.packages[0].validityDays,
                'validityDuration': nextProps.state.packages[0].validityDuration
            }]
        })
    }

    onTextChange = (e) => {
        let val = e.target.value
        if (val !== '') {
            this.setState({ [e.target.name]: val })
        } else {
            if (e.target.name === 'promo') {
                this.setState({
                    [e.target.name]: '',
                    percentage: 0,
                    maximumAmount: 0,
                    promoCodeId: 0
                })
            }
        }
    }

    applyPromo = async () => {
        if (validatePromoCode(this.state)) {
            await services.checkPromoCode(this.state.promo).then((response) => {
                if (response.success) {
                    if (response.body.status === "ACTIVE") {
                        this.setState({
                            percentage: response.body.discountPercentage,
                            maximumAmount: response.body.maxDiscount,
                            promoCodeId: response.body.id
                        })
                    } else {
                        this.setState({
                            percentage: 0,
                            maximumAmount: 0,
                            promoCodeId: 0
                        })
                    }

                } else {
                    this.setState({
                        percentage: 0,
                        maximumAmount: 0,
                        promoCodeId: 0
                    })
                    ToastUtil.showErrorToast(response.msg)
                }
            })
        }
    }

    getData = () => {
        this.paymentHandler()
        this.getB2bDiscount()
    }

    paymentHandler = () => {
        let script = document.createElement('script');
        script.setAttribute('src', constants.gatewayUrl);
        document.head.appendChild(script);
        const local = this;
        script.onload = function () {
            WebXPayTokenizeInit(
                {
                    number: "#card-number",
                    securityCode: "#security-code",
                    expiryMonth: "#expiry-month",
                    expiryYear: "#expiry-year",
                    nameOnCard: "#cardholder-name",
                },
                local.afterInit,
            );
        };
        script.onerror = function (e) {
            console.log(e)
        }
    }

    afterInit = (GenerateSession) => {
        const local = this
        $('#save-card-button').click(function () {
            // local.makePayment('SESSION0002618155108J75008010F0')
            GenerateSession(
                function (session) {
                    local.makePayment(session)
                    // this.makePayment(session)
                },
                function (error) {
                    handleErrors(error);
                }
            );
        });
    }

    makePayment = (session) => {
        const plan = this.state.packages[this.state.subscription_plan]
        let discount = Math.round((plan.price * this.state.percentage) / 100).toFixed(2)
        if (discount > this.state.maximumAmount)
            discount = this.state.maximumAmount;
        let totalDiscount = (Number(discount) + this.state.etUserDiscount).toFixed(2)

        const data = {
            email: this.props.state.email,
            amount: plan.price - totalDiscount,
            sessionId: session,
            promoCodeId: this.state.promoCodeId
        }
        services.renewPackageMakePayment(data)
            .then(async response => {
                if (response.success) {
                    await services.getExpireData().then(async (res) => {
                        await localStorage.setItem(constants.USER_OBJ, JSON.stringify(createUser(res.body)))
                        await localStorage.setItem(constants.USER_ORIGINAL_OBJ, JSON.stringify(res.body))
                    })
                    ToastUtil.showSuccessToast(response.msg);
                    // this.props.history.push(routes.myProfile)
                    this.props.handler(false)
                    window.location.href = routes.myProfile
                } else {
                    // ToastUtil.showErrorToast(response.msg)
                    swal({
                        title: "Payment failed",
                        text: " Unfortunately, your payment has failed.\n\n If you require assistance, please contact us on 0114326791 ",
                        icon: null,
                        closeOnClickOutside: false,
                        buttons: {
                            dangerMode: { text: "Ok", value: "action", className: "swalSuccess" }
                        }
                    })
                        .then((value) => {
                            switch (value) {
                                case "action":
                                    window.location = routes.contact;
                                    break;
                                default:
                                    break;
                            }
                        });
                }
            })
    }

    getB2bDiscount = () => {
        let user = JSON.parse(localStorage.getItem(constants.USER_ORIGINAL_OBJ))

        getB2bDiscount(user.email).then((res) => {
            if (res.success) {
                let price = this.state.packages[this.state.subscription_plan].price
                let discount = (price * res.body.discount) / 100

                this.setState({
                    ...this.state,
                    etUserDiscount: res.body.suitable ? discount : 0,
                    suitableForDiscount: res.body.suitable
                })
            }
        })
    }

    render() {
        return (
            <Modal
                className="productViewModal"
                show={this.props.state.isRenewPackageModal}
                onShow={this.getData}
                onHide={() => {
                    this.props.handler(false)
                    this.setState({
                        promo: ''
                    })
                }}
                backdrop="static"
                keyboard={false}
                size="xl"
            >
                <Modal.Header closeButton={true}>
                    <Modal.Title>Renew Package</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Payment onTextChange={this.onTextChange} applyPromo={this.applyPromo}
                        state={{ ...this.state }} isB2bDiscount={this.state.suitableForDiscount} />
                </Modal.Body>
            </Modal>
        )
    }
}

export default RenewPackage
