import React, { Component } from 'react';
import { Assets } from "../../assets";
import YouTube from "react-youtube";

class OurStory extends Component {


    render() {

        return <div className="sell-home-area rld-1-3-bg pd-top-60 pd-bottom-60 mt-5"
            style={{ backgroundImage: `url(${Assets.about3})` }}>
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 mb-4 mb-lg-0">
                        <div className="shape-image-list-wrap">
                            {/*<img className="shadow-img" src={Assets.about2} alt="image"/>*/}
                            <YouTube videoId="Ah9HfSrWjf4" />
                        </div>
                    </div>
                    <div className="col-lg-6 align-self-center">
                        <div className="section-title pd-left mb-0">
                            <h2 className="title inner-title">Who We Are</h2>
                            <p>"eTenders.lk" is Sri Lanka's most comprehensive and accurate tender alert service - if
                                there's a tender out there, then we surely have it on our platform. Our profiling team
                                and powerful tender matching system ensure that you get the tenders you want and the
                                edge to win. With many years in the etendering industry in Sri Lanka, we are confident
                                that www.eTenders.lk is tailor made to fulfill your needs.<br /><br />
                                eTenders.lk started its journey on the 26th of March 2012 was the first Sri Lankan
                                Tender Portal. Currently we service more than 400 customers and have published over
                                50,000 Sri Lankan Tenders.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    }
}

export default OurStory
