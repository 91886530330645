import React, { useEffect } from 'react';
import Navbar from '../components/globalComponents/navbar';
import LatestRequests from '../components/purchaseRequest/latestRequests';
import PopularCategory from '../components/globalComponents/popularCategory';
import Testimonials from '../components/globalComponents/testimonials';
import Partners from '../components/globalComponents/partners';
import Banner from '../components/banner/banner';
import Footer from "../components/globalComponents/footer";
import Clients from '../components/globalComponents/clients';
import Pricing from '../components/globalComponents/pricing'
import Ads from "../components/globalComponents/ads";
import Statistics from "../components/statistics/statistics";
import constants from "../constants/constants";

const Landing = (props) => {
    useEffect(() => {
        document.querySelector('body').scrollTo(0, 0)
        localStorage.removeItem(constants.REDIRECT_TO_HOME)
    }, [])

    return <div>
        <Navbar />
        <Banner history={props.history} />
        <Statistics />
        {/*<Testimonials/>*/}
        <LatestRequests history={props.history} />
        <Partners history={props.history} />
        {/*<Ads/>*/}
        <PopularCategory history={props.history} />
        <Clients />
        <Pricing />
        <Footer />
    </div>
}

export default Landing

