import React from "react";
import constants, {categories, categoryActions} from "../../constants/constants";
import strings from "../../locals/string.json";
import * as commonFunc from "../../constants/commonFunc";
import {createUpdateUserCategory, createUser} from "../../constants/commonFunc";
import {ToastUtil} from "../../constants/toastUtil";
import {getSupplierCategories, requestMoreCategory, updateUser} from "../../service/suppliers";
import {getCategories} from "../../service/category";
import {Assets} from "../../assets";
import {connect} from "react-redux";
import {withRouter} from "react-router";
import swal from "sweetalert";
import StepTwo from "../oauth/registrationStep2";
import * as userActions from "../../store/domain/user/action";
import axios from "axios";
import * as services from "../../service/auth";

const toast = strings.toast;
const modals = strings.modals;

class Categories extends React.Component {

    state = {
        id: '',
        categories: categories,
        userCategories: [],
        category: [],
        subCategory: [],
        catIdArray: [],
        seeMore: false,
        selectedId: -1,
        maxCategoryCount: 0,
        currentCount: 0,

        selectedIndex: -1,
        selectedIndexID: -1,
        selectAll: false,
        index: 0,
        size: 10,
        disabled: true,
    }

    componentWillMount() {
        this.getCategories();
        this.setState({
            id: this.props.user.id
        })
    }

    getCategories = () => {
        getCategories(categoryActions.GET_ALL)
            .then(async response => {
                if (response.success) {
                    let dummyArray = []
                    response.body.map(i => {
                        if (i.id !== 0) {
                            dummyArray.push(i)
                        }
                    })
                    this.getSupplierCategories(dummyArray);
                } else {
                    ToastUtil.showErrorToast(response.msg);
                }
            })
    }

    getSupplierCategories = (categories) => {
        // axios.get('https://b2b-et-test.ceyentra.lk/etenders-api/v1/user/category/view-all',{
        //     headers: {
        //         'Test-Header': 'test-value'
        //     }
        // })
        getSupplierCategories(this.props.user.id, this.state.index, this.state.size)
            .then(async response => {
                if (response.success) {
                    let dummyArray = []
                    response.body.map(async (item, index) => {
                        if (item.id !== 1) {
                            dummyArray.push(item)
                        }
                    })

                    const catLength = this.props.user.categoryCount;
                    const catIdArray = []
                    const data = [];
                    await dummyArray.map(async (item, index) => {
                        item.subCategory.map(cat => {
                            if (cat.checked) {
                                data.push(cat)
                            }
                        })
                        if ((categories.filter(e => e.id === item.id).length > 0)) {
                            categories.splice(categories.findIndex(e => e.id === item.id), 1);
                        }
                        await this.setState({
                            ['cat' + (index + 1)]: item
                        })
                        catIdArray.push(item.id)
                    });

                    const sortCategories = [...response.body, ...categories]
                    for (let i = response.body.length; i < catLength; i++) {
                        sortCategories[i] && catIdArray.push(-1)
                    }

                    await this.setState({
                        subCategory: data,
                        categories: sortCategories,
                        userCategories: response.body,
                        maxCategoryCount: catLength,
                        catIdArray: catIdArray,
                        currentCount: response.body.length
                    })
                } else {
                    ToastUtil.showErrorToast(response.msg);
                }
            })
    }

    handleSubCategoryChange = async (index, status, id) => {
        let data = {...this.state['cat' + index]};
        const result = commonFunc.handleSubCategoryChange(index, status, id, data)
        this.setState({['cat' + index]: result})
    }

    setCategory = async (index, value) => {
        const category = {...this.state.categories[value]}
        await this.setState({
            ["cat" + index]: category
        })
    }

    onCategoryChange = async (index, value) => {
        const category = {...this.state.categories[value]}
        const result = commonFunc.onCategoryChange(category, this.state.subCategory, this.state.category)
        const catIdArray = [...this.state.catIdArray];
        catIdArray[index] = this.state.categories[value].id;
        await this.setState({
            subCategory: result.data, category: result.cat,
            ["cat" + (index + 1)]: this.state.categories[value],
            catIdArray: catIdArray
        })
    }

    onSubCategoryChange = (category, item) => {
        const result = commonFunc.onSubCategoryChange(category, item, this.state.subCategory, this.state.category)
        this.setState({subCategory: result.data, category: result.cat})
    }

    onSelectAllSubCategory = (category, status) => {
        const data = this.state.subCategory;
        const cat = this.state.category;
        category.subCategory.map((item, index) => {
            if ((data.filter(e => e.id === item.id).length > 0)) {
                data.splice(data.findIndex(e => e.id === item.id), 1);
            }
            if (status) {
                data.push(item);
            }
        })

        if (!(cat.filter(e => e.id === category.id).length > 0)) {
            cat.push(category);
        } else {
            if (!status) {
                cat.splice(cat.findIndex(e => e.id === category.id), 1);
            }
        }
        this.setState({subCategory: data, category: cat})
    }

    onSave = () => {
        if (this.state.subCategory.length === 0) {
            ToastUtil.showErrorToast(toast.emptyCategory);
            return false;
        }

        updateUser(createUpdateUserCategory(this.state))
            .then(async response => {
                if (response.success) {
                    ToastUtil.showSuccessToast(response.msg);
                    await localStorage.setItem(constants.USER_OBJ, JSON.stringify(createUser(response.body)));
                    await localStorage.setItem(constants.USER_ORIGINAL_OBJ, JSON.stringify(response.body))
                    this.props.setAuth(createUser(response.body));
                    this.setState({disabled: !this.state.disabled});
                } else {
                    ToastUtil.showErrorToast(response.msg);
                }
            })
    }

    requestMore = () => {
        swal({
            title: modals.areUSure,
            text: modals.moreCategories,
            closeOnClickOutside: false,
            buttons: {
                success: {text: "Request", value: "action", className: "swalSuccess"},
                dangerMood: {text: "Cancel", value: "cancel", className: "swalCancel"}
            }
        })
            .then((value) => {
                switch (value) {
                    case "action":
                        requestMoreCategory()
                            .then(response => {
                                if (response.success) {
                                    swal({
                                        title: response.msg,
                                        icon: null,
                                        closeOnClickOutside: false,
                                        buttons: {
                                            dangerMode: {text: "Ok", value: "action", className: "swalSuccess"}
                                        }
                                    })
                                        .then((value) => {
                                            switch (value) {
                                                case "action":
                                                default:
                                                    break;
                                            }
                                        });
                                } else {
                                    ToastUtil.showErrorToast(response.msg);
                                }
                            })
                        break;
                    default:
                        break;
                }
            });
    }

    clearSelection = (i) => {
        const catIdArray = [...this.state.catIdArray];
        const catList = []
        this.state.categories.map(cat => {
            if (cat.id === catIdArray[i - 1]) {
                const subCategoryList = []
                cat.subCategory.map(item => {
                    item.checked = false
                    subCategoryList.push(item);
                })
                cat.subCategory = subCategoryList;
            }
            catList.push(cat);
        })
        catIdArray[i - 1] = -1;
        this.setState({
            ["cat" + i]: undefined,
            catIdArray: catIdArray,
            categories: catList
        })
    }

    render() {
        const userCategories = this.state.userCategories.length;

        return <div>
            <div className="display-flex justify-content-end">
                {
                    this.state.disabled ?
                        <button className="btn btn-yellow mt-3 mr-2"
                                onClick={() => this.setState({disabled: !this.state.disabled})}>
                            <i className="fa fa-edit mr-2"/>Edit</button> :
                        <button className="btn btn-yellow mt-3 mr-2"
                                onClick={this.onSave}>
                            <i className="fa fa-save mr-2"/>Save</button>
                }
                <button className="btn btn-yellow mt-3" onClick={this.requestMore}>Request
                    More
                </button>
            </div>
            <form className="contact-form-bg">
                {
                    userCategories === 0 ?
                        <div className="display-flex flex-column  align-items-center"
                             style={{width: '100%'}}>
                            {/*<img src={Assets.empty} alt="empty"/>*/}
                        </div> :
                        <StepTwo
                            state={{...this.state, userType: constants.USER_SELLER}}
                            categoryCount={this.state.disabled ? userCategories : this.state.maxCategoryCount}
                            onCategoryChange={this.onCategoryChange}
                            onSelectAllSubCategory={this.onSelectAllSubCategory}
                            setCategory={this.setCategory}
                            active={userCategories}
                            clearSelection={this.clearSelection}
                            disabled={this.state.disabled}
                            handleSubCategoryChange={this.handleSubCategoryChange}
                            onSubCategoryChange={this.onSubCategoryChange}
                        />
                }
            </form>
        </div>
    }
}

const mapStateToProps = (state) => ({
    loggedInStatus: state.user.loggedInStatus,
    userType: state.user.userType,
    user: state.user.user,
});
const mapDispatchToProps = (dispatch) => ({
    setAuth: data => dispatch(userActions.setAuth(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Categories));
