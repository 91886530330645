import React, {Component} from 'react';
import {Assets} from "../../assets";
import '../../scss/footer.scss'
import {Link} from 'react-router-dom'
import routes from "../../constants/routes";
import {contactDetails} from "../../constants/constants";

class Footer_v1 extends Component {

    state = {
        social: [
            {
                "icon": "fa fa-facebook",
                "url": "https://www.facebook.com/ETenders.lk"
            },
            {
                "icon": "fa fa-linkedin",
                "url": "https://www.linkedin.com/company/etenders-lk/about/"
            },
            {
                "icon": "fa fa-instagram",
                "url": "https://www.instagram.com/etenderslk"
            }
        ],
    }

    render() {

        return (
            <>
                <footer className="footer-area">
                    <div className="container">
                        <div className="footer-top">
                            <div className="row">
                                <div className="logo-white-container col-sm-4">
                                    <a className="footer-logo" href={routes.home}><img src={Assets.logoWhite}
                                                                                       alt="Footer logo"/></a>
                                </div>
                                <div className="col-sm-8">
                                    <div className="footer-social text-sm-right">
                                        {/*<span>FOLLOW US</span>*/}
                                        <ul className="social-icon">
                                            {this.state.social.map((item, i) =>
                                                <li key={i}>
                                                    <a href={item.url} target="_blank"><i className={item.icon}/></a>
                                                </li>
                                            )}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className={'hr-line'}></div>
                        </div>
                        {/*<div className="copy-right text-center">*/}
                        {/*    © Copyright {new Date().getFullYear()}, <a href='https://eTenders.lk/' target='_blank'>*/}
                        {/*    <span> eTenders.lk.</span></a> All Rights Reserved. Developed by <a href='https://ceyentra.com/' target='_blank'>*/}
                        {/*    <span> Ceyentra Technologies.</span></a>*/}
                        {/*</div>*/}

                        <div className="copy-right">
                            <div className={'row'}>
                                <div className={'col-md-6 col-sm-12'}>
                                    <p className={'pg-content'}>e-Tenders is the most effective online tender
                                        marketplace in Sri Lanka that opens up endless business opportunities for
                                        enterprises.</p>

                                    <div className={'contact-details-content'}>
                                        <span><b>A:</b> <span>2<sup>nd</sup> Floor McLaren's Building, No 123, Bauddhaloka Mawatha, Colombo 4.</span></span>
                                        <br/>
                                        <span><b>M:</b> <span>{contactDetails.mobileNumber}</span></span>
                                        <br/>
                                        <span><b>E:</b> <span>info@etenders.lk</span></span>
                                        <br/>
                                        <span className={'rights-content mb-4'}>All Rights Reserved.</span>
                                    </div>
                                </div>
                                <div className={'col-md-4'}></div>

                                <div className={'col-md-2'}>
                                    <ul className={'footer-pages-content'}>
                                        <li><Link to={routes.about}>About Us</Link></li>
                                        {/*<li><Link to={routes.pricing}>Pricing</Link></li>*/}
                                        {/*<li><Link to={routes.home}>Testimonials</Link></li>*/}
                                        <li><Link to={routes.tenders}>Tenders</Link></li>
                                        <li><Link to={routes.home}>Packages</Link></li>
                                    </ul>

                                    <span className={'rights-content-mobile mt-4'}>All Rights Reserved.</span>
                                </div>

                                {/*<div className={'col-md-2'}>*/}
                                {/*    <ul className={'footer-pages-content'}>*/}
                                {/*        /!*<li><Link to={routes.products}>Products</Link></li>*!/*/}
                                {/*        /!*<li><Link to={routes.suppliers}>Suppliers</Link></li>*!/*/}
                                {/*        <li><Link to={routes.home}>Packages</Link></li>*/}
                                {/*    </ul>*/}
                                {/*</div>*/}
                            </div>
                            {/*© Copyright {new Date().getFullYear}, B2B.lk is a Subsidiary of <a*/}
                            {/*href='https://eTenders.lk/'*/}
                            {/*target='_blank'>*/}
                            {/*<span> eTenders.lk.</span></a> All Rights Reserved. Developed by <a*/}
                            {/*href='https://ceyentra.com/'*/}
                            {/*target='_blank'>*/}
                            {/*<span> Ceyentra Technologies.</span></a>*/}
                        </div>


                    </div>
                </footer>

                <div className={'footer-white container'}>
                    <div className={'row'}>
                        <div className={'white-footer-left col-md-6 col-sm-12'}>
                            <p>Copyrights © 2022 - <a href={'https://www.etenders.lk'} target={'_blank'}><b
                                className={'color-main'}>eTenders.lk</b></a>
                            </p>
                        </div>
                        <div className={'white-footer-right col-md-6 col-sm-12'}>
                            <p>Developed by <a className={'color-main'} href={'https://ceyentra.com'} target={'_blank'}>Ceyentra
                                Technologies</a>
                            </p>
                        </div>
                    </div>
                </div>

            </>
        )
    }
}


export default Footer_v1
