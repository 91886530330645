import React from "react";
import strings from "../../locals/string.json";

const pr = strings.purchaseRequest;

class AccountManager extends React.Component {

    render() {
        const user = this.props.user;
        return <div>
            <div className="p-3">
                <p className="title">Account Manager Details</p>

                <p className="headline mt-3 display-flex flex-row">{pr.amName}: &nbsp;
                    <p className="contactDetails">{user.accountManager.name !== null ? user.accountManager.name : "N/A"}</p>
                </p>

                <p className="headline display-flex flex-row">{pr.email}:&nbsp;
                    <p className="contactDetails">{user.accountManager.email !== null ? user.accountManager.email : "N/A"}</p>
                </p>

                <p className="headline display-flex flex-row">{pr.mobile}:&nbsp;
                    <p className="contactDetails">{user.accountManager.mobile !== null ? user.accountManager.mobile : "N/A"}</p>
                </p>
                {
                    user.accountManager.email !== null &&
                    <button className="btn btn-white review"
                            onClick={() =>  window.open(`mailto:${user.accountManager.email}`,'_blank')}>
                        <i className="fa fa-edit mr-2"/>Contact Manager</button>
                }

            </div>
        </div>
    }
}

export default AccountManager;
