import React, {Component} from 'react';
import {Assets} from "../../assets";

class NewsDetails extends Component {
    render() {
        let data = this.props.location.state;

        return (
            <div>
                <div className="news-details-area">
                    <div className="container">
                        <div className="news-details-author-social">
                            <div className="row">
                                <div className="col-sm-6 mb-4 mg-sm-0">
                                    <div className="author">
                                        <img src={data.authorimage} alt="news"/>
                                        <p>By {data.author}</p>
                                        <p>{data.date}</p>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <img className="pointer mb-3" src={Assets.back} alt="back"
                             onClick={() => this.props.history.goBack()}/>
                        <div className="row justify-content-center pd-top-70">

                            <div className="col-lg-8">
                                <div className="news-details-wrap">
                                    <h3 className="title1">{data.title}</h3>

                                    <img className="news-details-thumb" src={data.image} alt="img" style={{width: '100%'}}/>

                                    <p>{data.content}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default NewsDetails;
