import React, {Component} from 'react';
import {Assets} from "../../assets";
import YouTube from 'react-youtube';
import {blogs} from "../../constants/constants";
import routes from "../../constants/routes";
import '../globalComponents/globalComponents.scss'

class IslandWild extends Component {

    render() {
        return <div className="Professisonal-area mt-5 ">
            <div className="container mt-4">
                <div className="our-achievement row">
                    <div className="col-lg-6 offset-lg-1 order-lg-12 mb-4 mb-lg-0">
                        <div className="shape-image-list-wrap">
                            <img className="shadow-img" src={Assets.about1} alt="image"/>
                        </div>
                    </div>
                    <div className="col-lg-5 align-self-center order-lg-1">
                        <div className="section-title mb-0">

                            <h2 className="title inner-title">Our Achievements</h2>
                            <p className="mb-0"><b>Winner - ICTA eSwabhimani Award</b></p>
                            <p>B2b.lk is Sri Lanka’s only ONLINE BUSINESS TO BUSINESS marketplace that connects buyers
                                and suppliers with ease. Currently Sri Lanka has 125,000 businesses and our goal is to
                                bring all of them onto one online platform and provide visibility to SME’s and provide
                                them with an online system to receive purchase leads.</p>

                            <p className="mb-0"><b>Won the ICTA Spiralation Project</b></p>
                            <p>E TENDERS was recognized by ICTA as one of the most promising and innovative IT companies
                                in 2012.
                                We were selected by ICTA for a grant of USD $ 5000 under the Spirilation Project. As a
                                result of this ICTA provides Mentoring, Guidance and Training to eTenders.
                                http://spiralation.com</p>
                        </div>
                    </div>
                </div>

                {/* <div className="section-title mb-0">
                    <h2 className="title inner-title text-center">Our Videos</h2>
                </div>
                <div className="row justify-content-center pd-bottom-100">
                    <div className="col-md-6 col-12 mb-3">
                        <p className="mb-0"><b>ICTA eSwabhimani Award</b></p>
                        <YouTube videoId="2PqlFOrw588"/>
                    </div>
                    <div className="col-md-6 col-12 mb-3">
                        <p className="mb-0"><b>Infotel Exhibition 2014 ( BMICH )</b></p>
                        <YouTube videoId="5QHyiBzhsOw"/>
                    </div>
                </div> */}

                {/*<div className="section-title mb-0">*/}
                {/*    <h2 className="title inner-title text-center">Blog</h2>*/}
                {/*</div>*/}
                {/*<div className="row">*/}
                {/*    {*/}
                {/*        blogs.map((item, i) =>*/}
                {/*            <div key={i} className="col-lg-4">*/}
                {/*                <div className="single-news pointer" onClick={()=> this.props.history.push(routes.blog,item)}>*/}
                {/*                    <div className="thumb">*/}
                {/*                        <img src={item.image} alt='img' style={{width: '100%'}}/>*/}
                {/*                    </div>*/}
                {/*                    <div className="details pl-2">*/}
                {/*                        <h6 style={{color: 'var(--main-color-one)'}}>{item.title}</h6>*/}
                {/*                        <p className="bolgDescription">{item.content}</p>*/}
                {/*                    </div>*/}
                {/*                </div>*/}
                {/*            </div>*/}
                {/*        )*/}
                {/*    }*/}
                {/*</div>*/}
            </div>
        </div>
    }
}

export default IslandWild
