import React from 'react';
import routes from "../../constants/routes";
import constants from "../../constants/constants";
import moment from "moment";
import Cookies from "js-cookie";
import warningImg from '../../assets/img/warning-red.png'
import closeBlack from '../../assets/img/close-black.png'
import * as action from "../../store/domain/user/action";
import {connect} from "react-redux";
import {withRouter} from "react-router";
import * as services from "../../service/auth";
import {createUser} from "../../constants/commonFunc";
import * as userActions from "../../store/domain/user/action";


class Error extends React.Component {

    state = {
        view: false,
        expiryDate: '',
        isTrial: false
    }

    componentDidMount() {
        if (Cookies.get(constants.ACCESS_TOKEN) !== undefined) {
            this.getAllData()
        }
    }

    getAllData = async () => {
        await services.getExpireData().then(async (res) => {
            await localStorage.setItem(constants.USER_OBJ, JSON.stringify(createUser(res.body)))
            await localStorage.setItem(constants.USER_ORIGINAL_OBJ, JSON.stringify(res.body))

            this.props.setAuth(createUser(res.body));

            const user = createUser(res.body)
            const userOriginal = res.body

            if (user === undefined || user === null) {
                this.setState({view: false, isTrial: false})
            } else {
                if (user.userType === constants.USER_BUYER) {
                    this.setState({
                        view: false,
                        isTrial: userOriginal && userOriginal.account.activatedDate === userOriginal.account.expiryDate ? true : false
                    })
                } else {
                    const diff = moment(user.expiryDate).diff(moment(), 'days');

                    if (diff < 10 && diff >= 0)
                        this.setState({
                            view: true,
                            expiryDate: moment(user.expiryDate).format(constants.DATE_FORMAT_EXTEND),
                            isTrial: userOriginal && userOriginal.account.activatedDate === userOriginal.account.expiryDate ? true : false,
                            userData: userOriginal.account
                        })
                }
            }
        })
    }

    render() {
        return <>
            {this.state.view && <>
                {
                    Cookies.get(constants.ACCESS_TOKEN) !== undefined
                        ? <div className="error">
                            <div className={'error-inner'}>
                                <img src={warningImg} height={25} width={25} className={'img-warning'}/>
                                <span className="text">Your account will expire on {this.state.expiryDate}. Please
                                    {
                                        this.state.userData.etPackage.packageName === 'Trial' ? ' upgrade ' : ' renew or upgrade '
                                    }
                                    your subscription to continue using our service.</span>
                                <div className="display-flex flex-row justify-content-end">
                                    {/*<button className="btn btn-white mr-3" onClick={() => this.setState({view: false})}>Close*/}
                                    {/*</button>*/}
                                    {/*<button className="btn btn-yellow"*/}
                                    {/*        onClick={() => window.location = routes.myProfile}>Upgrade*/}
                                    {/*</button>*/}
                                    <button className={'btn-upgrade'}
                                            onClick={() => window.location = routes.myProfile}>{this.state.userData.etPackage.packageName === 'Trial' ? 'Upgrade' : 'Renew or Upgrade'}
                                    </button>
                                    <img onClick={() => this.setState({view: false})} className={'btn-close ml-3 mt-1'}
                                         src={closeBlack}
                                         height={20} width={20}/>
                                </div>
                            </div>
                        </div> : null
                }
            </>}
            {
                Cookies.get(constants.ACCESS_TOKEN) !== undefined && this.state.isTrial &&
                <div className={`${this.state.view ? "error2" : "error"}`}>
                    <div className={'error-inner'}>
                        <img src={warningImg} height={25} width={25} className={'img-warning'}/>
                        <span className="text">Your account will expire within today, To continue full features please settle payment</span>
                        <div className="display-flex flex-row justify-content-end">
                            {/*<button className="btn btn-white mr-3" onClick={() => this.setState({view: false})}>Close*/}
                            {/*</button>*/}
                            {/*<button className="btn btn-yellow"*/}
                            {/*        onClick={() => window.location = routes.myProfile}>Upgrade*/}
                            {/*</button>*/}
                            <button className={'btn-upgrade'}
                                    onClick={() => window.location = routes.myProfile}>Pay
                            </button>
                            <img onClick={() => this.setState({view: false})} className={'btn-close ml-3 mt-1'}
                                 src={closeBlack}
                                 height={20} width={20}/>
                        </div>
                    </div>
                </div>
            }
        </>
    }
}

const mapStateToProps = (state) => ({
    userData: state.user.userData,
});

const mapDispatchToProps = (dispatch) => ({
    setAuth: data => dispatch(userActions.setAuth(data)),
    setUserType: data => dispatch(userActions.setUserType(data)),
});


export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Error));
