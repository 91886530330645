import ApiService from './apiHandler';

export async function loginUser(userCredentials, ETUser) {
    const apiObject = {};
    apiObject.method = 'POST';
    apiObject.authentication = false;
    apiObject.isBasicAuth = true;
    apiObject.urlEncoded = true;
    apiObject.endpoint = 'oauth/token';
    apiObject.body = userCredentials;
    apiObject.state = "login";
    apiObject.ETUser = ETUser;
    return await ApiService.callApi(apiObject);
}

export async function renewToken(token) {
    const apiObject = {};
    apiObject.method = 'POST';
    apiObject.authentication = false;
    apiObject.isBasicAuth = true;
    apiObject.urlEncoded = true;
    apiObject.endpoint = 'oauth/token';
    apiObject.body = token;
    apiObject.state = "renewToken";
    return await ApiService.callApi(apiObject);
}

export async function register(data) {
    const apiObject = {};
    apiObject.method = 'POST';
    apiObject.multipart = true;
    apiObject.endpoint = 'user/register';
    apiObject.body = data;
    apiObject.state = "register";
    return await ApiService.callApi(apiObject);
}

export async function checkEmailAvailability(email) {
    const apiObject = {};
    apiObject.method = 'GET';
    apiObject.endpoint = `user/email/${email}/check-availability`;
    return await ApiService.callApi(apiObject);
}

export async function checkCompanyUrlAvailability(companyNameUrl) {
    const apiObject = {};
    apiObject.method = 'GET';
    apiObject.endpoint = `user/company-name-url/${companyNameUrl}/check-availability`;
    return await ApiService.callApi(apiObject);
}

export async function getSubscriptions() {
    const apiObject = {};
    apiObject.method = 'GET';
    apiObject.endpoint = `subscription`;
    return await ApiService.callApi(apiObject);
}

export async function requestPin(email) {
    const apiObject = {};
    apiObject.method = 'GET';
    apiObject.endpoint = `user/otp/request/${email}`;
    return await ApiService.callApi(apiObject);
}

export async function updateForgotPassword(data) {
    const apiObject = {};
    apiObject.method = 'POST';
    apiObject.endpoint = `user/password/forgot`;
    apiObject.body = data;
    return await ApiService.callApi(apiObject);
}

export async function resetPassword(data) {
    const apiObject = {};
    apiObject.method = 'POST';
    apiObject.endpoint = `user/password/reset`;
    apiObject.body = data;
    apiObject.authentication = true;
    return await ApiService.callApi(apiObject);
}

export async function getStatistics() {
    const apiObject = {};
    apiObject.method = 'GET';
    apiObject.endpoint = `dashboard/get-count`;
    return await ApiService.callApi(apiObject);
}

export async function contactus(data) {
    const apiObject = {};
    apiObject.method = 'POST';
    apiObject.endpoint = `user/contact-us`;
    apiObject.body = data;
    apiObject.authentication = false;
    return await ApiService.callApi(apiObject);
}

export async function checkPromoCode(promoCode) {
    const apiObject = {};
    apiObject.method = 'GET';
    apiObject.endpoint = `user/promo-code/${promoCode}`;
    apiObject.authentication = false;
    return await ApiService.callApi(apiObject);
}

export async function checkETUserDiscount(email) {
    const apiObject = {};
    apiObject.method = 'GET';
    apiObject.endpoint = `user/etender-user/${email}`;
    apiObject.authentication = false;
    return await ApiService.callApi(apiObject);
}

export async function makePayment(data) {
    const apiObject = {};
    apiObject.method = 'POST';
    apiObject.endpoint = `user/payment`;
    apiObject.body = data;
    apiObject.authentication = false;
    return await ApiService.callApi(apiObject);
}

export async function updatePackageMakePayment(data) {
    const apiObject = {};
    apiObject.method = 'PUT';
    apiObject.endpoint = `user/payment`;
    apiObject.body = data;
    apiObject.authentication = false;
    return await ApiService.callApi(apiObject);
}

export async function renewPackageMakePayment(data) {
    const apiObject = {};
    apiObject.method = 'PATCH';
    apiObject.endpoint = `user/payment`;
    apiObject.body = data;
    apiObject.authentication = false;
    return await ApiService.callApi(apiObject);
}

export async function saveTempUser(data) {
    const apiObject = {};
    apiObject.method = 'POST';
    apiObject.endpoint = `user/temporary/log`;
    apiObject.body = data;
    apiObject.authentication = false;
    return await ApiService.callApi(apiObject);
}

export async function getB2bDiscount(email) {
    const apiObject = {};
    apiObject.method = 'GET';
    apiObject.endpoint = `user/b2b-user/${email}`;
    apiObject.authentication = false;
    return await ApiService.callApi(apiObject);
}

export async function getExpireData() {
    const apiObject = {};
    apiObject.method = 'GET';
    apiObject.endpoint = `user/details`;
    apiObject.authentication = true;
    return await ApiService.callApi(apiObject);
}
