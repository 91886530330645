const routes = {
    home: '/',
    login: '/login',
    register: '/register',
    requests: '/tenders',
    myQuotes: '/my-quotations',
    myRequests: '/my-requests',
    products: '/products',
    myProducts: '/my-products',
    suppliers: '/suppliers',
    contact: '/contact',
    blog: '/blog',
    about: '/about-us',
    forgotPW: '/forgot-password',
    myProfile: '/my-profile',
    viewSupplier: '/supplier',
    viewProduct: '/view-product',
    viewRequest: '/tender',
    tenders:'/tenders'
}

export default routes;
