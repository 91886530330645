import React, {Component, Suspense} from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import {Provider} from "react-redux";
import {store} from "./store/storeConfig/store";
import routes from "./constants/routes";
import Landing from './views/landing';
import Login from './views/login';
import Register from './views/register';
import {ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import Requests from "./views/requests";
import ViewRequests from "./views/viewRequests";
import MyProfile from "./views/myProfile";
import AboutUs from "./views/aboutUs";
import Contact from "./views/contact";
import Error from "./components/globalComponents/expire";
import NewsDetails from "./views/newsDetails";

class Root extends Component {
    render() {
        return (
            <Provider store={store}>
                <Suspense>
                    <Router>
                        <ToastContainer
                            position="top-center"
                            autoClose={3000}
                            hideProgressBar
                            newestOnTop={false}
                            closeOnClick
                            rtl={false}
                            pauseOnFocusLoss
                            draggable
                            pauseOnHover
                        />
                        <Switch>
                            {/* oauth & landing */}
                            <Route exact path={routes.home} component={Landing}/>
                            <Route exact path={routes.login} component={Login}/>
                            <Route exact path={routes.register} component={Register}/>

                            {/* requests */}
                            <Route exact path={routes.requests} component={Requests}/>
                            <Route exact path={routes.viewRequest + "/:tender_url"} component={ViewRequests}/>

                            {/* my-profile */}
                            <Route exact path={routes.myProfile} component={MyProfile}/>

                            {/* b2b */}
                            <Route exact path={routes.about} component={AboutUs}/>
                            <Route exact path={routes.contact} component={Contact}/>
                            <Route exact path={routes.blog} component={NewsDetails}/>
                        </Switch>
                        <Error/>
                    </Router>
                </Suspense>
            </Provider>
        )
    }
}

export default Root;

ReactDOM.render(<Root/>, document.getElementById('root'));
