import React, {Component} from 'react';
import './globalComponents.scss';
import {Link} from 'react-router-dom';
import {Assets} from "../../assets";
import strings from '../../locals/string.json';
import routes from "../../constants/routes";
import {withRouter} from "react-router";
import {connect} from "react-redux";
import constants, {popularSuppliers} from "../../constants/constants";
import * as userActions from "../../store/domain/user/action";
import {createUser, removeCookiesValues} from "../../constants/commonFunc";
import ResetPasswordModal from "../oauth/resetPasswordModal";
import Cookies from "js-cookie";
import moment from "moment";
import {ToastUtil} from "../../constants/toastUtil";

const string = strings.navBar;

class Navbar extends Component {

    state = {
        modelOpen: false
    }

    componentWillMount() {
        if (localStorage.getItem(constants.ACCESS_TOKEN) !== null) {
            this.props.setLoginStatus(true)
            this.props.setUser(JSON.parse(localStorage.getItem(constants.USER_OBJ)))
            this.props.setUserType(constants.USER_SELLER);
        }
    }

    componentDidMount() {
        if (Cookies.get(constants.ACCESS_TOKEN) !== undefined) {
            this.checkExpired()
        }
    }

    checkExpired = async () => {
        let date = await JSON.parse(localStorage.getItem(constants.USER_OBJ)).expiryDate

        if (moment(date).diff(moment(), 'days') <= 0 && window.location.pathname.split('/')[1] !== 'my-profile') {
            this.props.history.push(routes.myProfile)
            ToastUtil.showErrorToast(string.expiredMessage);
        }
    }

    logOUt = async () => {
        Cookies.remove(constants.ACCESS_TOKEN);
        Cookies.remove(constants.REFRESH_TOKEN);
        localStorage.removeItem(constants.ACCESS_TOKEN);
        // this.props.history.push(routes.login)
        this.props.setAuth(undefined);
        this.props.setUser(null);
        this.props.setLoginStatus(false);

        sessionStorage.clear();
        localStorage.removeItem(constants.USER_ORIGINAL_OBJ);
        localStorage.removeItem(constants.USER_OBJ);
        window.location.href = routes.home
    }

    render() {
        return (
            <div>
                <div className="navbar-area navBar">
                    <nav className="navbar navbar-area navbar-expand-lg">
                        <div className="container nav-container">
                            <div className="responsive-mobile-menu">
                                <button className="menu toggle-btn d-block d-lg-none" data-toggle="collapse"
                                        data-target="#realdeal_main_menu" aria-expanded="false"
                                        aria-label="Toggle navigation">
                                    <span className="icon-left"/>
                                    <span className="icon-right"/>
                                </button>
                            </div>
                            <div className="logo readeal-top" style={{width: '120px'}}>
                                <Link to={routes.home}><img src={Assets.logo} alt="logo"/></Link>
                            </div>

                            <div className="nav-right-part nav-right-part-mobile oauthHolder-mobile">

                                {
                                    !this.props.loggedInStatus ?
                                        <>
                                            <Link className="btn btn-yellow"
                                                  to={routes.register}>Subscribe</Link>
                                            <p className="or">or</p>
                                            <Link className="btn login" to={routes.login}>{string.login}</Link>
                                        </>
                                        : null
                                }

                            </div>

                            <div className="collapse navbar-collapse" id="realdeal_main_menu">
                                <ul className="navbar-nav menu-open readeal-top">
                                    <li className="current-menu-item">
                                        <a><Link to={routes.home}>{string.home}</Link></a>
                                    </li>

                                    <li><Link to={routes.requests}>{string.tenders}</Link></li>

                                    <li><Link to={routes.about}>{string.aboutUs}</Link></li>

                                    <li><Link to={routes.contact}>{string.contact}</Link></li>

                                    {
                                        this.props.loggedInStatus &&
                                        <li className="menu-item-has-children">
                                            <a><img className='loggedUser'
                                                    src={this.props.user?.profilePicture ?? Assets.avatarPlaceholder}
                                                    alt="user" width={45}/></a>
                                            <ul className="sub-menu">
                                                <li onClick={() => this.props.history.push(routes.myProfile, popularSuppliers[0])}>
                                                    <Link>{string.profile}</Link></li>
                                                <li onClick={() => this.setState({modelOpen: !this.state.modelOpen})}>
                                                    <Link>{string.reset}</Link></li>
                                                <li onClick={this.logOUt}><Link>{string.logOut}</Link></li>
                                            </ul>
                                        </li>
                                    }

                                </ul>
                            </div>
                            <div className="nav-right-part nav-right-part-desktop readeal-top oauthHolder">
                                {
                                    !this.props.loggedInStatus ?
                                        <>
                                            <Link className="btn btn-yellow"
                                                  to={routes.register}>Subscribe</Link>
                                            <p className="or">or</p>
                                            <Link className="btn login" to={routes.login}>{string.login}</Link>
                                        </> : null

                                }
                            </div>

                        </div>
                    </nav>
                </div>

                <ResetPasswordModal
                    open={this.state.modelOpen}
                    close={() => this.setState({modelOpen: !this.state.modelOpen})}
                />
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    loggedInStatus: state.user.loggedInStatus,
    userType: state.user.userType,
    user: state.user.user,
});

const mapDispatchToProps = (dispatch) => ({
    setAuth: data => dispatch(userActions.setAuth(data)),
    setLoginStatus: data => dispatch(userActions.setLoginStatus(data)),
    setUser: data => dispatch(userActions.setUser(data)),
    setUserType: data => dispatch(userActions.setUserType(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Navbar));
