/**
 * @author Sahan Dinuka
 * @CreatedBy IntelliJ IDEA
 * @created 03/11/2021 - 2:49 PM
 */
import React, { Component } from 'react'
import { Modal } from "react-bootstrap";
import { Assets } from "../../assets";
import * as services from '../../service/auth'
import Payment from '../../components/oauth/payment'
import './myProfile.scss'
import { validatePromoCode } from '../../constants/validation'
import { ToastUtil } from "../../constants/toastUtil";
import constants from "../../constants/constants";
import { handleErrors, WebXPayTokenizeInit } from "../../constants/payment";
import $ from "jquery";
import routes from "../../constants/routes";
import Cookies from "js-cookie";
import jwt_decode from "jwt-decode";
import { getB2bDiscount } from "../../service/auth";
import '../globalComponents/globalComponents.scss'
import { createUser } from "../../constants/commonFunc";
import swal from "sweetalert";
import strings from "../../locals/string.json";
import { connect } from "react-redux";
import { withRouter } from "react-router";

const modals = strings.modals;

class ChangePackage extends Component {

    state = {
        packages: [],
        subscription_plan: 0,
        percentage: 0,
        maximumAmount: 0,
        etUserDiscount: 0,
        nameOnTHeCard: '',
        cardNumber: 0,
        expMonth: '',
        expYear: '',
        cvv: 0,
        promo: '',
        step: 1,
        promoCodeId: 0,
        packageId: 0,
        currentEmail: 0,
        currentCategories: 0,
        suitableForDiscount: false
    }

    onTextChange = (e) => {
        let val = e.target.value
        if (val !== '') {
            this.setState({ [e.target.name]: val })
        } else {
            if (e.target.name === 'promo') {
                this.setState({
                    [e.target.name]: '',
                    percentage: 0,
                    maximumAmount: 0,
                    promoCodeId: 0
                })
            }
        }
    }

    componentDidMount() {
        let user = JSON.parse(localStorage.getItem(constants.USER_ORIGINAL_OBJ))

        this.setState({
            currentEmail: user.account.secondaryEmails !== null ? user.account.secondaryEmails.split(',').length : 0,
            currentCategories: user.account.categories.length
        })
    }

    applyPromo = async () => {
        if (validatePromoCode(this.state)) {
            await services.checkPromoCode(this.state.promo).then((response) => {
                if (response.success) {
                    this.setState({
                        percentage: response.body.discountPercentage,
                        maximumAmount: response.body.maxDiscount,
                        promoCodeId: response.body.id
                    })
                } else {
                    this.setState({
                        percentage: 0,
                        maximumAmount: 0,
                        promoCodeId: 0
                    })
                    ToastUtil.showErrorToast(response.msg)
                }
            })
        }
    }

    getAllPackages = async () => {
        await services.getSubscriptions().then((res) => {
            if (res.success) {
                let tempPackages = []
                res.body.map((pack) => {
                    if (pack.subscriptionName !== 'Trial') {
                        tempPackages.push(pack)
                    }
                })

                this.setState({
                    ...this.state,
                    packages: tempPackages
                })
            }
        })
    }

    paymentHandler = () => {
        let script = document.createElement('script');
        script.setAttribute('src', constants.gatewayUrl);
        document.head.appendChild(script);
        const local = this;
        script.onload = function () {
            WebXPayTokenizeInit(
                {
                    number: "#card-number",
                    securityCode: "#security-code",
                    expiryMonth: "#expiry-month",
                    expiryYear: "#expiry-year",
                    nameOnCard: "#cardholder-name",
                },
                local.afterInit,
            );
        };
        script.onerror = function (e) {
            console.log(e)
        }
    }

    afterInit = (GenerateSession) => {
        const local = this
        $('#save-card-button').click(function () {
            // local.makePayment('SESSION0002618155108J75008010F0')
            GenerateSession(
                function (session) {
                    local.makePayment(session)
                    // this.makePayment(session)
                },
                function (error) {
                    handleErrors(error);
                }
            );
        });
    }

    makePayment = (session) => {
        const plan = this.state.packages[this.state.subscription_plan]
        let discount = Math.round((plan.price * this.state.percentage) / 100).toFixed(2)
        if (discount > this.state.maximumAmount)
            discount = this.state.maximumAmount;
        let totalDiscount = (Number(discount) + this.state.etUserDiscount).toFixed(2)

        const data = {
            email: this.props.state.email,
            amount: plan.price - totalDiscount,
            sessionId: session,
            promoCodeId: this.state.promoCodeId,
            packageId: this.state.packageId
        }
        services.updatePackageMakePayment(data)
            .then(async response => {
                if (response.success) {
                    await services.getExpireData().then(async (res) => {
                        await localStorage.setItem(constants.USER_OBJ, JSON.stringify(createUser(res.body)))
                        await localStorage.setItem(constants.USER_ORIGINAL_OBJ, JSON.stringify(res.body))
                    })
                    ToastUtil.showSuccessToast(response.msg);
                    // this.onClear();
                    // this.props.history.push(routes.login)
                    this.props.handler(false)
                    window.location.href = routes.myProfile
                } else {
                    // ToastUtil.showErrorToast(response.msg)
                    swal({
                        title: "Payment failed",
                        text: " Unfortunately, your payment has failed.\n\n If you require assistance, please contact us on 0114326791 ",
                        icon: null,
                        closeOnClickOutside: false,
                        buttons: {
                            dangerMode: { text: "Ok", value: "action", className: "swalSuccess" }
                        }
                    })
                        .then((value) => {
                            switch (value) {
                                case "action":
                                    window.location = routes.contact;
                                    break;
                                default:
                                    break;
                            }
                        });
                }
            })
    }

    getB2bDiscount = async () => {
        let user = JSON.parse(localStorage.getItem(constants.USER_ORIGINAL_OBJ))

        await getB2bDiscount(user.email).then(async (res) => {
            if (res.success) {
                let price = this.state.packages[this.state.subscription_plan].price
                let discount = (price * res.body.discount) / 100

                await this.setState({
                    ...this.state,
                    etUserDiscount: res.body.suitable ? discount : 0,
                    suitableForDiscount: res.body.suitable
                })
            }
        })
    }

    onSelectPackage = (index, pack) => {
        if (this.state.currentCategories > pack.categoryCount || this.state.currentEmail > pack.userCount) {
            swal({
                title: modals.sorry,
                text: "As you have customized subscription package, please contact your account manager to change/renew the subscription.",
                closeOnClickOutside: false,
                buttons: {
                    success: { text: "Ok", value: "action", className: "swalSuccess" },
                    // dangerMood: {text: "Cancel", value: "cancel", className: "swalCancel"}
                }
            })
        } else {
            this.setState({
                ...this.state,
                subscription_plan: index,
                step: 2,
                packageId: pack.id
            })
            this.paymentHandler(this.state);
            this.getB2bDiscount()
        }
    }

    render() {
        return (<>
            <Modal
                className="productViewModal"
                show={this.props.state.isChangePackageModal}
                onShow={this.getAllPackages}
                onHide={() => {
                    this.props.handler(false)
                    this.setState({
                        ...this.state,
                        step: 1,
                        promo: ''
                    })
                }}
                backdrop="static"
                keyboard={false}
                size="lg"
            >
                <Modal.Header closeButton={true}>
                    <Modal.Title>Change Package</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className={'packageChange mt-2'}>
                        {this.state.step === 1
                            ? <div className={'row'}>
                                {
                                    this.state.packages.map((pack, index) =>
                                        <div className={'col-xs-12 col-md-6 col-lg-6 col-xl-4'}>
                                            <div className="p-2 change-package">
                                                <div className="padding15">
                                                    <div className="packageHolder">
                                                        <img className="cardBg" src={Assets.cardBg5} alt={'check'} />

                                                        <h6 className="change-package-title">{pack.subscriptionName.toUpperCase()}</h6>

                                                        <h6 className="priceTag">Rs.
                                                            <h6 className="price">{pack.price.toFixed(2)}</h6>
                                                        </h6>

                                                        <h6 className="priceTag">{pack.validityDuration}</h6>
                                                        <h6 className="userTag mt-4">{pack.userCount + 1} Email{pack.userCount + 1 > 1 ? 's' : ''}</h6>
                                                        <h6 className="userTag">{pack.categoryCount} {pack.categoryCoun > 1 ? 'Categories' : 'Category'}</h6>

                                                        <button
                                                            className="btn btn-yellow mb-4 mt-4"
                                                            onClick={() => this.onSelectPackage(index, pack)}>Select
                                                            Plan
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }
                            </div>
                            : null}

                        {
                            this.state.step === 2 ? <>
                                <Payment onTextChange={this.onTextChange} applyPromo={this.applyPromo}
                                    state={{ ...this.state }} isB2bDiscount={this.state.suitableForDiscount} />
                            </> : null
                        }
                    </div>
                </Modal.Body>
                {
                    this.state.step !== 1 ? <Modal.Footer>
                        <button className="btn btn-white"
                            onClick={() => this.setState({ step: (this.state.step - 1) })}>BACK
                        </button>
                        {/*<button className="btn btn-yellow">UPDATE</button>*/}
                    </Modal.Footer> : null
                }
            </Modal>
        </>)
    }
}

const mapStateToProps = (state) => ({
    loggedInStatus: state.user.loggedInStatus,
    userType: state.user.userType,
    user: state.user.user,
});

export default connect(mapStateToProps, null)(withRouter(ChangePackage));
