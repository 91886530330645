import React from 'react'
import {Modal} from "react-bootstrap";
import YouTube from "react-youtube";
import '../globalComponents/globalComponents.scss'
import close from '../../assets/img/close.png'

export default (props) => {

    return <Modal
        className="productViewModal myProfile videoModal"
        show={props.open}
        onHide={props.close}
        backdrop="static"
        keyboard={false}
        centered
        size="md"
    >
        <div className="display-flex justify-content-center section-title pointer" style={{width: '100%'}}>
            <img src={close} className={'video-modal-close'} onClick={() => props.close()}/>
            <div className={'video-modal'}>
                <YouTube videoId={props.videoId}/>
            </div>
        </div>
    </Modal>
}
