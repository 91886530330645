import ApiService from './apiHandler';

/**
 *
 * @param action one of from GET_ALL, AVAILABLE, POPULAR
 * @returns {Promise<*>}
 */
export async function getCategories(action) {
    const apiObject = {};
    apiObject.method = 'GET';
    apiObject.endpoint = `category/${action}`;
    apiObject.authentication = true;
    return await ApiService.callApi(apiObject);
}

export async function getSources() {
    const apiObject = {};
    apiObject.method = 'GET';
    apiObject.endpoint = `source`;
    apiObject.authentication = true;
    return await ApiService.callApi(apiObject);
}

export async function getCompanies(name) {
    const apiObject = {};
    apiObject.method = 'GET';
    apiObject.endpoint = `company/${name}`;
    apiObject.authentication = true;
    return await ApiService.callApi(apiObject);
}
