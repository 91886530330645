import React from "react";
import './requests.scss'
import { Assets } from "../../assets";
import strings from "../../locals/string.json";
import { dateDiff, getBase64, numberWithCommas, openWebSite } from "../../constants/commonFunc";
import { favRequest, getRequestDetails } from "../../service/request";
import { ToastUtil } from "../../constants/toastUtil";
import moment from "moment";
import constants from "../../constants/constants";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import FileViewer from "react-file-viewer";
import routes from "../../constants/routes";
import Files from 'react-files'
import DocViewer, { PDFRenderer, PNGRenderer, JPGRenderer, DocViewerRenderers } from "react-doc-viewer";

const pr = strings.purchaseRequest;
const modals = strings.modals;

class ViewRequest extends React.Component {

    state = {
        fav: false,
        item: undefined,
        isNoData: false
    }

    componentWillMount() {
        this.props.loggedInStatus ? this.getRequest() : ToastUtil.showErrorToast(modals.becomeASeller);
        if (!this.props.loggedInStatus) {
            sessionStorage.setItem(constants.VIEW_TENDER, this.props.history.location.pathname);
            this.props.history.push(routes.login)
        } else {
            sessionStorage.clear();
        }
    }

    getRequest = () => {
        let tender_url = this.props.match.params.tender_url;
        getRequestDetails(tender_url)
            .then(response => {
                if (response.success) {

                    const item = response.body;
                    this.setState({
                        item: item,
                        fav: item.fav,
                        isNoData: false
                    })
                    item.attachment && getBase64(item.attachment);
                } else {
                    this.setState({
                        isNoData: true
                    })
                    ToastUtil.showErrorToast(response.msg);
                }
            })
    }

    onFav = (id, status) => {
        favRequest(id, status)
            .then(response => {
                if (response.success) {
                    this.getRequest()
                } else {
                    ToastUtil.showSuccessToast(response.msg)
                }
            })
    }

    goBackHandler = () => {
        if (sessionStorage.getItem(constants.VIEW_TENDER) !== undefined) {
            if (this.props.location.state !== undefined) {
                this.props.history.push(routes.tenders, { currentStatus: this.props.location.state.currentStatus })
            } else {
                this.props.history.push(routes.tenders)
            }
        } else {
            this.props.history.goBack()
        }
    }

    render() {
        const item = this.state.item;
        let filePreview = item !== undefined && item.attachment !== null && item.attachment.split('.')
        let fileExtension = filePreview[filePreview.length - 1]
        const docs = [
            { uri: item !== undefined && item.attachment !== null && item.attachment }
        ];

        return item ? <div className="register-page-area pd-bottom-100 view-request">
            <div className="container">
                <img className="pointer mt-3" src={Assets.back} alt="back"
                    onClick={() => this.goBackHandler()} />

                <div className="row">
                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                        <div className="refHolder">
                            <p className="ref"><b>Ref: {item.id}</b></p>
                            <div>
                                <i className={this.state.fav ? "fa fa-heart pointer" : "fa fa-heart-o pointer"}
                                    onClick={() => this.onFav(item.id, this.state.fav ? 'NOT_INTERESTED' : 'INTERESTED')} />
                            </div>
                        </div>

                        <p className="title mt-4">{pr.requestInformation}</p>
                        <div className="p-3">
                            <p className="sub-title">{item.title}</p>
                            <p className="headline"><i
                                style={{ color: "var(--main-color-one)" }}
                                className="fa fa-map-marker" /> <span
                                    className="c2">{item.location !== null ? item.location : 'N/A'}</span></p>
                            <p className="headline mt-3">Ministry/Department: <span
                                className="c2">{item.company?.name}</span></p>
                            {/*<p className="headline">Quantity: <span*/}
                            {/*    className="c2">{item.qty !== null ? item.qty : 'N/A'}</span></p>*/}
                            <p className="headline">Tender Value: <span
                                className="c1">{item.tenderValue === 0 ? 'N/A' :
                                    "Rs. " + numberWithCommas(item.tenderValue.toFixed(2))}</span></p>


                            <p className="headline">Industry: <span className="c3">{
                                item.category.map((item, index) => index === 0 ? item.name : ", " + item.name)
                            }</span></p>
                            <p className="headline">Sector: <span className="c3">{
                                item.subCategory.map((item, index) => index === 0 ? item.name : ", " + item.name)
                            }</span></p>
                            <p className="headline">Source: <span
                                className="c2">{item.source !== null ? item.source : 'N/A'}</span></p>

                            <div className="separator mt-3 mb-3" />

                            <div className="row">
                                <div className="col-md-4 col-sm-12 col-xs-12">
                                    <p className="headline">Published On: <span
                                        className="c2">{moment(item.publishedDate).format(constants.DATE_FORMAT_EXTEND)}</span>
                                    </p>
                                </div>
                                <div className="col-md-4 col-sm-12 col-xs-12 mb-2">
                                    <p className="headline">Closing On: <span
                                        className="c2">{moment(item.closingDate).format(constants.DATE_FORMAT_EXTEND)}</span>
                                    </p>
                                </div>
                                {
                                    this.props.location.state !== undefined && this.props.location.state.currentStatus.action === "EXPIRED" ?
                                        <div className="col-md-4 col-sm-12 col-xs-12">
                                            <p className="headline pl-2 pr-2" style={{
                                                background: "rgba(255, 110, 99, 0.15)",
                                                color: "var(--main-color-two)",
                                                borderColor: "var(--main-color-two)",
                                                textAlign: 'center',
                                                width: 'fit-content',
                                                borderRadius: 5,
                                                border: '1px solid'
                                            }}>{pr.expired}</p>
                                        </div>
                                        :
                                        <div className="col-md-4 col-sm-12 col-xs-12">
                                            <p className="headline pl-2 pr-2" style={{
                                                background: dateDiff(item) <= 0 ? "rgba(255, 110, 99, 0.15)" :
                                                    dateDiff(item) > 0 && dateDiff(item) <= 5 ? "rgba(243, 141, 16, 0.15)" : "rgba(33, 170, 104, 0.15)",
                                                color: dateDiff(item) < 0 ? "var(--main-color-two)" :
                                                    dateDiff(item) === 0 || dateDiff(item) > 0 && dateDiff(item) <= 5 ? "var(--main-color-four)" : "var(--main-color-one)",
                                                borderColor: dateDiff(item) < 0 ? "var(--main-color-two)" :
                                                    dateDiff(item) === 0 || dateDiff(item) > 0 && dateDiff(item) <= 5 ? "var(--main-color-four)" : "var(--main-color-one)",
                                                textAlign: 'center',
                                                width: 'fit-content',
                                                borderRadius: 5,
                                                border: '1px solid'
                                            }}>{
                                                    dateDiff(item) < 0 ? pr.expired : dateDiff(item) === 0 ? pr.lessThanADay : dateDiff(item) + 1 + pr.daysRemaining
                                                }</p>
                                        </div>
                                }

                            </div>

                        </div>

                        <div className="separator mt-3 mb-3" />

                        <p className="title ">{pr.rDescription}</p>
                        <div className="p-3">
                            <p className="headline lineHeight"><span
                                className="c2 lineHeight whiteSpacePre">{item.description}</span></p>
                        </div>

                        {
                            item.sellerFollowupStatus === 'WON' ?
                                <button className="btn btn-yellow"
                                    onClick={() => this.onFav(item.id, 'PENDING')}
                                ><i
                                        className="fa fa-trophy mr-2" />Awarded</button> :
                                item.sellerFollowupStatus === 'LOST' ?
                                    <button className="btn btn-white"
                                        onClick={() => this.onFav(item.id, 'PENDING')}
                                    ><i
                                            className="fa fa-ban mr-2" />Lost</button> :
                                    <>
                                        <button className="btn btn-white mr-2"
                                            onClick={() => this.onFav(item.id, 'WON')}>
                                            <i className="fa fa-trophy mr-2" />Award
                                        </button>
                                        <button className="btn btn-red" onClick={() => this.onFav(item.id, 'LOST')}>
                                            <i className="fa fa-ban mr-2" />Lost
                                        </button>
                                    </>
                        }

                        <div className="separator mt-3 mb-3" />


                        {
                            item.attachment !== null &&
                            <>
                                <p className="title">{pr.attachment}</p>

                                <div className="row">
                                    <div className="col-md-4 col-sm-12 mt-3 mb-3">

                                        <a className="btn btn-yellow"
                                            type="button"
                                            href=""
                                            id="downloadbtn"
                                            download={item.attachment.split(/(\\|\/)/g).pop()}>{pr.download}</a>

                                    </div>

                                    {/* {fileExtension === constants.PNGEXTENTION ?
                                        <div className="col-12 mt-3" align={'center'}>
                                            <img src={item.attachment} />
                                        </div>
                                        :
                                        <div className="col-12 mt-3" align={'center'}>
                                            <FileViewer
                                                fileType={fileExtension}
                                                filePath={item.attachment}
                                                onError={(e) => console.log(e)}
                                            />
                                        </div>
                                    } */}
                                    {/* {
                                        fileExtension === constants.DOCEXTENTION || fileExtension === constants.DOCXEXTENTION ?
                                            <div className="col-12 mt-3" align={'center'}>
                                                <FileViewer
                                                    fileType={fileExtension}
                                                    filePath={item.attachment}
                                                    onError={(e) => console.log(e)}
                                                />
                                            </div>
                                            : */}
                                    <div className="col-12 mt-3" align={'center'}>
                                        <DocViewer documents={docs} pluginRenderers={fileExtension === constants.DOCEXTENTION || fileExtension === constants.DOCXEXTENTION ? DocViewerRenderers : [PDFRenderer, PNGRenderer, JPGRenderer]} />
                                    </div>
                                    {/* } */}


                                </div>
                            </>
                        }
                    </div>
                    {/*<div className="col-xl-3 col-lg-4 col-md-4 col-sm-12">*/}
                    {/*    <div className="profileDetailsHolder">*/}
                    {/*        <div className="companyDetails">*/}
                    {/*            <img className="logo"*/}
                    {/*                 src={item.company.profilePicture !== null ? item.company.profilePicture : Assets.companyLogo}*/}
                    {/*                 alt="img"/>*/}

                    {/*            <p className="title">{item.company.name !== null ? item.company.name : "N/A"}</p>*/}
                    {/*        </div>*/}
                    {/*        <div className="separator mt-3"/>*/}

                    {/*        <div className="p-3">*/}
                    {/*            <p className="title">{pr.contactDetails}</p>*/}

                    {/*            <p className="headline mt-3"><i*/}
                    {/*                className="fa fa-map-marker"/> &nbsp;&nbsp;{pr.address}</p>*/}
                    {/*            <p className="contactDetails">{item.company?.address !== null ? item.company.address : "N/A"}</p>*/}

                    {/*            <p className="headline mt-3"><i className="fa fa-mobile"/> &nbsp;&nbsp;{pr.mobile}*/}
                    {/*            </p>*/}
                    {/*            <p className="contactDetails">{item.company?.hotLine !== null ? item.company.hotLine : "N/A"}</p>*/}

                    {/*            <p className="headline mt-3"><i className="fa fa-envelope"/> &nbsp;&nbsp;{pr.email}*/}
                    {/*            </p>*/}
                    {/*            <p className="contactDetails">{item.company?.email !== null ? item.company.email : "N/A"}</p>*/}

                    {/*            <p className="headline mt-3"><i className="fa fa-globe"/> &nbsp;&nbsp;{pr.website}*/}
                    {/*            </p>*/}

                    {/*            <p className="contactDetails pointer"*/}
                    {/*               onClick={()=> openWebSite(item.company?.website)}*/}
                    {/*            >{item.company?.website !== null ? item.company.website : "N/A"}</p>*/}


                    {/*            /!*<button className="btn btn-yellow mt-4">{pr.contact}</button>*!/*/}
                    {/*        </div>*/}


                    {/*    </div>*/}
                    {/*</div>*/}
                </div>
            </div>
        </div> :
            <div className="display-flex flex-column  align-items-center"
                style={{ width: '100%' }}>
                {
                    this.state.isNoData ? <img src={Assets.empty} alt="empty" /> : null
                }
            </div>
    }
}

const mapStateToProps = (state) => ({
    loggedInStatus: state.user.loggedInStatus,
});

export default connect(mapStateToProps, null)(withRouter(ViewRequest));
