import React from "react";
import './globalComponents.scss'
import {breakPoint3} from "../../constants/constants";
import Slider from "react-slick";
import strings from "../../locals/string.json";

const landing = strings.landing;
export default (props) => {

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        autoplay: true,
        autoplaySpeed: 3000,
        slidesToShow: 6,
        swipeToSlide: true,
        slidesToScroll: 1,
        responsive: breakPoint3,
        rows: 2,
    };

    const getAllClients = () => {
        let clients = []
        const noOfImages = 136
        for (let i = 0; i < noOfImages; i++) {
            clients.push(require("../../assets/img/client-logos/" + (i + 1) + ".png"))
        }
        return clients
    }

    return <div className="properties-area pd-top-60 pd-bottom-60 popularSupplier">
        <div className="container">
            <div className="partner-title section-title">
                <span className="txt-sub-title">OUR</span>
                <h2 className="title">{landing.clients}</h2>
            </div>
            <Slider {...settings}>
                {getAllClients().map((item, i) =>
                    <div className="p-2" key={i}>
                        <div className="single-feature partnerHolder">
                            <img className="cat" src={item} alt="img"/>
                        </div>
                    </div>
                )}
            </Slider>
        </div>
    </div>
}
