import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import routes from "../../constants/routes";
import {Assets} from "../../assets";
import {connect} from "react-redux";
import {withRouter} from "react-router";

class Ads extends Component {


    render() {

        const inlineStyle = {
            backgroundImage: `url(${Assets.ad})`
        }

    return !this.props.loggedInStatus && <div className="call-to-action-area pt-5">
              <div className="container">
                <div className="call-to-action" style={ inlineStyle }>
                  <div className="cta-content">
                    <h3 className="title">Grow your business with ETenders.lk</h3>
                    <Link className="btn btn-white" to={ routes.register }>Register</Link>
                  </div>
                </div>
              </div>
          </div>
        }
}

const mapStateToProps = (state) => ({
    loggedInStatus: state.user.loggedInStatus,
});

export default connect(mapStateToProps, null)(withRouter(Ads));
