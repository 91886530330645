import React from "react";
import constants from "../../constants/constants";
import {getMySubscriptionData} from "../../service/suppliers";
import {ToastUtil} from "../../constants/toastUtil";
import moment from "moment";
import {Assets} from "../../assets";
import ChangePackage from "./changePackage";
import RenewPackage from "./renewPackage";
import './myProfile.scss'
import Cookies from "js-cookie";
import jwt_decode from "jwt-decode";

class MySubscription extends React.Component {

    state = {
        subscriptionData: undefined,
        isChangePackageModal: false,
        isRenewPackageModal: false,
        email: '',
        packages: []
    }

    componentWillMount() {
        let token = Cookies.get(constants.ACCESS_TOKEN);
        let user = jwt_decode(token)

        let dummyPlan = []
        let usr = localStorage.getItem(constants.USER_ORIGINAL_OBJ);
        let amount = JSON.parse(usr).account.renewalFee

        getMySubscriptionData()
            .then(response => {
                if (response.success) {
                    dummyPlan.push({
                        categoryCount: response.body.subscriptionPlan.categoryCount,
                        id: response.body.subscriptionPlan.id,
                        packageName: response.body.subscriptionPlan.packageName,
                        price: amount,
                        userCount: response.body.subscriptionPlan.userCount,
                        validityDays: response.body.subscriptionPlan.validityDays,
                        validityDuration: response.body.subscriptionPlan.validityDuration
                    })

                    this.setState({
                        subscriptionData: response.body,
                        email: user.user_name,
                        packages: dummyPlan
                    })
                } else {
                    ToastUtil.showErrorToast(response.msg);
                }
            })
    }

    changePackageModal = (val) => {
        this.setState({isChangePackageModal: val})
    }

    renewPackage = (val) => {
        this.setState({isRenewPackageModal: val})
    }

    render() {
        const item = this.state.subscriptionData;
        return <div className="mySubscription mt-4 p-4">
            <p className="subDetailTopic">Subscription Details</p>
            {
                this.state.subscriptionData ?
                    <div className="row">

                        <div className="col-xl-4 col-lg-3 col-md-12 col-sm-12  mt-3">
                            <div className="subscriptionHolder pb-4">
                                <h6 className="title">{item.subscriptionPlan.packageName}</h6>

                                <h6 className="priceTag">{item.subscriptionPlan.price !== 0 && "Rs"}
                                    <h6 className="price">{item.subscriptionPlan.price === 0 ? 'Free' : item.subscriptionPlan.price}</h6>
                                </h6>

                                <h6 className="priceTag">{item.subscriptionPlan.validityDuration}</h6>
                                <h6 className="userTag mt-4">{this.props.user.emailCount + 1} Email{this.props.user.emailCount + 1 > 1 ? 's' : ''}</h6>
                                <h6 className="userTag">{this.props.user.categoryCount} {this.props.user.categoryCount > 1 ? 'Categories' : 'Category'}</h6>

                                <button className="btn btn-yellow" onClick={() => this.changePackageModal(true)}>Change
                                    Package
                                </button>
                                {
                                    item.subscriptionPlan.packageName !== 'Trial'
                                        ?
                                        <h6 className="userTag renewPayment m-3"
                                            onClick={() => this.renewPackage(true)}>Renew
                                            Package</h6> : null
                                }
                            </div>
                        </div>
                        <div className="col-xl-8 col-lg-9 col-md-12 col-sm-12  mt-3">
                            <p className="topic mt-2">Valid Till
                                <p className="validity">: {moment.utc(item.expiryDate).local().format(constants.DATE_FORMAT_EXTEND)}</p>
                            </p>

                            {
                                item.paymentHistory.length !== 0 &&
                                <>
                                    <p className="topic">Payment History</p>
                                    {
                                        item.paymentHistory.map((item, index) => (
                                            <div className="row" key={index}>
                                                <div
                                                    className="col-xl-3 col-lg-4 col-md-12 col-sm-12">{moment(item.paymentDate).format(constants.DATE_FORMAT_EXTEND)}</div>
                                                <div
                                                    className="col-xl-6 col-lg-6 col-md-12 col-sm-12">{item.paymentMethod + " " + item.paymentType} PAYMENT
                                                </div>
                                                <div
                                                    className="col-xl-3 col-lg-4 col-md-12 col-sm-12 text-right">Rs. {item.amount.toFixed(2)}</div>
                                                <div className="separator m-2"/>
                                            </div>
                                        ))
                                    }
                                </>
                            }
                        </div>
                    </div> :
                    <div className="display-flex flex-column  align-items-center"
                         style={{width: '100%'}}>
                        {/*<img src={Assets.empty} alt="empty"/>*/}
                    </div>
            }

            <ChangePackage state={{...this.state}} handler={this.changePackageModal}/>
            <RenewPackage user={this.props.user} state={{...this.state}} handler={this.renewPackage}/>
        </div>
    }
}

export default MySubscription;
