import React, {Component} from 'react';
import {Assets} from "../../assets";
import {connect} from "react-redux";
import {withRouter} from "react-router";

class Banner extends Component {

    state = {
        cover: Assets.subBanner2
    }

    componentWillReceiveProps(nextProps, nextContext) {
        let pathname = window.location.pathname.substring(1)
        if (pathname == 'my-profile') {
            this.setState({
                cover: this.props.user.coverImage ? this.props.user.coverImage : Assets.subBanner2
            })
        }
    }

    render() {
        const inlineStyle = {
            backgroundImage: `url(${this.state.cover})`
        }
        return (
            <div className="breadcrumb-area jarallax" style={inlineStyle}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="breadcrumb-inner">
                                <h1 className="page-title">{this.props.user?.companyName !== null ? this.props.user?.companyName : "Not Found"}</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    user: state.user.user,
});

export default connect(mapStateToProps, null)(withRouter(Banner));
