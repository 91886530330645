import ApiService from './apiHandler';

export async function getAllSuppliers(index,size,data) {
    const apiObject = {};
    apiObject.method = 'POST';
    apiObject.endpoint = `seller/view-all?index=${index}&size=${size}`;
    apiObject.body = data
    apiObject.authentication = true
    return await ApiService.callApi(apiObject);
}

export async function getSelectedSupplier(company_url) {
    const apiObject = {};
    apiObject.method = 'GET';
    apiObject.endpoint = `seller/${company_url}`;
    apiObject.authentication = true
    return await ApiService.callApi(apiObject);
}

export async function getSupplierProducts(index,size,data) {
    const apiObject = {};
    apiObject.method = 'POST';
    apiObject.endpoint = `seller/product/view-all?index=${index}&size=${size}`;
    apiObject.body = data
    apiObject.authentication = true
    return await ApiService.callApi(apiObject);
}

export async function getSupplierReview(id,index,size) {
    const apiObject = {};
    apiObject.method = 'GET';
    apiObject.endpoint = `seller/${id}/review?index=${index}&size=${size}`;
    apiObject.authentication = true
    return await ApiService.callApi(apiObject);
}

export async function addReview(data) {
    const apiObject = {};
    apiObject.method = 'POST';
    apiObject.endpoint = `user/review`;
    apiObject.body = data
    apiObject.authentication = true
    return await ApiService.callApi(apiObject);
}

export async function getSupplierCategories() {
    const apiObject = {};
    apiObject.method = 'GET';
    apiObject.endpoint = `user/category/view-all`;
    apiObject.authentication = true
    return await ApiService.callApi(apiObject);
}

export async function updateUser(data) {
    const apiObject = {};
    apiObject.method = 'POST';
    apiObject.endpoint = `user/profile-update`;
    apiObject.body = data
    apiObject.authentication = true
    apiObject.multipart = true
    return await ApiService.callApi(apiObject);
}

export async function removeImage(type) {
    const apiObject = {};
    apiObject.method = 'DELETE';
    apiObject.endpoint = `user/remove-image/${type}`;
    apiObject.authentication = true
    return await ApiService.callApi(apiObject);
}

export async function requestMoreCategory() {
    const apiObject = {};
    apiObject.method = 'GET';
    apiObject.endpoint = `email/category-addon`;
    apiObject.authentication = true
    return await ApiService.callApi(apiObject);
}

export async function getMySubscriptionData() {
    const apiObject = {};
    apiObject.method = 'GET';
    apiObject.endpoint = `user/subscription-details`;
    apiObject.authentication = true;
    return await ApiService.callApi(apiObject);
}
