import React from "react";
import strings from "../../locals/string.json";
import {Assets} from "../../assets";

const registration = strings.registration;

export default (props) => {
    return <form className="contact-form-wrap contact-form-bg package">
        <div className="row">
            {
                props.state.packages.map((item, index) => (
                    <div className="col-lg-3 col-md-6 col-sm-12 padding15"
                         onClick={() => props.onSelectSubscription(index, item.id)}>
                        <div className="packageHolder">
                            <img className="cardBg" src={Assets.cardBg5} alt={'check'}/>

                            <h6 className="title">{item.subscriptionName}</h6>

                            <h6 className="priceTag">{item.price !== 0 && "Rs"}
                                <h6 className="price">{item.price === 0 ? 'Free' : item.price}</h6>
                            </h6>

                            <h6 className="priceTag">{item.validityDuration}</h6>
                            <h6 className="userTag mt-4">{item.userCount + 1} Email{item.userCount + 1 > 1 ? 's' : ''}</h6>
                            <h6 className="userTag">{item.categoryCount} {item.categoryCount > 1 ? 'Categories' : 'Category'}</h6>

                            {
                                props.subscription_plan === index ?
                                    <button className="btn btn-yellow mb-4"
                                            onClick={event => event.preventDefault()}>{registration.selected}
                                        <img className="checkedImg" src={Assets.biCheck} alt={'check'}/>
                                    </button> :
                                    <button className="btn btn-white mb-4"
                                            onClick={event => event.preventDefault()}>{registration.selectPlan}
                                    </button>
                            }
                        </div>
                    </div>
                ))
            }
        </div>
    </form>
}
