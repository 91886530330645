import React, {useState} from "react";
import strings from "../../locals/string.json";
import {Checkbox} from 'antd';
import Select from 'react-select'
import './categorySelection.scss'

const registration = strings.registration;

class StepTwo extends React.Component {

    state = {
        load: false,
        seeMore: false,
        selectedId: -1,
        active: 0,
    }

    async componentWillMount() {
        for (let i = 1; i < this.props.categoryCount + 1; i++) {
            await this.setState({
                ['c' + i]: false
            })
        }
        await this.setState({load: true, active: this.props.active ?? 0})
    }

    onCategoryChange = async (val, i) => {
        let value, subId;
        const props = this.props;
        if (val.toString().startsWith('sub')) {
            val = val.replace('sub', '').split('_');
            value = Number(val[1]);
            subId = Number(val[0]);
        } else {
            value = val;
        }

        await props.setCategory(i, props.state.categories.findIndex(e => e.id === value))
        await props.handleSubCategoryChange(i, false)
        await props.onCategoryChange((i - 1), props.state.categories.findIndex(e => e.id === value));

        if (subId) {
            props.handleSubCategoryChange(i, true, subId);
            const selectedCategory = props.state.categories[props.state.categories.findIndex(e => e.id === value)]
            const index = selectedCategory?.subCategory.findIndex(e => e.id === subId);

            props.onSubCategoryChange(selectedCategory, selectedCategory?.subCategory[index]);
            this.setState({
                seeMore: true,
                selectedId: i,
            })
        }
    }

    render() {
        const catLength = this.props.categoryCount;
        const props = this.props;
        const {seeMore, selectedId} = this.state;
        const disabled = props.disabled;

        let categories = [];
        if (this.state.load) {
            for (let i = 1; i < catLength + 1; i++) {
                const options = [];
                {
                    props.state.categories.filter(x => !props.state.catIdArray.includes(x.id) || x.id === props.state.catIdArray[i - 1])
                        .map((cate, index) => {
                            options.push({value: cate.id, label: cate.name})
                        })
                }
                {
                    props.state.categories.filter(x => !props.state.catIdArray.includes(x.id) || x.id === props.state.catIdArray[i - 1])
                        .map((cate, index) => {
                            cate.subCategory.map((subCate, subCateIndex) => {
                                options.push({
                                    value: 'sub' + subCate.id + '_' + cate.id,
                                    label: cate.name + " - " + subCate.name
                                })
                            })
                        })
                }
                this.state.active >= i && categories.push(
                    <div className={'category-selection'} key={i}>
                        <div className="mt-4 display-flex " style={{width: '100%'}}>
                            <h6>{registration.category} 0{i}:</h6>

                            {
                                !disabled && props.state["cat" + i] &&
                                <h6 className="pointer viewAll text-danger"
                                    onClick={() => {
                                        this.setState({
                                            ['c' + i]: false
                                        })
                                        props.handleSubCategoryChange(i, false);
                                        props.onSelectAllSubCategory(props.state["cat" + i], false)
                                        props.clearSelection(i);
                                    }}
                                >Clear Selection</h6>
                            }

                        </div>

                        <div className="row">
                            <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12 col-12">
                                <Select
                                    className="my-category basic-single mb-3"
                                    classNamePrefix="select"
                                    value={props.state["cat" + i] ? {
                                        value: props.state["cat" + i].id,
                                        label: props.state["cat" + i].name
                                    } : null}
                                    isDisabled={disabled}
                                    isSearchable={true}
                                    options={options}
                                    placeholder={'Select Category 0' + i}
                                    onChange={({value}) => this.onCategoryChange(value, i)}
                                />
                            </div>
                            {
                                !disabled && props.state["cat" + i] &&
                                <div className="col-12">

                                    <h6 className="pointer viewAll2"
                                        onClick={() => {
                                            this.setState({
                                                ['c' + i]: !this.state['c' + i]
                                            })
                                            props.handleSubCategoryChange(i, !this.state['c' + i]);
                                            props.onSelectAllSubCategory(props.state["cat" + i], !this.state['c' + i])
                                        }}
                                    >{
                                        this.state['c' + i] ? 'Un Select All' : 'Select All'
                                    }</h6>
                                </div>
                            }

                            <div className="col-12 pl-4">
                                <div className="row">
                                    {
                                        props.state["cat" + i]?.subCategory.map((item, j) => {

                                            // if (!(seeMore && selectedId === i) && j < 4) {
                                            //     return (
                                            //         <div className="col-md-6 col-sm-12 mt-2" key={j}>
                                            //             <Checkbox
                                            //                 disabled={disabled}
                                            //                 checked={item.checked}
                                            //                 onChange={e => {
                                            //                     props.handleSubCategoryChange(i, e.target.checked, item.id)
                                            //                     props.onSubCategoryChange(props.state["cat" + i], item)
                                            //                 }}>{item.name}</Checkbox>
                                            //         </div>
                                            //     )
                                            // }
                                            // if ((seeMore && selectedId === i)) {
                                            return (
                                                <div className="col-md-6 col-sm-12 mt-2" key={j}>
                                                    <Checkbox
                                                        disabled={disabled}
                                                        checked={item.checked}
                                                        onChange={e => {
                                                            props.handleSubCategoryChange(i, e.target.checked, item.id)
                                                            props.onSubCategoryChange(props.state["cat" + i], item)
                                                        }}>{item.name}</Checkbox>
                                                </div>
                                            )
                                            // }

                                        })
                                    }
                                </div>
                            </div>

                            {/*<div className="mt-1 display-flex justify-content-end" style={{width: '100%'}}>*/}
                            {/*    {*/}
                            {/*        props.state["cat" + i]?.subCategory.length > 4 &&*/}
                            {/*        <h6 className="pointer viewAll mr-3" onClick={async () => {*/}
                            {/*            await this.setState({*/}
                            {/*                seeMore: i !== selectedId,*/}
                            {/*                selectedId: i === selectedId ? -1 : i*/}
                            {/*            })*/}
                            {/*        }}>{(seeMore && selectedId === i) ? "View Less" : "View All"}</h6>*/}
                            {/*    }*/}
                            {/*</div>*/}
                        </div>
                    </div>
                )
            }
        }

        return <form className="contact-form-wrap contact-form-bg">
            {categories}
            {
                this.state.active < catLength &&
                <div className="display-flex justify-content-center" style={{width: '100%'}}>
                    <button type="button" className="btn btn-white mb-5"
                            onClick={() => this.setState({active: this.state.active + 1})}>+ Add New
                    </button>
                </div>
            }
        </form>
    }
}

export default StepTwo;
