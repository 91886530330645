import axios from 'axios';
import apiConfig from './apiConfig';
import Cookies from "js-cookie";
import swal from "sweetalert";
import qs from "qs";
import constants from "../constants/constants";
import {removeCookiesValues} from "../constants/commonFunc";
import routes from "../constants/routes";
import * as authService from "./auth";
import $ from 'jquery';

export const callApi = async(apiObject) => {
    $(".preloader").css("display", "block")
    let body = {};
    let method = apiObject.method?.toLowerCase() ?? 'get';

    if (method === 'post' || method === 'put' || method === 'patch') {
        body = apiObject.body ?? {};
    }

    let headers = {
        'Content-Type': apiObject.urlEncoded ? 'application/x-www-form-urlencoded' : apiObject.multipart ? 'multipart/form-data' : 'application/json',
    };

    if (apiObject.authentication) {
        let access_token = Cookies.get(constants.ACCESS_TOKEN);
        if (access_token) {
            headers.Authorization = `Bearer ${access_token}`;
        }
    }

    if (apiObject.isBasicAuth) {
        headers.Authorization = `Basic ${apiObject.ETUser ? constants.BASIC_AUTH_E_TENDERS : constants.BASIC_AUTH}`;
    }

    const url = `${apiConfig.serverUrl}/${apiConfig.basePath}/${(apiObject.state !== "renewToken" && apiObject.state !== "login") ? apiConfig.version : "" }${apiObject.endpoint}`;
    let result;
    await axios[method](url, (method !== 'get' && method !== 'delete') ? body : {headers: headers}, {headers: headers})
        .then(response => {
            $(".preloader").css("display", "none")
            result = {...response.data, success: response.data.success ?? true}
        })
        .catch(async error => {
            $(".preloader").css("display", "none")
            if(apiObject.note === constants.VERIFY_USER_NOTE){
                await removeCookiesValues();
                window.location = routes.login;
            }

            if (error?.response === undefined){
                result = {success: false, msg: "Your connection was interrupted"}
            }

            if (error?.response?.status === 401) {
                if (apiObject.state === "renewToken") {
                    result = {success: false, msg: "Your session has expired. Please sign in again ..."};
                    return;
                }
                if (apiObject.state === "login") {
                    result = {success: false, msg: error?.response?.data?.msg ?? "Invalid login details please try again!"};
                    return;
                }
                result = await renewTokenHandler(apiObject);
            } else if (error?.response?.data) {
                result = {
                    success: false,
                    msg: error.response.data.message,
                }
            } else {
                result = {
                    success: false,
                    msg: "Your connection was interrupted!",
                };
            }

        })
    return result;
}

export const renewTokenHandler = async (apiObject) => {
    let result;
    const obj = {refresh_token: Cookies.get(constants.REFRESH_TOKEN), grant_type: 'refresh_token'};
    await authService.renewToken(qs.stringify(obj))
        .then(async response => {
            if (response.access_token) {
                Cookies.set(constants.ACCESS_TOKEN, response.access_token);
                Cookies.set(constants.REFRESH_TOKEN, response.refresh_token);
                result = await callApi(apiObject);
            } else {
                result = await response;
                swal({
                    title: response.msg,
                    icon: null,
                    closeOnClickOutside: false,
                    buttons: {
                        dangerMode: {text: "Ok", value: "action", className: "swalSuccess"}}})
                    .then((value) => {
                        switch (value) {
                            case "action":
                                removeCookiesValues();
                                window.location = routes.login;
                                break;
                            default:
                                break;
                        }
                    });
            }
        });
    return result;
}

export default {renewTokenHandler,callApi};
