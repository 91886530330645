import React, {useEffect} from 'react';
import Navbar from '../components/globalComponents/navbar';
import Footer from '../components/globalComponents/footer';
import Banner from '../components/banner/subBanner';
import ViewRequest from '../components/purchaseRequest/viewRequest';

const ViewRequests = (props) => {

    useEffect(()=>{
        document.querySelector('body').scrollTo(0,0)
    },[])

    return <div>
        <Navbar/>
        <Banner headertitle="View Tender"/>
        <ViewRequest
            history={props.history}
            match={props.match}
            location={props.location}/>
        <Footer/>
    </div>
}

export default ViewRequests
