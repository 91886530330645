import React, {useEffect} from 'react';
import Navbar from '../components/globalComponents/navbar';
import Banner from '../components/banner/subBanner';
import Footer from "../components/globalComponents/footer";
import NewsDetailsSection from '../components/globalComponents/news-details';
import {Assets} from "../assets";

const NewsDetails = (props) => {
    useEffect(() => {
        document.querySelector('body').scrollTo(0, 0)
    }, [])

    return <div>
        <Navbar/>
        <Banner headertitle="Blog" background={Assets.blogBg}/>
        <NewsDetailsSection history={props.history} location={props.location}/>
        <Footer/>
    </div>
}

export default NewsDetails

