import {Assets} from "../assets";

export const __DEV__ = window.location.hostname === 'localhost' || window.location.hostname === 'cdev.etenders.lk';


const constants = {
    USER_SELLER: 'B2B_SELLER',
    USER_BUYER: 'B2B_BUYER',
    USER_GUEST: 'B2B_GUEST',
    E_TENDER_SELLER: 'E_TENDER_SELLER',

    AWARDED: 'WON',
    PENDING: 'PENDING',
    INTERESTED: 'INTERESTED',
    NOT_INTERESTED: 'NOT_INTERESTED',

    TELEPHONE: 'CALL',
    EMAIL: 'EMAIL',
    WHATSAPP: 'WHATSAPP',
    ALL: 'ALL',

    DATE_FORMAT: 'DD MMM YYYY',
    DATE_FORMAT_EXTEND: 'DD MMM YYYY',
    DATE_FORMAT_WITH_TIME_EXTEND: 'DD MMM YYYY hh:ss',

    ACCESS_TOKEN: 'FpZDWwZxnE',
    USER_OBJ: 'FpZDWwZxnEXakWkL',
    USER_ORIGINAL_OBJ: 'fGdrHSYsFhgfewW',
    REFRESH_TOKEN: 'RtEw67ZUI6bg',
    BASIC_AUTH: 'RVRfU0VMTEVSOg==',
    BASIC_AUTH_E_TENDERS: 'QjJCX0VfVEVOREVSX1VTRVI6',
    VERIFY_USER_NOTE: "VERIFY_USER_NOTE",
    REDIRECT_TO_HOME: "REDIRECT_TO_HOME",

    user: 'https://muktiindiauk.com/images/team/administrato.png',
    // gatewayUrl: 'https://cbcmpgs.gateway.mastercard.com/form/version/52/merchant/WEBXPATOKLKR/session.js',

    //testing
    gatewayUrl: 'https://test-gateway.mastercard.com/form/version/55/merchant/0020008/session.js',

    //live
    // gatewayUrl: 'https://ap-gateway.mastercard.com/form/version/55/merchant/00248/session.js',

    reCaptureSiteKey: '6Lfm2zYcAAAAAPkApsUgt9b3lB8AMtqtedOxh6cQ',
    DEFAULT: "DEFAULT",

    VIEW_TENDER: '5052536000b74de7ab67b005c858ef69',

    PNGEXTENTION: "PNG",
    DOCEXTENTION: "doc",
    DOCXEXTENTION: "docx",
}

export const categoryActions = {
    GET_ALL: 'GET_ALL',
    AVAILABLE: 'AVAILABLE',
    POPULAR: 'POPULAR'
}

export default constants;

export const QUOTATIONS_STATUS = [
    {
        key: "Awarded",
        value: "WON"
    },
    {
        key: "Interested",
        value: "INTERESTED"
    },
    {
        key: "Not-Interested",
        value: "NOT_INTERESTED"
    },
    {
        key: "Pending",
        value: "PENDING"
    }
]

export const QUOTATIONS_STATUS_SELLER = [
    {
        key: "Pending",
        value: "PENDING"
    },
    {
        key: "Awarded",
        value: "WON"
    },
    {
        key: "Rejected",
        value: "NOT_INTERESTED"
    }
]

export const REQUEST_STATUS = [
    {
        key: "Completed",
        value: "AWARDED"
    },
    // {
    //     key: "Rejected",
    //     value: "REJECTED"
    // },
    {
        key: "On Going",
        value: "PENDING"
    }
]

// export const DISTRICTS = [
//     "Colombo", "Gampaha", "Kalutara", "Puttalam", "Kurunegala", "Jaffna", "Kilinochchi", "Mannar",
//     "Mullaitivu", "Vavuniya", "Anuradhapura", "Polonnaruwa", "Matale", "Kandy",
//     "Nuwara Eliya", "Kegalle", "Ratnapura", "Trincomalee", "Batticaloa", "Ampara", "Badulla", "Monaragala", "Hambantota", "Matara", "Galle"
// ]

export const DISTRICTS = ["Central Province", "North Central Province", "Northern Province", "North Western Province", "Sabaragamuwa Province", "Southern Province", "Uva Province", "Western Province", "Eastern Province"]

export const categories = [
    {
        id: 0,
        name: "Computer & IT",
        subCategory: [
            {
                id: 0,
                name: "Computer Software",
                checked: false
            },
            {
                id: 1,
                name: "Computer Hardware & consumables",
                checked: false
            },
            {
                id: 2,
                name: "Interactive Voice Response System",
                checked: false
            },
            {
                id: 3,
                name: "Computer Hardware & consumables",
                checked: false
            }
        ]
    },
    {
        id: 1,
        name: "Agriculture",
        subCategory: [
            {
                id: 4,
                name: "Software Computer",
                checked: false
            },
            {
                id: 5,
                name: "consumables & Computer Hardware",
                checked: false
            },
            {
                id: 6,
                name: "Interactive Voice Response System",
                checked: false
            },
            {
                id: 7,
                name: "Computer Hardware & consumables",
                checked: false
            }
        ]
    },
    {
        id: 2,
        name: "Furniture",
        subCategory: [
            {
                id: 8,
                name: "Interactive Voice Response System",
                checked: false
            },
            {
                id: 9,
                name: "Computer Hardware & consumables",
                checked: false
            },
            {
                id: 10,
                name: "Computer Software",
                checked: false
            },
            {
                id: 11,
                name: "Computer Hardware & consumables",
                checked: false
            }
        ]
    },
    {
        id: 3,
        name: "Constructions",
        subCategory: [
            {
                id: 12,
                name: "Computer Software",
                checked: false
            },
            {
                id: 13,
                name: "Computer Hardware & consumables",
                checked: false
            },
            {
                id: 14,
                name: "Interactive Voice Response System",
                checked: false
            },
            {
                id: 15,
                name: "Computer Hardware & consumables",
                checked: false
            }
        ]
    },
    {
        id: 4,
        name: "Kids",
        subCategory: [
            {
                id: 16,
                name: "Computer Software",
                checked: false
            },
            {
                id: 17,
                name: "Computer Hardware & consumables",
                checked: false
            },
            {
                id: 18,
                name: "Interactive Voice Response System",
                checked: false
            },
            {
                id: 19,
                name: "Computer Hardware & consumables",
                checked: false
            }
        ]
    }
]

export const packages = [
    {
        id: 0,
        subscriptionName: "Trial",
        validityDuration: "1 Month",
        amountOfCategory: 3,
        amountOfUsers: 3,
        price: 0
    },
    {
        id: 1,
        packageName: "Bronze",
        validityDuration: "1 Month",
        categoryCount: 3,
        userCount: 3,
        price: 28000
    },
    {
        id: 2,
        subscriptionName: "Silver",
        validityDuration: "2 Month",
        amountOfCategory: 3,
        amountOfUsers: 2,
        price: 48000
    },
    {
        id: 3,
        subscriptionName: "Gold",
        validityDuration: "2 Month",
        amountOfCategory: 3,
        amountOfUsers: 2,
        price: 65000
    }
]

export const popularSuppliers = [
    {
        id: 0,
        companyName: 'Vegas Landville Apartment',
        firstName: "Test",
        lastName: "Test",
        hotLine: '0382222222',
        designation: 'Software Engineer',
        landNumber: '0382222222',
        address: 'No 100, Panadura',
        email: "test@email.com",
        secondaryEmails: "test@email.com",
        location: 'Kaluthara',
        profilePicture: 'https://negosentro.com/wp-content/uploads/2020/08/pexels-pixabay-269077-696x464.jpg',
        description: 'Lacinia dui, amet, ut netus tempor, eget ut vitae massa. Eu tristique nisi, enim in risus blandit sed. Velit condimentum feugiat velit amet integer. Tristique nunc pharetra aenean etiam massa sem. Morbi lacus interdum velit quam risus. Sem vitae morbi purus nisl massa adipiscing sit diam habitant. At amet gravida cursus cursus congue nisl vitae amet. Tristique eu facilisis massa convallis. Cursus sodales aliquam ut fringilla blandit arcu. Metus pharetra magna nunc, donec vitae massa sit risus. At diam, in nunc iaculis tristique amet dictum massa. \n' +
            '\n' +
            'Lacinia dui, amet, ut netus tempor, eget ut vitae massa. Eu tristique nisi, enim in risus blandit sed. Velit condimentum feugiat velit amet integer. Tristique nunc pharetra aenean etiam massa sem. Morbi lacus interdum velit quam risus. ',
        website: ".com",
        coverImage: "https://pngimage.net/wp-content/uploads/2018/06/white-design-png-4.png",
        ratingValue: 3.75,
        ratingCount: 12,
        categories: [
            {
                id: 0,
                name: "Computer & IT",
                image: ".png",
                subCategory: [
                    {
                        id: 0,
                        name: "Test",
                        checked: true
                    }
                ]
            },
            {
                id: 1,
                name: "Agriculture",
                image: ".png",
                subCategory: [
                    {
                        id: 4,
                        name: "Test",
                        checked: false
                    }
                ]
            },
            // {
            //     id: 2,
            //     name: "Constructions",
            //     image: ".png",
            //     subCategory: [
            //         {
            //             id: 8,
            //             name: "Test",
            //             checked: true
            //         }
            //     ]
            // }
        ]
    },
    {
        id: 1,
        firstName: "Test",
        lastName: "Test",
        designation: 'Software Engineer',
        companyName: 'Vegas Landville Apartment',
        hotLine: '0382222222',
        landNumber: '0382222222',
        email: "test@email.com",
        secondaryEmails: "test@email.com",
        address: 'No 100, Panadura',
        location: 'Kaluthara',
        profilePicture: null,
        description: 'Sample Description',
        website: ".com",
        coverImage: null,
        ratingValue: 3,
        ratingCount: 2,
        categories: [
            {
                id: 0,
                name: "Computer & IT",
                image: ".png",
                subCategory: [
                    {
                        id: 0,
                        name: "Test"
                    }
                ]
            },
        ]
    },
    {
        id: 2,
        firstName: "Test",
        lastName: "Test",
        designation: 'Software Engineer',
        companyName: 'Vegas Landville Apartment',
        hotLine: '0382222222',
        landNumber: '0382222222',
        address: 'No 100, Panadura',
        location: 'Kaluthara',
        email: "test@email.com",
        secondaryEmails: "test@email.com",
        profilePicture: 'https://negosentro.com/wp-content/uploads/2020/08/pexels-pixabay-269077-696x464.jpg',
        description: 'Sample Description',
        website: ".com",
        coverImage: null,
        ratingValue: 2.5,
        ratingCount: 10,
        categories: [
            {
                id: 0,
                name: "Computer & IT",
                image: ".png",
                subCategory: [
                    {
                        id: 0,
                        name: "Test"
                    }
                ]
            },
        ]
    },
    {
        id: 3,
        firstName: "Test",
        lastName: "Test",
        designation: 'Software Engineer',
        companyName: 'Vegas Landville Apartment',
        hotLine: '0382222222',
        landNumber: '0382222222',
        address: 'No 100, Panadura',
        location: 'Kaluthara',
        email: "test@email.com",
        secondaryEmails: "test@email.com",
        profilePicture: 'https://negosentro.com/wp-content/uploads/2020/08/pexels-pixabay-269077-696x464.jpg',
        description: 'Sample Description',
        website: ".com",
        coverImage: null,
        ratingValue: 0,
        ratingCount: 0,
        categories: [
            {
                id: 0,
                name: "Computer & IT",
                image: ".png",
                subCategory: [
                    {
                        id: 0,
                        name: "Test"
                    }
                ]
            },
        ]
    },
    {
        id: 4,
        firstName: "Test",
        lastName: "Test",
        designation: 'Software Engineer',
        companyName: 'Vegas Landville Apartment',
        hotLine: '0382222222',
        landNumber: '0382222222',
        address: 'No 100, Panadura',
        location: 'Kaluthara',
        email: "test@email.com",
        secondaryEmails: "test@email.com",
        profilePicture: 'https://negosentro.com/wp-content/uploads/2020/08/pexels-pixabay-269077-696x464.jpg',
        description: 'Sample Description',
        website: ".com",
        coverImage: null,
        ratingValue: 0,
        ratingCount: 0,
        categories: [
            {
                id: 0,
                name: "Computer & IT",
                image: ".png",
                subCategory: [
                    {
                        id: 0,
                        name: "Test"
                    }
                ]
            },
        ]
    },
]

export const testimonials = {
    "title1": "What Our Customers ",
    "title2": "Are Saying ",
    "items": [
        {
            "name": "Varun Vachhar",
            "designation": "Chief Technology Officer",
            "image": constants.user,
            "company": "Ceyentra Technologies",
            "content": "a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source. Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of \"de Finibus Bonorum et Malorum\" "
        },
        {
            "name": "Tayla Elias",
            "designation": "CEO",
            "image": constants.user,
            "company": "Ceyentra Technologies",
            "content": "a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source. Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of \"de Finibus Bonorum et Malorum\" "
        },
        {
            "name": "Luca Inwood",
            "designation": "Land Owner",
            "image": constants.user,
            "company": "Ceyentra Technologies",
            "content": "a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source. Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of \"de Finibus Bonorum et Malorum\" "
        },
        {
            "name": "George Kavel",
            "designation": "Land Owner",
            "image": constants.user,
            "company": "Ceyentra Technologies",
            "content": "a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source. Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of \"de Finibus Bonorum et Malorum\" "
        }

    ]
}

export const ratings = [
    {
        id: 0,
        comment: 'Amet, sit nunc, enim vulputate eget proin etiam id. Orci commodo nunc aliquam ullamcorper morbi at quis vel molestie. Ullamcorper sollicitudin augue felis amet morbi sed a egestas sem. Nisl tincidunt eu velit parturient. Gravida varius justo.',
        rate: 4.5,
        from: {
            firstName: "Jane",
            lastName: "Smith",
            companyName: "Barclays International",
            profilePicture: "https://negosentro.com/wp-content/uploads/2020/08/pexels-pixabay-269077-696x464.jpg"
        }
    }, {
        id: 0,
        comment: 'Amet, sit nunc, enim vulputate eget proin etiam id. Orci commodo nunc aliquam ullamcorper morbi at quis vel molestie. Ullamcorper sollicitudin augue felis amet morbi sed a egestas sem. Nisl tincidunt eu velit parturient. Gravida varius justo.',
        rate: 4.5,
        from: {
            firstName: "Jane",
            lastName: "Smith",
            companyName: "Barclays International",
            profilePicture: "https://negosentro.com/wp-content/uploads/2020/08/pexels-pixabay-269077-696x464.jpg"
        }
    }
]

export const breakPoint = [
    {
        breakpoint: 520,
        settings: {
            slidesToShow: 1,
        },
    },
    {
        breakpoint: 768,
        settings: {
            slidesToShow: 2,
        },
    },
    {
        breakpoint: 992,
        settings: {
            slidesToShow: 2,
        },
    },
    {
        breakpoint: 1300,
        settings: {
            slidesToShow: 3,
        },
    },
]

export const breakPoint2 = [
    {
        breakpoint: 520,
        settings: {
            slidesToShow: 1,
        },
    },
    {
        breakpoint: 768,
        settings: {
            slidesToShow: 1,
        },
    },
    {
        breakpoint: 992,
        settings: {
            slidesToShow: 2,
        },
    },
    {
        breakpoint: 1300,
        settings: {
            slidesToShow: 2,
        },
    },
]

export const breakPoint3 = [
    {
        breakpoint: 420,
        settings: {
            slidesToShow: 1,
        },
    },
    {
        breakpoint: 550,
        settings: {
            slidesToShow: 2,
        },
    },
    {
        breakpoint: 768,
        settings: {
            slidesToShow: 3,
        },
    },
    {
        breakpoint: 992,
        settings: {
            slidesToShow: 4,
        },
    },
    {
        breakpoint: 1300,
        settings: {
            slidesToShow: 6,
        },
    },
]

export const breakPoint4 = [
    {
        breakpoint: 520,
        settings: {
            slidesToShow: 1,
        },
    },
    {
        breakpoint: 768,
        settings: {
            slidesToShow: 1,
        },
    },
    {
        breakpoint: 992,
        settings: {
            slidesToShow: 2,
        },
    },
    {
        breakpoint: 1300,
        settings: {
            slidesToShow: 2,
        },
    },
    {
        breakpoint: 1600,
        settings: {
            slidesToShow: 3,
        },
    },
]

/***********************************************************************************************************************
 *  ETenders
 ***********************************************************************************************************************/

export const tenders = [
    {
        id: "4786732",
        title: "Require 10 nos of Intel i5 10th gen laptops with 8GB RAM",
        qty: '100 units',
        tenderValue: 200000.00,
        description: "",
        publishedDate: "2021/08/24",
        closingDate: "2021/08/25",
        availability: true,
        attachment: "https://www.mtsac.edu/webdesign/accessible-docs/word/example03.docx",
        location: DISTRICTS[0],
        source: 'Daily news',
        subCategory: [
            {
                id: 1,
                name: "Computer Hardware & consumables",
            },
            {
                id: 2,
                name: "Interactive Voice Response System",
            },
        ],
        category: [
            {
                "id": "0",
                "name": "Electronic"
            },
        ],
        company: {
            name: 'State Engineering Corporation Of Sri Lanka',
            address: null,
            hotLine: null,
            email: null,
            profilePicture: null,
            website: null
        }
    },
    {
        id: "4786732",
        title: "Require 10 nos of Intel i5 10th gen laptops with 8GB RAM",
        qty: '100 units',
        tenderValue: 200000.00,
        description: "In malesuada sit consectetur facilisis praesent. Pulvinar morbi purus proin lobortis viverra habitasse commodo in. Nunc odio senectus massa dignissim consectetur. Scelerisque quis condimentum faucibus nulla cras amet libero et sit. Ac ut hac lorem viverra nunc id consequat in. Erat venenatis proin nulla lorem adipiscing ac nam. Id vestibulum morbi non.",
        publishedDate: "2021/06/31",
        closingDate: "2021/06/12",
        attachment: ".pfd",
        location: DISTRICTS[0],
        source: 'Daily news',
        subCategory: [
            {
                id: 1,
                name: "Computer Hardware & consumables",
            },
            {
                id: 2,
                name: "Interactive Voice Response System",
            },
        ],
        category: [
            {
                "id": "0",
                "name": "Electronic"
            },
        ],
        company: {
            name: 'State Engineering Corporation Of Sri Lanka',
            address: null,
            hotLine: null,
            email: null,
            profilePicture: null,
            website: null
        }
    },
    {
        id: "4786732",
        title: "Require 10 nos of Intel i5 10th gen laptops with 8GB RAM",
        qty: '100 units',
        tenderValue: 200000.00,
        description: "In malesuada sit consectetur facilisis praesent. Pulvinar morbi purus proin lobortis viverra habitasse commodo in. Nunc odio senectus massa dignissim consectetur. Scelerisque quis condimentum faucibus nulla cras amet libero et sit. Ac ut hac lorem viverra nunc id consequat in. Erat venenatis proin nulla lorem adipiscing ac nam. Id vestibulum morbi non.",
        publishedDate: "2021/05/31",
        closingDate: "2021/06/12",
        attachment: ".pfd",
        location: DISTRICTS[0],
        source: 'Daily news',
        subCategory: [
            {
                id: 1,
                name: "Computer Hardware & consumables",
            },
            {
                id: 2,
                name: "Interactive Voice Response System",
            },
        ],
        category: [
            {
                "id": "0",
                "name": "Electronic"
            },
        ],
        company: {
            name: 'State Engineering Corporation Of Sri Lanka',
            address: null,
            hotLine: null,
            profilePicture: null,
            email: null,
            website: null
        }
    }
]

export const partners = [
    {
        name: 'State Pharmaceuticals Manufacturing Corporation',
        image: Assets.pt1,
        id: 20
    },
    {
        name: 'National Water Supply and Drainage Board',
        image: Assets.pt2,
        id: 74
    },
    {
        name: 'Milco (Private) Limited',
        image: Assets.pt3,
        id: 162
    },
    {
        name: 'General Sir John Kotelawala Defence University',
        image: Assets.pt4,
        id: 31
    }
]

export const blogs = [
    {
        "title": "Readme. lk - Spiralation 2014",
        "image": Assets.blog1,
        "author": "Readme. lk",
        "authorimage": Assets.readme,
        "date": "",
        "content": "Sit ullamcorper sed eget proin. Felis scelerisque tincidunt sit viverra. Id porta volutpat faucibus sapien lectus ullamcorper. Sed imperdiet mattis sagittis magna pharetra euismod netus neque imperdiet. Tellus posuere non aliquam turpis ornare. Bibendum nulla laoreet sed leo ut justo vitae vitae ut. At mattis volutpat, a adipiscing ipsum egestas tellus. Accumsan, laoreet pellentesque amet, nisl neque ultrices vitae arcu et.\n" +
            "\n" +
            "Faucibus magna donec urna odio sodales accumsan, ac ipsum. Leo amet, sagittis ullamcorper nunc. Proin ac ornare commodo at mauris quisque placerat sem. Quisque diam amet eu porta. Elit auctor in massa interdum. Lorem ac cursus pellentesque placerat nisl cum nisi. Vel id etiam massa sed sed amet mi. Magna egestas eget dictum sodales lacus, commodo. Sem diam eget consectetur turpis habitasse fermentum risus.\n" +
            "\n" +
            "Duis egestas morbi lectus ut. Donec suspendisse phasellus vel amet a nibh lorem. Velit tristique tellus, dolor libero nibh ut bibendum nibh. Odio luctus turpis consectetur dolor morbi lobortis scelerisque aliquam amet. Morbi volutpat, etiam nullam turpis integer ut. Tellus orci id orci, commodo integer netus bibendum lobortis. In diam, odio nisl duis at senectus vel tortor tellus. Ipsum aliquet interdum at elementum. Et, tincidunt nibh amet, eu. Cursus massa amet semper nec ac rhoncus. Eget semper arcu mi tincidunt a, lorem. Pretium non pellentesque massa aliquam, integer. Adipiscing dignissim convallis sed dolor quam facilisis nunc scelerisque amet. Morbi enim in pharetra at. Risus posuere integer massa bibendum tempor lectus vestibulum.\n" +
            "\n" +
            "Elit quam montes, tellus ornare turpis lacus iaculis parturient. Tempor sociis morbi tellus, non in porttitor. Auctor sit in egestas ultrices lectus nulla gravida vehicula malesuada. Nec purus id tellus tortor cras sed est scelerisque. Eleifend et ante facilisis fermentum fusce. Feugiat donec ac fermentum ornare lacus leo. Cursus eget duis lacus laoreet sit faucibus a, amet. Sagittis ultricies convallis massa scelerisque in tellus. Aliquet elit, gravida non at bibendum. Iaculis nunc, convallis nulla maecenas. Turpis at nulla id viverra arcu cras. Est neque, sollicitudin est, ultrices lectus ut diam. In sapien imperdiet enim, ornare feugiat dignissim sollicitudin pulvinar. In etiam et ornare in vulputate cum euismod odio.\n" +
            "\n" +
            "Quis aenean magna sed aliquet eget ullamcorper nunc a. Lorem scelerisque fames consequat posuere. Neque, felis tortor varius aliquet tortor bibendum ipsum. Velit tellus libero, est est, nullam integer. Vivamus tristique pharetra egestas mi turpis. Ultricies cursus mauris sagittis sollicitudin feugiat. Sit lacinia ornare ante velit commodo vitae, diam feugiat. Leo porta congue cursus nisi purus. Ac dolor, ultrices lacinia lectus. Convallis vestibulum, tortor, enim ac massa sit tortor odio. Pellentesque tellus at aliquet enim risus egestas cras neque urna.\n" +
            "\n" +
            "Et, adipiscing ac feugiat praesent viverra. Lobortis amet nisl, amet tristique commodo dignissim. Dolor venenatis aliquet placerat proin amet suscipit odio sociis. Elit et vulputate mi suscipit sagittis imperdiet sagittis. Sit tortor mauris id posuere dui."
    },
    {
        "title": "Sunday Times - INFOTEL 2013",
        "image": Assets.blog2,
        "author": "Sunday Times",
        "authorimage": Assets.sunday,
        "date": "",
        "content": "Sit ullamcorper sed eget proin. Felis scelerisque tincidunt sit viverra. Id porta volutpat faucibus sapien lectus ullamcorper. Sed imperdiet mattis sagittis magna pharetra euismod netus neque imperdiet. Tellus posuere non aliquam turpis ornare. Bibendum nulla laoreet sed leo ut justo vitae vitae ut. At mattis volutpat, a adipiscing ipsum egestas tellus. Accumsan, laoreet pellentesque amet, nisl neque ultrices vitae arcu et.\n" +
            "\n" +
            "Faucibus magna donec urna odio sodales accumsan, ac ipsum. Leo amet, sagittis ullamcorper nunc. Proin ac ornare commodo at mauris quisque placerat sem. Quisque diam amet eu porta. Elit auctor in massa interdum. Lorem ac cursus pellentesque placerat nisl cum nisi. Vel id etiam massa sed sed amet mi. Magna egestas eget dictum sodales lacus, commodo. Sem diam eget consectetur turpis habitasse fermentum risus.\n" +
            "\n" +
            "Duis egestas morbi lectus ut. Donec suspendisse phasellus vel amet a nibh lorem. Velit tristique tellus, dolor libero nibh ut bibendum nibh. Odio luctus turpis consectetur dolor morbi lobortis scelerisque aliquam amet. Morbi volutpat, etiam nullam turpis integer ut. Tellus orci id orci, commodo integer netus bibendum lobortis. In diam, odio nisl duis at senectus vel tortor tellus. Ipsum aliquet interdum at elementum. Et, tincidunt nibh amet, eu. Cursus massa amet semper nec ac rhoncus. Eget semper arcu mi tincidunt a, lorem. Pretium non pellentesque massa aliquam, integer. Adipiscing dignissim convallis sed dolor quam facilisis nunc scelerisque amet. Morbi enim in pharetra at. Risus posuere integer massa bibendum tempor lectus vestibulum.\n" +
            "\n" +
            "Elit quam montes, tellus ornare turpis lacus iaculis parturient. Tempor sociis morbi tellus, non in porttitor. Auctor sit in egestas ultrices lectus nulla gravida vehicula malesuada. Nec purus id tellus tortor cras sed est scelerisque. Eleifend et ante facilisis fermentum fusce. Feugiat donec ac fermentum ornare lacus leo. Cursus eget duis lacus laoreet sit faucibus a, amet. Sagittis ultricies convallis massa scelerisque in tellus. Aliquet elit, gravida non at bibendum. Iaculis nunc, convallis nulla maecenas. Turpis at nulla id viverra arcu cras. Est neque, sollicitudin est, ultrices lectus ut diam. In sapien imperdiet enim, ornare feugiat dignissim sollicitudin pulvinar. In etiam et ornare in vulputate cum euismod odio.\n" +
            "\n" +
            "Quis aenean magna sed aliquet eget ullamcorper nunc a. Lorem scelerisque fames consequat posuere. Neque, felis tortor varius aliquet tortor bibendum ipsum. Velit tellus libero, est est, nullam integer. Vivamus tristique pharetra egestas mi turpis. Ultricies cursus mauris sagittis sollicitudin feugiat. Sit lacinia ornare ante velit commodo vitae, diam feugiat. Leo porta congue cursus nisi purus. Ac dolor, ultrices lacinia lectus. Convallis vestibulum, tortor, enim ac massa sit tortor odio. Pellentesque tellus at aliquet enim risus egestas cras neque urna.\n" +
            "\n" +
            "Et, adipiscing ac feugiat praesent viverra. Lobortis amet nisl, amet tristique commodo dignissim. Dolor venenatis aliquet placerat proin amet suscipit odio sociis. Elit et vulputate mi suscipit sagittis imperdiet sagittis. Sit tortor mauris id posuere dui."
    },
    {
        "title": "DailyMirror - B2b.lk ",
        "image": Assets.blog3,
        "author": "DailyMirror",
        "authorimage": Assets.dailyMirror,
        "date": "",
        "content": "Sit ullamcorper sed eget proin. Felis scelerisque tincidunt sit viverra. Id porta volutpat faucibus sapien lectus ullamcorper. Sed imperdiet mattis sagittis magna pharetra euismod netus neque imperdiet. Tellus posuere non aliquam turpis ornare. Bibendum nulla laoreet sed leo ut justo vitae vitae ut. At mattis volutpat, a adipiscing ipsum egestas tellus. Accumsan, laoreet pellentesque amet, nisl neque ultrices vitae arcu et.\n" +
            "\n" +
            "Faucibus magna donec urna odio sodales accumsan, ac ipsum. Leo amet, sagittis ullamcorper nunc. Proin ac ornare commodo at mauris quisque placerat sem. Quisque diam amet eu porta. Elit auctor in massa interdum. Lorem ac cursus pellentesque placerat nisl cum nisi. Vel id etiam massa sed sed amet mi. Magna egestas eget dictum sodales lacus, commodo. Sem diam eget consectetur turpis habitasse fermentum risus.\n" +
            "\n" +
            "Duis egestas morbi lectus ut. Donec suspendisse phasellus vel amet a nibh lorem. Velit tristique tellus, dolor libero nibh ut bibendum nibh. Odio luctus turpis consectetur dolor morbi lobortis scelerisque aliquam amet. Morbi volutpat, etiam nullam turpis integer ut. Tellus orci id orci, commodo integer netus bibendum lobortis. In diam, odio nisl duis at senectus vel tortor tellus. Ipsum aliquet interdum at elementum. Et, tincidunt nibh amet, eu. Cursus massa amet semper nec ac rhoncus. Eget semper arcu mi tincidunt a, lorem. Pretium non pellentesque massa aliquam, integer. Adipiscing dignissim convallis sed dolor quam facilisis nunc scelerisque amet. Morbi enim in pharetra at. Risus posuere integer massa bibendum tempor lectus vestibulum.\n" +
            "\n" +
            "Elit quam montes, tellus ornare turpis lacus iaculis parturient. Tempor sociis morbi tellus, non in porttitor. Auctor sit in egestas ultrices lectus nulla gravida vehicula malesuada. Nec purus id tellus tortor cras sed est scelerisque. Eleifend et ante facilisis fermentum fusce. Feugiat donec ac fermentum ornare lacus leo. Cursus eget duis lacus laoreet sit faucibus a, amet. Sagittis ultricies convallis massa scelerisque in tellus. Aliquet elit, gravida non at bibendum. Iaculis nunc, convallis nulla maecenas. Turpis at nulla id viverra arcu cras. Est neque, sollicitudin est, ultrices lectus ut diam. In sapien imperdiet enim, ornare feugiat dignissim sollicitudin pulvinar. In etiam et ornare in vulputate cum euismod odio.\n" +
            "\n" +
            "Quis aenean magna sed aliquet eget ullamcorper nunc a. Lorem scelerisque fames consequat posuere. Neque, felis tortor varius aliquet tortor bibendum ipsum. Velit tellus libero, est est, nullam integer. Vivamus tristique pharetra egestas mi turpis. Ultricies cursus mauris sagittis sollicitudin feugiat. Sit lacinia ornare ante velit commodo vitae, diam feugiat. Leo porta congue cursus nisi purus. Ac dolor, ultrices lacinia lectus. Convallis vestibulum, tortor, enim ac massa sit tortor odio. Pellentesque tellus at aliquet enim risus egestas cras neque urna.\n" +
            "\n" +
            "Et, adipiscing ac feugiat praesent viverra. Lobortis amet nisl, amet tristique commodo dignissim. Dolor venenatis aliquet placerat proin amet suscipit odio sociis. Elit et vulputate mi suscipit sagittis imperdiet sagittis. Sit tortor mauris id posuere dui."
    },
]

export const contactDetails = {
    landNumber: '+94 11 432 6791',
    mobileNumber: '+94 77 719 6703'
}
