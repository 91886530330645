import React, { useState } from "react";
import strings from "../../locals/string.json";
import constants, { DISTRICTS } from "../../constants/constants";
import apiConfig from "../../service/apiConfig";
import { Select } from "antd";
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import {
    EMAIL_REGEX,
    MOBILE_REGEX,
    Name_REGEX,
    PASSWORD_REGEX,
    WEB_SITE_REGEX
} from "../../constants/validation";
import { isPossiblePhoneNumber } from 'react-phone-number-input';
import './registration.scss'


const registration = strings.registration;
const profile = strings.profile;
const { Option } = Select
const toast = strings.toast;

export default (props) => {
    const [view, setView] = useState(false);

    const validPw = (props.state.confirmPassword !== '' && (props.state.confirmPassword !== props.state.password));
    return <form className="contact-form-wrap contact-form-bg" onSubmit={event => event.preventDefault()}>
        <h6>{registration.personalInformation}</h6>
        <div className="row">
            <div className="col-md-6 col-sm-12 ">
                <p className="inputLabel">{registration.firstName}<span>*</span></p>
                <div className="rld-single-input">
                    <input type="text" name="firstName" required="required" onChange={props.onTextChange}
                        value={props.state.firstName}
                        placeholder="First name"
                        className={(props.state.firstName.trim() === '' || !Name_REGEX.test(props.state.firstName)) && props.state.isValidation ? 'input-error' : ''}
                    />
                    {
                        (props.state.firstName.trim() === '' || !Name_REGEX.test(props.state.firstName)) && props.state.isValidation ?
                            <span
                                className={'fields-error-text'}>{toast.invalidName}</span>
                            : null
                    }
                </div>
            </div>
            <div className="col-md-6 col-sm-12 ">
                <p className="inputLabel">{registration.lastName}<span>*</span></p>
                <div className="rld-single-input">
                    <input type="text" name="lastName" required="required" onChange={props.onTextChange}
                        value={props.state.lastName} placeholder="Last name"
                        className={(props.state.lastName.trim() === '' || !Name_REGEX.test(props.state.lastName)) && props.state.isValidation ? 'input-error' : ''}
                    />
                    {
                        (props.state.lastName.trim() === '' || !Name_REGEX.test(props.state.lastName)) && props.state.isValidation ?
                            <span
                                className={'fields-error-text'}>{toast.invalidLName}</span>
                            : null
                    }
                </div>
            </div>
        </div>

        <div className="row">
            <div className="col-md-6 col-sm-12 ">
                <p className="inputLabel">{registration.designation}<span>*</span></p>
                <div className="rld-single-input">
                    <input type="text" name="designation" placeholder={registration.designation}
                        onChange={props.onTextChange}
                        value={props.state.designation}
                        className={(props.state.designation.trim() === '') && props.state.isValidation ? 'input-error' : ''}
                    />
                    {
                        (props.state.designation.trim() === '') && props.state.isValidation ?
                            <span
                                className={'fields-error-text'}>{toast.invalidDName}</span>
                            : null
                    }
                </div>
            </div>
            <div className="col-md-6 col-sm-12 ">
                <p className="inputLabel">{registration.email}<span>*</span></p>
                <div className="rld-single-input">
                    <input type="text" name="email" required="required" onChange={props.onTextChange}
                        value={props.state.email} placeholder="Email address"
                        className={(props.state.email.trim() === '' || !EMAIL_REGEX.test(props.state.email)) && props.state.isValidation ? 'input-error' : ''}
                    />
                    {
                        (props.state.email.trim() === '' || !EMAIL_REGEX.test(props.state.email)) && props.state.isValidation ?
                            <span
                                className={'fields-error-text'}>{toast.invalidEmail}</span>
                            : null
                    }
                </div>
            </div>
        </div>


        {/*{*/}
        {/*    props.state.userType === constants.USER_SELLER &&*/}
        {/*        <>*/}
        {/*            <p className="inputLabel">{registration.secondaryEmail}</p>*/}
        {/*            <SecondaryEmail onChangeSecondaryEmail={props.onChangeSecondaryEmail}*/}
        {/*                            emailCount={props.state.packages[props.state.subscription_plan].emailCount ?? 2}/>*/}
        {/*        </>*/}
        {/*}*/}

        <p className="inputLabel ">{registration.mobile}<span>*</span></p>
        <PhoneInput
            international
            countryCallingCodeEditable={false}
            defaultCountry="LK"
            placeholder="Mobile number"
            value={props.state.mobile}
            onChange={value => props.onTextChange({ target: { name: 'mobile', value: value ?? '' } })}
            className={(props.state.mobile.trim() === '' || !isPossiblePhoneNumber(props.state.mobile)) && props.state.isValidation ? 'input-error' : ''}
        />
        {
            (props.state.mobile.trim() === '' || !isPossiblePhoneNumber(props.state.mobile)) && props.state.isValidation ?
                <span
                    className={'fields-error-text'}>{toast.invalidMobile}</span>
                : null
        }

        <h6 className="mt-5">{registration.password} <i
            onClick={() => setView(!view)}
            className={"fa pointer " + (view ? 'fa-eye' : 'fa-eye-slash')} /></h6>

        <div className="row">
            <div className="col-md-6 col-sm-12 ">
                <p className="inputLabel">{registration.password}<span>*</span></p>
                <div className="rld-single-input">
                    <input type={view ? 'text' : "password"} name="password" required="required"
                        onChange={props.onTextChange}
                        autoComplete={false}
                        value={props.state.password} placeholder={registration.password}
                        className={(props.state.password.trim() === '' || !PASSWORD_REGEX.test(props.state.password)) && props.state.isValidation ? 'input-error' : ''}
                    />
                    {
                        (props.state.password.trim() === '' || !PASSWORD_REGEX.test(props.state.password)) && props.state.isValidation ?
                            <span
                                className={'fields-error-text'}>{toast.emptyPassword}</span>
                            : <span className={'fields-info-text'}>{`Note: ${toast.emptyPassword}`}</span>
                    }
                </div>
            </div>
            <div className="col-md-6 col-sm-12 ">
                <p className="inputLabel">{registration.confirmPassword}<span>*</span></p>
                <div className="rld-single-input">
                    <input type={view ? 'text' : "password"}
                        name="confirmPassword" required="required" onChange={props.onTextChange}
                        value={props.state.confirmPassword}
                        style={{ border: validPw && '1px solid red' }} placeholder="Confirm password" />
                </div>
                {
                    validPw && <span className="text-danger">*Password not matched!</span>
                }
            </div>
        </div>

        <h6 className="mt-4">{registration.companyInformation}</h6>

        {/*<div className="row">*/}
        {/*<div className="col-md-5 col-12">*/}
        {/*    <p className="inputLabel">{registration.companyImage}*/}
        {/*        {props.state.userType === constants.USER_SELLER && <span>*</span>}</p>*/}
        {/*    <div className="display-flex flex-row align-items-center mt-3">*/}
        {/*        <img className="companyImage mr-3" src={props.state.image !== null ? props.state.image : Assets.companyLogo}*/}
        {/*             alt='image'/>*/}
        {/*        <Files*/}
        {/*            className='files-dropzone-file'*/}
        {/*            onChange={props.onFilesChange1}*/}
        {/*            accepts={["image/png", "image/jpg", "image/jpeg"]}*/}
        {/*            multiple={false}*/}
        {/*            maxFileSize={10485760}*/}
        {/*            minFileSize={0}*/}
        {/*            onError={error => console.log(error)}*/}
        {/*            clickable*/}
        {/*        >*/}
        {/*            <p className="choose2 m-0">{pr.chooseCI}</p>*/}
        {/*        </Files>*/}
        {/*        {*/}
        {/*            props.state.image !== null &&*/}
        {/*            <p className="remove m-0" onClick={()=>props.removeImage('PROFILE')}>Remove</p>*/}
        {/*        }*/}
        {/*    </div>*/}
        {/*</div>*/}

        {/*{*/}
        {/*    props.state.userType === constants.USER_SELLER &&*/}
        {/*    <div className="col-md-7 col-12">*/}
        {/*        <p className="inputLabel">{registration.coverImage}<span>*</span></p>*/}
        {/*        <div className="display-flex flex-row align-items-center mt-3">*/}
        {/*            <img className="companyCoverImage mr-3" src={props.state.coverImage !== null ? props.state.coverImage : Assets.companyLogo}*/}
        {/*                 alt='image'/>*/}
        {/*            <Files*/}
        {/*                className='files-dropzone-file'*/}
        {/*                onChange={props.onFilesChange2}*/}
        {/*                accepts={["image/png", "image/jpg", "image/jpeg"]}*/}
        {/*                multiple={false}*/}
        {/*                maxFileSize={10485760}*/}
        {/*                minFileSize={0}*/}
        {/*                onError={error => console.log(error)}*/}
        {/*                clickable*/}
        {/*            >*/}
        {/*                <p className="choose2 m-0">{pr.chooseCI}</p>*/}
        {/*            </Files>*/}
        {/*            {*/}
        {/*                props.state.coverImage !== null &&*/}
        {/*                <p className="remove m-0" onClick={()=>props.removeImage('COVER')}>Remove</p>*/}
        {/*            }*/}
        {/*        </div>*/}
        {/*    </div>*/}
        {/*}*/}

        {/*</div>*/}

        <p className="inputLabel">{registration.companyName}<span>*</span></p>
        <div className="rld-single-input">
            <input type="text" name="companyName" required="required" onChange={props.onTextChange}
                value={props.state.companyName} placeholder="Company name"
                // className={(props.state.companyName.trim() === '' || !Name_REGEX.test(props.state.companyName)) && props.state.isValidation ? 'input-error' : ''}
                className={(props.state.companyName.trim() === '') && props.state.isValidation ? 'input-error' : ''}
            />
            {
                (props.state.companyName.trim() === '') && props.state.isValidation ?
                    <span
                        className={'fields-error-text'}>{toast.invalidCName}</span>
                    : null
            }
        </div>

        {
            props.state.userType === constants.USER_SELLER &&
            <>
                {/*<p className="inputLabel">{registration.companyUrl}<span>*</span></p>*/}
                {/*<div className="rld-single-input">*/}
                {/*    <input type="text" name="companyUrl" placeholder={registration.companyUrl}*/}
                {/*           onChange={props.onTextChange}*/}
                {/*           value={props.state.companyUrl}/>*/}
                {/*</div>*/}

                {/*{*/}
                {/*    props.state.companyUrl.trim() !== '' &&*/}
                {/*    <h6 className=" mt-2 mb-3 url">{apiConfig.frontEnd + props.state.companyUrl}</h6>*/}
                {/*}*/}
            </>
        }

        <div className="row">
            <div className="col-md-6 col-sm-12 ">
                <p className="inputLabel ">{profile.hotline}</p>
                <PhoneInput
                    international
                    countryCallingCodeEditable={false}
                    defaultCountry="LK"
                    placeholder="Hot line"
                    value={props.state.hotline}
                    onChange={value => props.onTextChange({ target: { name: 'hotline', value: value ?? '' } })}
                    className={(props.state.hotline.trim() !== '' && !isPossiblePhoneNumber(props.state.hotline)) && props.state.isValidation ? 'input-error' : ''}
                />
                {
                    (props.state.hotline.trim() !== '' && !isPossiblePhoneNumber(props.state.hotline)) && props.state.isValidation ?
                        <span
                            className={'fields-error-text'}>{toast.invalidMobile}</span>
                        : null
                }
            </div>
            <div className="col-md-6 col-sm-12 ">
                <p className="inputLabel ">{registration.land}</p>
                <PhoneInput
                    international
                    countryCallingCodeEditable={false}
                    defaultCountry="LK"
                    placeholder="Land line"
                    value={props.state.land}
                    onChange={value => props.onTextChange({ target: { name: 'land', value: value ?? '' } })}
                    className={props.state.land.trim() !== '' && !isPossiblePhoneNumber(props.state.land) && props.state.isValidation ? 'input-error' : ''}
                />
                {
                    (props.state.land.trim() !== '' && !isPossiblePhoneNumber(props.state.land)) && props.state.isValidation ?
                        <span
                            className={'fields-error-text'}>{toast.invalidMobile}</span>
                        : null
                }
            </div>
        </div>

        {
            props.state.userType === constants.USER_SELLER &&
            <div className="row">
                <div className="col-md-6 col-sm-12 ">
                    <p className="inputLabel">{registration.address}<span>*</span></p>
                    <div className="rld-single-input">
                        <input type="text" name="address" placeholder="Address" onChange={props.onTextChange}
                            value={props.state.address}
                            className={props.state.address.trim() === '' && props.state.isValidation ? 'input-error' : ''}
                        />
                        {
                            props.state.address.trim() === '' && props.state.isValidation ?
                                <span
                                    className={'fields-error-text'}>{toast.address}</span>
                                : null
                        }
                    </div>
                </div>
                <div className="col-md-6 col-sm-12 ">
                    <p className="inputLabel">{registration.district}<span>*</span></p>
                    <Select
                        className="mb-2"
                        showSearch
                        value={props.state.location}
                        className={props.state.location === null && props.state.isValidation ? 'input-error' : ''}
                        placeholder={registration.district}
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        filterSort={(optionA, optionB) =>
                            optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                        }
                        onChange={value => props.onTextChange({ target: { name: 'location', value: value } })}
                    >
                        {
                            DISTRICTS.map(item => <Option value={item}>{item}</Option>)
                        }
                    </Select>

                    {
                        props.state.location === null && props.state.isValidation ?
                            <span
                                className={'fields-error-text'}>{toast.district}</span>
                            : null
                    }
                </div>
            </div>
        }

        <p className="inputLabel mt-2">{registration.website}</p>
        <div className="rld-single-input">
            <input type="text" name="website" placeholder="Web site" onChange={props.onTextChange}
                value={props.state.website}
                className={(props.state.website.trim() !== '' && !WEB_SITE_REGEX.test(props.state.website)) && props.state.isValidation ? 'input-error' : ''}
            />
            {
                (props.state.website.trim() !== '' && !WEB_SITE_REGEX.test(props.state.website)) && props.state.isValidation ?
                    <span
                        className={'fields-error-text'}>{toast.invalidWeb}</span>
                    : null
            }
        </div>

        {
            props.state.userType === constants.USER_SELLER &&
            <>
                {/*<h6 className="mt-4">{registration.billingProf}</h6>*/}

                {/*<Files*/}
                {/*    className='files-dropzone-file'*/}
                {/*    onChange={props.onFileChange}*/}
                {/*    accepts={["image/png", "image/jpg", "image/jpeg", "application/pdf"]}*/}
                {/*    multiple={false}*/}
                {/*    maxFileSize={10485760}*/}
                {/*    minFileSize={0}*/}
                {/*    onError={error => console.log(error)}*/}
                {/*    clickable*/}
                {/*>*/}
                {/*    <div className="file-wrapper">*/}
                {/*        <p className="choose">{registration.chooseFile}</p>*/}
                {/*        {*/}
                {/*            props.state.billingProof &&*/}
                {/*            <p className="view-file" onClick={props.onViewFile}>{registration.viewFile}</p>*/}
                {/*        }*/}
                {/*    </div>*/}
                {/*</Files>*/}

            </>
        }
    </form>
}
