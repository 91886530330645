import React from 'react';
import Navbar from '../components/globalComponents/navbar';
import Banner from '../components/banner/subBanner';
import Footer from "../components/globalComponents/footer";
import {validateContact} from "../constants/validation";
import {contactus} from "../service/auth";
import {ToastUtil} from "../constants/toastUtil";
import constants from "../constants/constants";
import {loadReCaptcha, ReCaptcha} from "react-recaptcha-v3";
import {contactDetails} from "../constants/constants";

class Contact extends React.Component {

    state = {
        name: '',
        email: '',
        mobile: '',
        description: ''
    }

    componentWillMount() {
        loadReCaptcha(constants.reCaptureSiteKey, () => {
        });
    }

    contactUs = async () => {
        if (validateContact(this.state)) {
            await this.recaptcha.execute();
            this.state.recaptchaToken !== '' &&
            contactus(this.state)
                .then(response => {
                    if (response.success) {
                        this.setState({name: '', email: '', mobile: '', description: ''})
                        ToastUtil.showSuccessToast(response.msg)
                    } else {
                        ToastUtil.showErrorToast(response.msg)
                    }
                })
        }
    }

    verifyCallback = async (recaptchaToken) => {
        await this.setState({recaptchaToken})
    }

    render() {
        return <div>
            <ReCaptcha
                ref={ref => this.recaptcha = ref}
                sitekey={constants.reCaptureSiteKey}
                action='submit'
                verifyCallback={this.verifyCallback}
            />
            <Navbar/>
            <Banner headertitle="Contact"/>
            <div className="contact-area pd-top-60 pd-bottom-65">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <form className="contact-form-wrap contact-form-bg">
                                <h4>Contact Now</h4>
                                <div className="rld-single-input">
                                    <input type="text" placeholder="Name" value={this.state.name}
                                           onChange={e => this.setState({name: e.target.value})}/>
                                </div>
                                <div className="rld-single-input">
                                    <input type="email" placeholder="Email" value={this.state.email}
                                           onChange={e => this.setState({email: e.target.value})}/>
                                </div>
                                <div className="rld-single-input">
                                    <input type="number" placeholder="Phone" value={this.state.mobile}
                                           onChange={e => this.setState({mobile: e.target.value})}/>
                                </div>
                                <div className="rld-single-input">
                                    <textarea rows={5} placeholder="Message" value={this.state.description}
                                              onChange={e => this.setState({description: e.target.value})}/>
                                </div>
                                <div className="btn-wrap text-center">
                                    <button className="btn btn-yellow" type="button" onClick={this.contactUs}>Submit
                                    </button>
                                </div>
                            </form>
                        </div>
                        <div className="col-md-6 mt-5 display-flex flex-column justify-content-center">
                            <div className="p-3 display-flex flex-column justify-content-center">
                                <div className="single-contact-info">
                                    <p className="mb-1"><i className="fa fa-map-marker"/>Address</p>
                                    <h6 style={{maxWidth: 400}}>eTenders.lk, 2<sup>nd</sup> Floor McLaren's Building, No
                                        123, Bauddhaloka
                                        Mawatha, Colombo 4.</h6>
                                </div>
                                <div className="single-contact-info">
                                    <p className="mb-1"><i className="fa fa-fax"/>Telephone</p>
                                    <h6>{contactDetails.landNumber}</h6>
                                </div>
                                <div className="single-contact-info">
                                    <p className="mb-1"><i className="fa fa-phone"/>Mobile</p>
                                    <h6>{contactDetails.mobileNumber}</h6>
                                </div>

                                <div className="single-contact-info">
                                    <p className="mb-1"><i className="fa fa-envelope"/>Email</p>
                                    <h6>info@etenders.lk</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                    <h4 className="mt-3 mb-2">Management Team</h4>
                    <div className="row">
                        <div className="col-md-4 display-flex flex-column justify-content-center">
                            <div className="p-3 display-flex flex-column justify-content-center">
                                <div className="single-contact-info mb-2">
                                    <h5 className="mb-1"><b>Rehan J. Grero (MBA- UK)</b></h5>
                                    <p>Chief Executive Officer</p>
                                </div>
                                <div className="single-contact-info mb-2">
                                    <p className="mb-1"><i className="fa fa-fax"/>Telephone</p>
                                    <h6>{contactDetails.landNumber}</h6>
                                </div>
                                <div className="single-contact-info mb-2">
                                    <p className="mb-1"><i className="fa fa-phone"/>Mobile</p>
                                    <h6>+94 777 063 173</h6>
                                </div>

                                <div className="single-contact-info mb-2">
                                    <p className="mb-1"><i className="fa fa-envelope"/>Email</p>
                                    <h6>rgrero@etenders.lk</h6>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 display-flex flex-column justify-content-center">
                            <div className="p-3 display-flex flex-column justify-content-center">
                                <div className="single-contact-info mb-2">
                                    <h5 className="mb-1"><b>Angelo J Sugunasekera</b></h5>
                                    <p>Founder / Director Strategy</p>
                                </div>
                                <div className="single-contact-info mb-2">
                                    <p className="mb-1"><i className="fa fa-fax"/>Telephone</p>
                                    <h6>{contactDetails.landNumber}</h6>
                                </div>
                                <div className="single-contact-info mb-2">
                                    <p className="mb-1"><i className="fa fa-phone"/>Mobile</p>
                                    <h6>+94 777 766 415</h6>
                                </div>

                                <div className="single-contact-info mb-2">
                                    <p className="mb-1"><i className="fa fa-envelope"/>Email</p>
                                    <h6>angelo@etenders.lk</h6>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 display-flex flex-column justify-content-center">
                            <div className="p-3 display-flex flex-column justify-content-center">
                                <div className="single-contact-info mb-2">
                                    <h5 className="mb-1"><b>Damian Perrin Vanniasinkam</b></h5>
                                    <p>Chief Technology Officer</p>
                                </div>
                                <div className="single-contact-info mb-2">
                                    <p className="mb-1"><i className="fa fa-fax"/>Telephone</p>
                                    <h6>{contactDetails.landNumber}</h6>
                                </div>
                                <div className="single-contact-info mb-2">
                                    <p className="mb-1"><i className="fa fa-phone"/>Mobile</p>
                                    <h6>+94 773 336 989</h6>
                                </div>

                                <div className="single-contact-info mb-2">
                                    <p className="mb-1"><i className="fa fa-envelope"/>Email</p>
                                    <h6>perrin@etenders.lk</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                    <h4 className="mt-3 mb-2">Registration</h4>
                    <div className="row">
                        <div className="col-md-4 display-flex flex-column justify-content-center">
                            <div className="p-3 display-flex flex-column justify-content-center">
                                <div className="single-contact-info mb-2">
                                    <h5 className="mb-1"><b>Rehan Grero</b></h5>
                                    {/*<p>Account Manager</p>*/}
                                </div>
                                <div className="single-contact-info mb-2">
                                    <p className="mb-1"><i className="fa fa-fax"/>Telephone</p>
                                    <h6>{contactDetails.landNumber}</h6>
                                </div>
                                <div className="single-contact-info mb-2">
                                    <p className="mb-1"><i className="fa fa-phone"/>Mobile</p>
                                    <h6>{contactDetails.mobileNumber}</h6>
                                </div>

                                <div className="single-contact-info mb-2">
                                    <p className="mb-1"><i className="fa fa-envelope"/>Email</p>
                                    <h6>rgrero@etenders.lk</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    }
}

export default Contact

