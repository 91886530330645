import React from "react";
import './statistics.scss';
import strings from "../../locals/string.json";
import {getStatistics} from "../../service/auth";
import {ToastUtil} from "../../constants/toastUtil";

const landing = strings.landing;

class Statistics extends React.Component {

    state = {
        tenders: 0,
        live: 0,
        departments: 0,
        suppliers: 0,
    }

    componentWillMount() {
        getStatistics()
            .then(response => {
                if (response.success) {
                    const obj = document.getElementById("requestCount");
                    this.animateValue(obj, 0, response.body.tenders ?? 0, 2500, 'tenders');
                    this.animateValue(obj, 0, response.body.live ?? 0, 2500, 'live');
                    this.animateValue(obj, 0, response.body.departments ?? 0, 2500, 'departments');
                    this.animateValue(obj, 0, response.body.suppliers ?? 0, 2500, 'suppliers');
                } else {
                    ToastUtil.showErrorToast(response.msg);
                }
            })
    }

    animateValue = (obj, start, end, duration, name) => {
        let startTimestamp = null;
        const step = (timestamp) => {
            if (!startTimestamp) startTimestamp = timestamp;
            const progress = Math.min((timestamp - startTimestamp) / duration, 1);
            this.setState({[name]: Math.floor(progress * (end - start) + start)})
            if (progress < 1) {
                window.requestAnimationFrame(step);
            }
        };
        window.requestAnimationFrame(step);
    }

    render() {
        return <div className="statistics">
            <div className="container">
                <div className="row">
                    <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
                        <div className="staticTile">
                            <p className="count" id="requestCount">{this.state.tenders}</p>
                            <p className="title">{this.state.tenders > 1 ? "All Tenders" : "All Tender"}</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
                        <div className="staticTile">
                            <p className="count">{this.state.live}</p>
                            <p className="title">{this.state.live > 1 ? "Live Tenders" : "Live Tender"}</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
                        <div className="staticTile">
                            <p className="count">{this.state.departments}</p>
                            <p className="title">{this.state.departments > 1 ? "Departments" : "Department"}</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
                        <div className="staticTile">
                            <p className="count">{this.state.suppliers}</p>
                            <p className="title">{this.state.suppliers > 1 ? "Suppliers" : "Supplier"}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    }
}

export default Statistics;
