import React, { Component } from 'react';
import './oauth.scss'
import 'antd/dist/antd.css';
import strings from "../../locals/string.json";
import routes from "../../constants/routes";
import constants, { __DEV__, categoryActions } from "../../constants/constants";
import { Steps } from "antd";
import { getCategories } from "../../service/category";
import {
    SEARCH_SPACE_REGEXP,
    SEARCH_VALUE_REGEXP,
    validateBuyerRegDetails,
    validateCategory, validateLoginDetails,
    validatePromoCode
} from "../../constants/validation";
import * as commonFunc from "../../constants/commonFunc";
import { createUser, createUserRegDetails, getTimeDifferenceWithUTC } from "../../constants/commonFunc";
import { ToastUtil } from "../../constants/toastUtil";
import {
    checkEmailAvailability,
    checkPromoCode, getB2bDiscount,
    getSubscriptions, loginUser,
    makePayment,
    register,
    saveTempUser
} from "../../service/auth";
import { Assets } from "../../assets";
import { isBrowser } from "react-device-detect";
import StepOne from "./registrationStep01";
import StepTwo from "./registrationStep2";
import StepThree from "./registrationStep03";
import Payment from "./payment";
import { handleErrors, WebXPayTokenizeInit } from "../../constants/payment";
import getCroppedImg, { dataURLtoFile } from "../../constants/cropImage";
import Cropper from "react-easy-crop";
import $ from 'jquery'
import * as userActions from "../../store/domain/user/action";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import Cookies from "js-cookie";
import qs from "qs";
import swal from "sweetalert";

const login = strings.login;
const registration = strings.registration;
const { Step } = Steps;

class Register extends Component {

    state = {
        step: 1,
        firstName: __DEV__ ? 'Udara ' : '',
        lastName: __DEV__ ? 'Prabath' : '',
        email: __DEV__ ? 'udarap@ceyentra.com' : '',
        password: __DEV__ ? '12345678' : '',
        confirmPassword: __DEV__ ? '12345678' : '',
        companyName: __DEV__ ? 'Ceyentra' : '',
        companyUrl: __DEV__ ? 'ceyentra12' : '',
        designation: __DEV__ ? 'Software Engineer' : '',
        website: __DEV__ ? 'ceyentra.com' : '',
        address: __DEV__ ? 'Panadura' : '',
        billingProof: undefined,
        location: null,
        billingProofType: undefined,
        billingProofFile: undefined,
        category: [],
        subCategory: [],
        subscription_plan: 0,
        categories: [],
        packages: [],
        cat1: undefined,
        cat2: undefined,
        cat3: undefined,
        nameOnTHeCard: '',
        cardNumber: '',
        expDate: '',
        cvv: '',
        promo: '',
        applyPromo: false,
        viewBreakDown: true,
        secondaryEmail: '',
        mobile: '',
        hotline: '',
        land: '',
        secondaryEmails: [],
        catIdArray: [],

        imageType: undefined,
        imageFile: undefined,
        image: null,
        coverImageFile: undefined,
        coverImageType: undefined,
        coverImage: null,
        crop: { x: 100, y: 100 },
        zoom: 1,
        open: false,
        isProfile: true,
        percentage: 0,
        maximumAmount: 0,
        etUserDiscount: 0,
        isValidation: false,
        packageId: 0,
        promoCodeId: 0,
        suitableForDiscount: false,
        diff: ''
    }

    componentWillMount() {
        this.setGMT()
        this.getSubscriptionPackages();
        localStorage.setItem(constants.REDIRECT_TO_HOME, "true")
    }

    setGMT = async () => {
        this.setState({
            diff: await getTimeDifferenceWithUTC()
        })
    }

    getB2bDiscount = () => {
        getB2bDiscount(this.state.email).then((res) => {
            if (res.success) {
                let price = this.state.packages[this.state.subscription_plan].price
                let discount = (price * res.body.discount) / 100

                this.setState({
                    ...this.state,
                    etUserDiscount: res.body.suitable ? discount : 0,
                    suitableForDiscount: res.body.suitable
                })
            }
        })
    }

    getCategories = () => {
        getCategories(categoryActions.GET_ALL)
            .then(async response => {
                if (response.success) {
                    let dummyArray = []
                    response.body.map(i => {
                        if (i.id !== 1) {
                            dummyArray.push(i)
                        }
                    })
                    const cat = dummyArray.map(item => {
                        return {
                            ...item,
                            subCategory: item.subCategory.map(sub => {
                                return {
                                    ...sub,
                                    checked: false
                                }
                            })
                        }
                    });
                    const catLength = this.state.packages[this.state.subscription_plan].categoryCount;
                    const catIdArray = []
                    for (let i = 0; i < catLength; i++) {
                        await this.setState({
                            ['cat' + (i + 1)]: undefined
                        })
                        cat[i] && catIdArray.push(-1)
                    }
                    await this.setState({
                        categories: cat,
                        catIdArray: catIdArray,
                        step: 3
                    })
                }
            })
    }

    onTextChange = async (event) => {
        if (event.target.name === 'companyName') {
            await this.setState({
                [event.target.name]: event.target.value,
                companyUrl: event.target.value.toLowerCase().trim()
                    .replace(SEARCH_VALUE_REGEXP, '  ')
                    .replace(SEARCH_SPACE_REGEXP, '-').trim()
            });
        } else if (event.target.name === 'companyUrl') {
            await this.setState({
                companyUrl: event.target.value.toLowerCase().trim()
                    .replace(SEARCH_VALUE_REGEXP, '  ')
                    .replace(SEARCH_SPACE_REGEXP, '-').trim()
            });
        } else if (event.target.name === 'promo') {
            if (event.target.value === '') {
                await this.setState({
                    [event.target.name]: event.target.value,
                    percentage: 0,
                    maximumAmount: 0,
                })
            } else {
                await this.setState({
                    [event.target.name]: event.target.value,
                })
            }
        } else {
            await this.setState({
                [event.target.name]: event.target.value
            })
        }
    }

    onCardDetailsChange = async (name, value) => {
        await this.setState({
            [name]: value
        })
    }

    onFilesChange = async files => {
        if (files.length !== 0) {
            let file = files[0];
            const imageDataUrl = await commonFunc.readFile(file);
            this.setState({
                billingProofType: file.type,
                billingProofFile: file,
                billingProof: imageDataUrl
            });
        }
    };

    onSubCategoryChange = (category, item) => {
        const result = commonFunc.onSubCategoryChange(category, item, this.state.subCategory, this.state.category)
        this.setState({ subCategory: result.data, category: result.cat })
    }

    onSelectAllSubCategory = (category, status) => {
        const data = this.state.subCategory;
        const cat = this.state.category;
        category.subCategory.map((item, index) => {
            if ((data.filter(e => e.id === item.id).length > 0)) {
                data.splice(data.findIndex(e => e.id === item.id), 1);
            }
            if (status) {
                data.push(item);
            }
        })
        if (!(cat.filter(e => e.id === category.id).length > 0)) {
            cat.push(category);
        } else {
            if (!status) {
                cat.splice(cat.findIndex(e => e.id === category.id), 1);
            }
        }
        this.setState({ subCategory: data, category: cat })
    }

    setCategory = async (index, value) => {
        const category = { ...this.state.categories[value] }
        await this.setState({
            ["cat" + index]: category
        })
    }

    onCategoryChange = (index, value) => {
        const category = { ...this.state.categories[value] }
        const result = commonFunc.onCategoryChange(category, this.state.subCategory, this.state.category)
        const catIdArray = [...this.state.catIdArray];
        catIdArray[index] = this.state.categories[value].id;

        this.setState({
            subCategory: result.data, category: result.cat,
            ["cat" + (index + 1)]: this.state.categories[value],
            catIdArray: catIdArray
        })
    }

    handleSubCategoryChange = (index, status, id) => {
        let data = { ...this.state['cat' + index] };
        const result = commonFunc.handleSubCategoryChange(index, status, id, data)
        this.setState({ ['cat' + index]: result })
    }

    onAdd = () => {
        let data = this.state.secondaryEmail;
        data.push({
            id: data.length,
            value: ''
        })
        this.setState({ secondaryEmail: data })
    }

    onRemove = (index) => {
        let data = this.state.secondaryEmail;
        data.splice(index, 1);
        this.setState({ secondaryEmail: data })
    }

    onFilesChange1 = async files => {
        if (files.length !== 0) {
            let file = files[0];
            const imageDataUrl = await commonFunc.readFile(file);
            this.setState({
                imageType: file.type,
                imageFile: file,
                image: imageDataUrl,
                open: true,
                isProfile: true
            });
        }
    };

    onFilesChange2 = async files => {
        if (files.length !== 0) {
            let file = files[0];
            const imageDataUrl = await commonFunc.readFile(file);
            this.setState({
                coverImageType: file.type,
                coverImageFile: file,
                coverImage: imageDataUrl,
                isProfile: false,
                open: true
            });
        }
    };

    onTempUserUpdate = () => {
        let data = {
            "firstName": this.state.firstName,
            "lastName": this.state.lastName,
            "email": this.state.email,
            "mobileNumber": this.state.mobile,
            "address": this.state.address
        }

        saveTempUser(data).then((res) => {
            // console.log(res)
        })
    }

    onButtonClick = () => {
        if (this.state.open) {
            this.setState({
                open: false,
                [this.state.isProfile ? 'image' : 'coverImage']: this.state.imageCrop
            });
            return;
        }

        if (this.state.step === 1) {
            this.setState({ step: 2 })
            return;
        }
        if (this.state.step === 2) {
            this.setState({ isValidation: true })
            if (validateBuyerRegDetails(this.state, undefined, true, false)) {
                this.checkEmailAvailability();
                this.onTempUserUpdate()
                return;
            }
            return;
        }
        // if (this.state.step === 2 && validateBuyerRegDetails(this.state, undefined, true, false)) {
        //     this.setState({ isValidation: true })
        //     this.checkEmailAvailability();
        //     return;
        // }
        if (this.state.step === 3 && validateCategory(this.state)) {
            this.saveUser();
        }
    }

    checkEmailAvailability = () => {
        checkEmailAvailability(this.state.email)
            .then(response => {
                if (response.success) {
                    this.getCategories();
                } else {
                    if (response.status && (response.status === 600 || response.status === 601)) {
                        this.getCategories();
                    } else {
                        ToastUtil.showErrorToast(response.msg);
                        if (response.status) {
                            this.props.history.push(routes.login, { error: response.status });
                        }
                    }
                }
            })
    }

    saveUser = () => {
        const userType = constants.USER_SELLER;
        register(createUserRegDetails({ ...this.state, userType }))
            .then(response => {
                if (response.success) {
                    // Cookies.set(constants.ACCESS_TOKEN, response.access_token);
                    // localStorage.setItem(constants.ACCESS_TOKEN, response.access_token);
                    // localStorage.setItem(constants.USER_OBJ, JSON.stringify(createUser(response.user)));
                    // this.props.setAuth(createUser(response.user));
                    // this.props.setUserType(constants.USER_SELLER);


                    if (this.state.subscription_plan !== 0) {
                        this.paymentHandler(this.state);
                        this.setState({ step: 4 });
                        this.getB2bDiscount()
                    } else {
                        // this.props.history.push(routes.login);
                        this.onLogin()
                    }
                } else {
                    ToastUtil.showErrorToast(response.msg)
                }
            })
    }

    onLogin = () => {
        const data = {
            username: this.state.email,
            password: this.state.password,
            grant_type: 'password'
        }
        loginUser(qs.stringify(data), this.state.etUser)
            .then(response => {
                if (response.success) {
                    if (!this.state.etUser) {
                        localStorage.setItem(constants.USER_ORIGINAL_OBJ, JSON.stringify(response.user));
                        Cookies.set(constants.ACCESS_TOKEN, response.access_token);
                        localStorage.setItem(constants.ACCESS_TOKEN, response.access_token);
                        localStorage.setItem(constants.USER_OBJ, JSON.stringify(createUser(response.user)));
                        this.props.setAuth(createUser(response.user));
                        this.props.setUserType(constants.USER_SELLER);
                        //this.props.location?.state?.fromReg ? this.props.history.push(routes.home) : this.props.history.goBack();

                        window.location.href = routes.home
                    } else {
                        this.register(response.user)
                    }
                } else {
                    ToastUtil.showErrorToast(response.msg);
                }
            })
    }

    getSubscriptionPackages = () => {
        getSubscriptions()
            .then(response => {
                if (response.success) {
                    this.setState({
                        packages: response.body,
                        subscription_plan: 0
                    })
                } else {
                    ToastUtil.showErrorToast(response.msg)
                }
            })
    }

    onClear = () => {
        this.setState({
            step: 1,
            firstName: '',
            lastName: '',
            email: '',
            password: '',
            confirmPassword: '',
            companyName: '',
            companyUrl: '',
            designation: '',
            address: '',
            location: '',
            website: '',
            billingProof: undefined,
            billingProofType: undefined,
            billingProofFile: undefined,
            category: [],
            imageType: undefined,
            imageFile: undefined,
            image: null,
            coverImageFile: undefined,
            coverImageType: undefined,
            coverImage: null,
            subCategory: [],
            subscription_plan: 1,
            categories: [],
            packages: [],
            cat1: undefined,
            cat2: undefined,
            cat3: undefined,
            nameOnTHeCard: '',
            cardNumber: '',
            expDate: '',
            cvv: '',
            promo: '',
            applyPromo: false,
            viewBreakDown: true,
            secondaryEmail: '',
            catIdArray: [],
            percentage: 0,
            maximumAmount: 0,
            etUserDiscount: 0,
            isValidation: false
        });
        // this.props.close();
    }

    onCropComplete = async (croppedArea, croppedAreaPixels) => {
        const result = await getCroppedImg(this.state.isProfile ? this.state.image : this.state.coverImage, croppedAreaPixels, 0);
        const file = dataURLtoFile(result, 'product-image.jpg')
        file.preview = {
            type: "image",
            url: result
        }
        file.extension = "jpg"
        file.id = "files-1"
        await this.setState({
            imageCrop: result,
            [this.state.isProfile ? 'imageFile' : 'coverImageFile']: file
        })
    }

    onClose = () => {
        if (this.state.open) {
            this.setState({
                [this.state.isProfile ? 'image' : 'coverImage']: null,
                open: false
            })
        } else {
            this.onClear()
        }

        this.getSubscriptionPackages()
    }

    removeImage = type => {
        if (type === 'PROFILE') {
            this.setState({
                imageType: undefined,
                imageFile: undefined,
                image: null,
            })
        } else {
            this.setState({
                coverImageFile: undefined,
                coverImageType: undefined,
                coverImage: null,
            })
        }
    }

    clearSelection = (i) => {
        const catIdArray = [...this.state.catIdArray];
        const catList = []
        this.state.categories.map(cat => {
            if (cat.id === catIdArray[i - 1]) {
                const subCategoryList = []
                cat.subCategory.map(item => {
                    item.checked = false
                    subCategoryList.push(item);
                })
                cat.subCategory = subCategoryList;
            }
            catList.push(cat);
        })
        catIdArray[i - 1] = -1;
        this.setState({
            ["cat" + i]: undefined,
            catIdArray: catIdArray,
            categories: catList
        })
    }

    checkPromoCode = () => {
        if (validatePromoCode(this.state)) {
            checkPromoCode(this.state.promo)
                .then(response => {
                    if (response.success) {
                        this.setState({
                            percentage: response.body.discountPercentage,
                            maximumAmount: response.body.maxDiscount,
                            promoCodeId: response.body.id
                        })
                    } else {
                        this.setState({
                            percentage: 0,
                            maximumAmount: 0,
                            promoCodeId: 0
                        })
                        ToastUtil.showErrorToast(response.msg)
                    }
                })
        }
    }

    paymentHandler = () => {
        let script = document.createElement('script');
        script.setAttribute('src', constants.gatewayUrl);
        document.head.appendChild(script);
        const local = this;
        script.onload = function () {
            WebXPayTokenizeInit(
                {
                    number: "#card-number",
                    securityCode: "#security-code",
                    expiryMonth: "#expiry-month",
                    expiryYear: "#expiry-year",
                    nameOnCard: "#cardholder-name",
                },
                local.afterInit,
            );
        };
        script.onerror = function (e) {
            console.log(e)
        }
    }

    afterInit = (GenerateSession) => {
        const local = this
        $('#save-card-button').click(function () {
            // local.makePayment('SESSION0002618155108J75008010F0')
            GenerateSession(
                function (session) {
                    local.makePayment(session)
                    // this.makePayment(session)
                },
                function (error) {
                    handleErrors(error);
                }
            );
        });
    }

    makePayment = (session) => {
        const plan = this.state.packages[this.state.subscription_plan]
        let discount = Math.round((plan.price * this.state.percentage) / 100).toFixed(2)
        if (discount > this.state.maximumAmount)
            discount = this.state.maximumAmount;
        let totalDiscount = (Number(discount) + this.state.etUserDiscount).toFixed(2)

        const data = {
            email: this.state.email,
            amount: plan.price - totalDiscount,
            sessionId: session,
            promoCodeId: this.state.promoCodeId
        }
        makePayment(data)
            .then(response => {
                if (response.success) {
                    ToastUtil.showSuccessToast(response.msg);
                    // this.onClear();
                    this.onLogin()
                } else {
                    // ToastUtil.showErrorToast(response.msg)

                    swal({
                        title: "Payment failed",
                        text: " Unfortunately, your payment has failed.\n\n An account has been created for you with your details. Please login and navigate to the subscription section and click on 'Renew Package' to make your payment.\n\n If you require assistance, please contact us on 0114326791",
                        icon: null,
                        closeOnClickOutside: false,
                        buttons: {
                            dangerMode: { text: "Ok", value: "action", className: "swalSuccess" }
                        }
                    })
                        .then((value) => {
                            switch (value) {
                                case "action":
                                    window.location = routes.login;
                                    break;
                                default:
                                    break;
                            }
                        });
                }
            })
    }

    render() {
        const userType = constants.USER_SELLER
        return <div className="register-page-area pd-bottom-100 register">
            <div className="container">
                <div className="justify-content-center">
                    <h1 className="page-title">{login.createAcc}</h1>
                    <h1 className="page-subTitle">{login.fewSteps}</h1>
                    <div className="col-12 form pt-4">
                        {
                            this.state.open ?
                                <Cropper
                                    image={this.state.isProfile ? this.state.image : this.state.coverImage}
                                    crop={this.state.crop}
                                    zoom={this.state.zoom}
                                    aspect={this.state.isProfile ? 4 / 4 : 16 / 5}
                                    onCropChange={value => this.setState({ crop: value })}
                                    onZoomChange={value => this.setState({ zoom: value })}
                                    onCropComplete={this.onCropComplete}
                                /> :
                                <>
                                    {
                                        this.state.step !== 1 && this.state.step !== 4 &&
                                        <img className="pointer mb-3" src={Assets.back} alt="back"
                                            onClick={() => this.setState({ step: this.state.step - 1 })} />
                                    }

                                    {
                                        this.state.step !== 4 &&
                                        <Steps current={this.state.step - 1}>
                                            <Step title={isBrowser ? "Subscription Plan" : ""} />
                                            <Step title={isBrowser ? "Basic Information" : ""} />
                                            <Step title={isBrowser ? "Categories" : ""} />
                                        </Steps>
                                    }

                                    {
                                        this.state.step === 1 && this.state.packages.length !== 0 &&
                                        <StepThree
                                            state={{ ...this.state, userType }}
                                            subscription_plan={this.state.subscription_plan}
                                            onSelectSubscription={(index, id) => {
                                                this.setState({
                                                    subscription_plan: index,
                                                    packageId: id
                                                })
                                            }} />
                                    }
                                    {
                                        this.state.step === 2 &&
                                        <StepOne
                                            state={{ ...this.state, userType }}
                                            onTextChange={this.onTextChange}
                                            onAdd={this.onAdd}
                                            onRemove={this.onRemove}
                                            onFilesChange1={this.onFilesChange1}
                                            onFilesChange2={this.onFilesChange2}
                                            removeImage={this.removeImage}
                                            onChangeSecondaryEmail={value => this.setState({ secondaryEmails: value })}
                                            onViewFile={() => commonFunc.getB64ToBlob(this.state.billingProof, this.state.billingProofType)}
                                            onFileChange={this.onFilesChange} />
                                    }
                                    {
                                        this.state.step === 3 && this.state.categories.length !== 0 &&
                                        <>
                                            <StepTwo
                                                state={{ ...this.state, userType }}
                                                categoryCount={this.state.packages[this.state.subscription_plan].categoryCount}
                                                onCategoryChange={this.onCategoryChange}
                                                onSelectAllSubCategory={this.onSelectAllSubCategory}
                                                setCategory={this.setCategory}
                                                clearSelection={this.clearSelection}
                                                active={1}
                                                handleSubCategoryChange={this.handleSubCategoryChange}
                                                onSubCategoryChange={this.onSubCategoryChange}
                                            />
                                        </>
                                    }
                                    {
                                        this.state.step === 4 && this.state.packages.length !== 0 &&
                                        <Payment
                                            state={{ ...this.state }}
                                            onTextChange={this.onTextChange}
                                            applyPromo={this.checkPromoCode}
                                            viewBreakDown={() => this.setState({ viewBreakDown: !this.state.viewBreakDown })}
                                            onSelectSubscription={(id) => this.setState({ subscription_plan: id })}
                                            isB2bDiscount={this.state.suitableForDiscount}
                                        />
                                    }
                                </>
                        }

                        {
                            this.state.step === 3 && this.state.subscription_plan !== 0 ?
                                <p className="warningBeforReg"><span>*</span>note - As you have selected a corporate
                                    package
                                    in sign up process, please settle the payment within today to activate the account.
                                </p> : null
                        }

                        {
                            this.state.step !== 4 &&
                            <div className="display-flex justify-content-end p-3">
                                <button className="btn btn-white mr-3"
                                    onClick={this.onClose}>{registration.cancel}</button>
                                <button className="btn btn-yellow"
                                    onClick={this.onButtonClick}>{this.state.open ? "Crop" : this.state.step !== 3 ? registration.next : this.state.step === 3 && this.state.packageId === 0 || this.state.packageId === 1 ? "Register" : "Register & Pay"}</button>
                            </div>
                        }
                    </div>
                    <p className="dont">{login.alreadyReg}
                        <p className="register"
                            onClick={() => this.props.history.push(routes.login, { fromReg: true })}>&nbsp;{login.login}</p>
                    </p>
                </div>
            </div>
        </div>
    }
}

const mapDispatchToProps = (dispatch) => ({
    setAuth: data => dispatch(userActions.setAuth(data)),
    setUserType: data => dispatch(userActions.setUserType(data)),
});

export default connect(null, mapDispatchToProps)(withRouter(Register));
