import React, { Fragment } from "react";
import './filter.scss'
import { DatePicker, Select, Switch } from "antd";
import constants, { categoryActions, DISTRICTS } from "../../constants/constants";
import moment from "moment";
import { isBrowser } from "react-device-detect";
import { getCategories, getCompanies, getSources } from "../../service/category";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import _ from 'lodash'
import Cookies from "js-cookie";
import { purchaseRequest } from '../../locals/string.json'
import $ from "jquery";
import Flatpickr from "react-flatpickr"
import './flatpickr.scss'

const { Option } = Select;
const { RangePicker } = DatePicker;
let string = purchaseRequest

class Filter extends React.Component {

    state = {
        categories: [],
        sources: [],
        companies: [],
        selectedCategory: -1,
        selectedSubCategory: -1,
        viewSub: false,
        location: null,
        status: null,
        publishedDate: '',
        closingDate: '',
        myCategory: Cookies.get(constants.ACCESS_TOKEN) === undefined ? false : this.props.location.state !== undefined ? this.props.location.state.myCategory !== undefined ? this.props.location.state.myCategory : this.props.currentStatus.myCategory : true,
        source: null,
        company: null,
        companySearch: '',
        isClear: false
    }

    constructor(props) {
        super(props);
        this.getCompanies = _.debounce(this.getCompanies, 500);
    }

    componentWillMount() {
        this.getCategories();
        this.getSources();
        if (this.props.iscompanyName !== '') {
            this.setState({
                company: this.props.iscompanyName
            })
        }

        if (this.props.selectedMainCategory !== null) {
            this.setState({
                selectedCategory: this.props.selectedMainCategory - 1,
                selectedSubCategory: this.props.selectedPopularSubCategory,
                viewSub: true
            })
        }

        const params = this.props.location.state;
        if (params !== undefined) {
            this.setState({ myCategory: false })
            if (params.currentStatus !== undefined) {
                this.getCompanies(this.props.currentStatus.companySearchValue)
                this.setState({
                    source: this.props.currentStatus.source,
                    location: this.props.currentStatus.location,
                    publishedDate: [this.props.currentStatus.filter.publishStart !== null ? new Date(this.props.currentStatus.filter.publishStart) : '', this.props.currentStatus.filter.publishEnd !== null ? new Date(this.props.currentStatus.filter.publishEnd) : ''],
                    closingDate: [this.props.currentStatus.filter.expireStart !== null ? new Date(this.props.currentStatus.filter.expireStart) : '', this.props.currentStatus.filter.expireEnd !== null ? new Date(this.props.currentStatus.filter.expireEnd) : ''],
                    company: this.props.currentStatus.company,
                    viewSub: true,
                    selectedSubCategory: this.props.fintSubCat !== undefined ? this.props.fintSubCat.id : this.props.fintMainCat !== undefined ? this.props.fintMainCat.subCategory[0].id : this.props.selectedMainCategory !== null ? `S${this.props.selectedMainCategory}` : this.props.currentStatus.selectedSubCategory,
                    // selectedCategory: this.props.currentStatus.myCategory ? this.props.selectedMainCategory : this.props.selectedMainCategory - 1,
                    selectedCategory: this.props.fintMainCat !== undefined ? this.props.fintMainCat.id : this.props.selectedMainCategory !== null ? this.props.selectedMainCategory : this.props.currentStatus.findItemId,
                    myCategory: this.props.currentStatus.myCategory,
                    isClear: true
                })
            }
        } else {
            this.setState({
                myCategory: Cookies.get(constants.ACCESS_TOKEN) === undefined ? false : true
            })
        }
    }

    getCategories = () => {
        this.props.category && getCategories(this.state.myCategory ? categoryActions.AVAILABLE : categoryActions.GET_ALL)
            .then(response => {
                if (response.success) {
                    let customArray = [];
                    response.body.map(obj => {
                        let subCustomArray = [];
                        let subArray = obj.subCategory;
                        subCustomArray = [{
                            "id": `${'S' + obj.id}`,
                            "name": "All",
                            "checked": false,
                            "categoryID": obj.id,
                        }, ...subArray];
                        obj.subCategory = subCustomArray;
                        customArray.push(obj)
                    });
                    this.setState({
                        categories: customArray
                    })
                    this.props.onFilterItemChange('categoryList', customArray);
                }
            })
    }

    getSources = () => {
        getSources()
            .then(response => {
                if (response.success) {
                    this.setState({
                        sources: response.body
                    })
                }
            })
    }

    getCompanies = (value) => {
        this.setState({ companySearch: value })
        getCompanies(value)
            .then(response => {
                if (response.success) {
                    this.setState({
                        companies: response.body
                    })
                }
            })

        this.props.onCompanySearch(value)
    }

    onSubCategoryChange = (id, categoryID, itemId) => {
        let checkID = id.toString().startsWith("S");
        if (checkID) {
            this.setState({ selectedSubCategory: id });
            this.props.onFilterItemChange('selectedSubCategory', categoryID, null, true, "ALL", null);
        } else {
            this.setState({ selectedSubCategory: id });
            this.props.onFilterItemChange('selectedSubCategory', id, null, true, "SUB", itemId);
        }
    }

    onDropDownSelect = (name, value) => {
        this.setState({ [name]: value });
        this.props.onFilterItemChange(name, value, null, true);
    }

    onClear = () => {
        this.setState({
            selectedCategory: -1,
            selectedSubCategory: -1,
            viewSub: false,
            location: null,
            status: null,
            source: null,
            company: null,
            publishedDate: '',
            closingDate: '',
            companySearch: '',
            isClear: false
        })
        this.props.onClear()
    }

    render() {
        const { publishedDate, closingDate, advance } = this.props
        function disabledDate(current) {
            return current && current > moment().endOf('day');
        }

        const content = <div className="col-xl-4 col-lg-4 sitebar filter">
            <h6 className="filter-title mb-4">Filter</h6>
            <form className="widget widget-sidebar-search-wrap">
                <div className="widget-sidebar-search">

                    <div className="widget-sidebar-item-wrap rld-single-select">
                        <div className="inputTitle mb-3">Industries</div>

                        {
                            (this.props.categoryFilter && (this.props.loggedInStatus && this.props.userType === constants.USER_SELLER)) &&
                            <>
                                <div className="display-flex flex-row justify-content-between">
                                    <p className="myCat">Show my categories only</p>
                                    <Switch checked={this.state.myCategory}
                                        onChange={async value => {
                                            await this.setState({ myCategory: value });
                                            this.props.onMyCategory('myCategory', value);
                                            this.getCategories();
                                        }} />
                                </div>
                                <div className="separator mb-3" />
                            </>
                        }

                        {
                            this.state.categories.map((item, i) => (
                                <>
                                    {
                                        this.props.currentStatus !== undefined && this.props.currentStatus.selectedPopularSubCategory === null && this.state.isClear ?
                                            <div key={i} className="catHolder"
                                                onClick={() => {
                                                    this.setState({
                                                        viewSub: item.id !== this.state.selectedCategory,
                                                        selectedCategory: item.id !== this.state.selectedCategory ? item.id : -1
                                                    })
                                                }}>
                                                <p className={"catName" + (this.state.selectedCategory === item.id ? " highlight-main" : "")}>{item.name}</p>
                                                <i className="fa fa-chevron-down arrowIcon" />
                                            </div>
                                            :
                                            <div key={i} className="catHolder"
                                                onClick={() => {
                                                    this.setState({
                                                        viewSub: i !== this.state.selectedCategory,
                                                        selectedCategory: i !== this.state.selectedCategory ? i : -1
                                                    })
                                                }}>
                                                <p className={"catName" + (this.state.selectedCategory === i ? " highlight-main" : "")}>{item.name}</p>
                                                <i className="fa fa-chevron-down arrowIcon" />
                                            </div>
                                    }

                                    {

                                        item.subCategory.map((sub, index) => {
                                            if (this.props.currentStatus !== undefined && this.props.currentStatus.selectedPopularSubCategory === null && this.state.isClear) {

                                                if ((this.state.viewSub && this.state.selectedCategory === item.id))
                                                    return (<p
                                                        key={index}
                                                        onClick={() => this.onSubCategoryChange(sub.id, sub.categoryID, item.id)}
                                                        className={"subCat" + (this.state.selectedSubCategory === sub.id ? " highlight" : "")}
                                                    >{sub.name}</p>)
                                            } else {

                                                if ((this.state.viewSub && this.state.selectedCategory === i))
                                                    return (<p
                                                        key={index}
                                                        onClick={() => this.onSubCategoryChange(sub.id, sub.categoryID, item.id)}
                                                        className={"subCat" + (this.state.selectedSubCategory === sub.id ? " highlight" : "")}
                                                    >{sub.name}</p>)
                                            }
                                        })
                                    }
                                </>

                            ))
                        }
                    </div>

                    <div className="widget-sidebar-item-wrap rld-price-slider-wrap">
                        <div className="inputTitle">{string.district}</div>
                        <Select
                            value={this.state.location}
                            showSearch
                            className="select"
                            placeholder={string.district}
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                            filterSort={(optionA, optionB) =>
                                optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                            }
                            onChange={value => this.onDropDownSelect('location', value)}
                        >
                            {
                                DISTRICTS.map((item, i) => (
                                    <Option key={i} value={item}>{item}</Option>
                                ))
                            }
                        </Select>
                    </div>
                    {
                        this.props.loggedInStatus ?
                            <div className="widget-sidebar-item-wrap rld-price-slider-wrap">
                                <div className="inputTitle">Source</div>
                                <Select
                                    value={this.state.source}
                                    showSearch
                                    className="select"
                                    placeholder="Source"
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                    filterSort={(optionA, optionB) =>
                                        optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                    }
                                    onChange={value => this.onDropDownSelect('source', value)}
                                >
                                    {
                                        this.state.sources.map((item, i) => (
                                            <Option key={i} value={item.id}>{item.name}</Option>
                                        ))
                                    }
                                </Select>
                            </div>
                            : null
                    }

                    {
                        this.props.loggedInStatus ?
                            <div className="widget-sidebar-item-wrap rld-price-slider-wrap">
                                <div className="inputTitle">Company/Ministry</div>
                                <Select
                                    value={this.state.company}
                                    showSearch
                                    className="select"
                                    placeholder="Company/Ministry"
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                    filterSort={(optionA, optionB) =>
                                        optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                    }
                                    onChange={value => this.onDropDownSelect('company', value)}
                                    onSearch={value => this.getCompanies(value)}
                                    notFoundContent={this.state.companySearch === '' ? "Type company name" : this.state.companies.length === 0 ? "No companies" : ""}
                                >
                                    {
                                        this.state.companies.map((item, i) => (
                                            <Option key={i} value={item.id}>{item.name}</Option>
                                        ))
                                    }
                                </Select>
                            </div>
                            : null
                    }

                    {
                        this.props.loggedInStatus ?
                            <>
                                {
                                    publishedDate &&
                                    <div className="widget-sidebar-item-wrap rld-price-slider-wrap date-picker-flat">
                                        <div className="inputTitle">Published Date</div>
                                        {/*<RangePicker*/}
                                        {/*    value={this.state.publishedDate}*/}
                                        {/*    placeholder={['From', 'To']}*/}
                                        {/*    // disabledDate={disabledDate}*/}
                                        {/*    onChange={async data => {*/}
                                        {/*        await this.setState({ publishedDate: data });*/}
                                        {/*        this.props.onFilterItemChange('publishedDate', data[0], data[1], true)*/}
                                        {/*    }}*/}
                                        {/*    format={constants.DATE_FORMAT}*/}
                                        {/*    allowClear={false}*/}
                                        {/*/>*/}
                                        <Flatpickr
                                            value={this.state.publishedDate}
                                            onChange={async data => {
                                                await this.setState({ publishedDate: data });
                                                if (data.length > 1) this.props.onFilterItemChange('publishedDate', moment(data[0]), moment(data[1]), true)
                                            }}
                                            placeholder={'From - to'}
                                            options={{
                                                altInput: true,
                                                dateFormat: "Y-m-d",
                                                mode: 'range'
                                            }}
                                        />
                                    </div>
                                }
                            </>
                            : null
                    }

                    {
                        closingDate &&
                        <div className="widget-sidebar-item-wrap rld-price-slider-wrap  date-picker-flat">
                            <div className="inputTitle">Closing Date</div>
                            {/*<RangePicker*/}
                            {/*    value={this.state.closingDate}*/}
                            {/*    placeholder={['From', 'To']}*/}
                            {/*    onChange={async data => {*/}
                            {/*        await this.setState({ closingDate: data });*/}
                            {/*        this.props.onFilterItemChange('closingDate', data[0], data[1], true)*/}
                            {/*    }}*/}
                            {/*    format={constants.DATE_FORMAT}*/}
                            {/*    allowClear={false}*/}
                            {/*/>*/}
                            <Flatpickr
                                value={this.state.closingDate}
                                onChange={async data => {
                                    await this.setState({ closingDate: data });
                                    if (data.length > 1) this.props.onFilterItemChange('closingDate', moment(data[0]), moment(data[1]), true)
                                }}
                                placeholder={'From - to'}
                                options={{
                                    altInput: true,
                                    dateFormat: "Y-m-d",
                                    mode: 'range'
                                }}
                            />
                        </div>
                    }
                </div>
                <div className="btn-wrap text-center">
                    <button type="button" className="btn btn-success" style={{ width: '100%' }}
                        onClick={this.onClear}>CLEAR FILTER
                    </button>
                    {/*<button type="button" className="btn btn-yellow">Apply</button>*/}
                </div>
            </form>
        </div>

        return isBrowser ? content : advance ? content : null
    }
}

const mapStateToProps = (state) => ({
    loggedInStatus: state.user.loggedInStatus,
    userType: state.user.userType,
});

export default connect(mapStateToProps, null)(withRouter(Filter));
