import {__DEV__} from "../constants/constants";

const dev = 'https://b2b-et.ceyentra.lk';
const testing = 'https://b2b-et-test.ceyentra.lk';
const production = 'https://api.etenders.lk';

const conf = {
    // for testing server
    // serverUrl: testing,
    // basePath: `etenders-api`,

    // for production server
    serverUrl: production,
    basePath: 'api',

    version: `v1/`,
    frontEnd: 'www.b2b.lk/supplier/'
};

export default conf;
