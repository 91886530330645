import React, { Component } from 'react';
import * as strings from '../../locals/string.json';
import { Assets } from "../../assets";
import { categoryActions } from "../../constants/constants";
import './banner.scss'
import routes from "../../constants/routes";
import Statistics from "../statistics/statistics";
import { getCategories } from "../../service/category";
import Select from "react-select";

class Banner extends Component {

    state = {
        modelOpen: false,
        search: '',
        categories: [],
        subCategory: undefined
    }

    componentWillMount() {
        getCategories(categoryActions.GET_ALL)
            .then(response => {
                if (response.success) {
                    this.setState({
                        categories: response.body
                    })
                }
            })
    }

    onChangeSubCategory = (item) => {
        if (item !== null) {
            this.setState({ subCategory: item.value })
        } else {
            this.setState({ subCategory: undefined })
        }
    }

    findTender = () => {
        this.props.history.push(routes.requests, { subCategory: this.state.subCategory, search: this.state.search, myCategory: false });
    }

    render() {
        const inlineStyle = {
            backgroundImage: `url(${Assets.landing})`
        }
        const options = [];
        this.state.categories.map((cate, index) => {
            cate.subCategory.map((subCate, subCateIndex) => {
                options.push({ sub: subCate, value: subCate.id, label: cate.name + " - " + subCate.name })
            })
        })

        return <div className="banner-area jarallax pd-bottom-60" style={inlineStyle}>
            <div>

                <div className="display-flex justify-content-end" style={{ width: '100%' }}>
                    <img className="et-awards" src={Assets.awarded} alt="awards" />
                </div>

                <div className="container">
                    <div className="row">
                        <div className="col-lg-9 col-md-12">
                            <div className="banner-inner-wrap">
                                <div className="banner-inner">
                                    {/*<h1 className="title">{strings.landing.title1} <br/> {strings.landing.title2}</h1>*/}
                                    {/*<h1 className="landingSub">Serving you since 2012</h1>*/}
                                    <h2 className={'banner-title-1'}>{strings.landing.title1}</h2>
                                    <h2 className="banner-title-2">{strings.landing.title2}</h2>
                                    <p className={'banner-para'}>We at eTenders take pride in our services which we
                                        have
                                        had the pleasure of
                                        providing to you - our valued customers since 2012.</p>
                                </div>
                            </div>
                        </div>
                        <div className={'col-lg-3'}>
                            <img src={Assets.bannerSquare} className={'banner-square'} />
                        </div>
                    </div>

                    <div className={'row'}>
                        <div className="col-lg-8 col-md-12">
                            <div className="banner-search-wrap">
                                <div className="tab-content">
                                    <div className="tab-pane fade show active" id="tabs_1">
                                        <div className="form-container">
                                            {/*<h2 className="title">{strings.landing.findTenders}</h2>*/}
                                            <div className="row">
                                                <div className="col-xl-6 col-lg-6 col-md-12 mb-3">
                                                    <div className="rld-single-input left-icon">
                                                        <input type="text"
                                                            onKeyDown={e => e.key === 'Enter' && this.findTender()}
                                                            onChange={event => this.setState({ search: event.target.value })}
                                                            value={this.state.search}
                                                            placeholder={strings.landing.webInputPlaceholder} />
                                                    </div>
                                                </div>
                                                <div className="col-xl-6 col-lg-6 col-md-12">
                                                    <Select
                                                        className="basic-single mb-3"
                                                        classNamePrefix="select"
                                                        isSearchable={true}
                                                        isClearable={true}
                                                        options={options}
                                                        placeholder={'Select Category'}
                                                        onChange={(v) => this.onChangeSubCategory(v)}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/*<Statistics/>*/}
                        </div>
                    </div>

                    <div className="col-xl-2 col-lg-2 col-md-12 mt-4 mobile-content readeal-top">
                        <button
                            onClick={() => this.findTender()}
                            className="btn btn-findTender"
                            style={{ height: '48 !important', marginLeft: -15 }}>{strings.landing.findNow}</button>
                    </div>
                </div>

            </div>

        </div>
    }
}

export default Banner
