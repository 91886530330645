/**
 * Created by WebStorm.
 * User: at7/4/20
 * Time: 5:02 PM
 */
import * as actionTypes from './actionType';
import constants from "../../../constants/constants";

const initialState = {
    user: null,
    loggedInStatus: false,
    userType: constants.USER_GUEST,
    userData: null
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_AUTH:
            return {
                ...state,
                loggedInStatus: true,
                user: action.value,
            };
        case actionTypes.SET_USER_TYPE:
            return {
                ...state,
                userType: action.value,
            };
        case actionTypes.SET_USER:
            return {
                ...state,
                user: action.value,
            };
        case actionTypes.SET_LOGIN_STATUS:
            return {
                ...state,
                loggedInStatus: action.value,
            };

        case actionTypes.UPDATE_USER:
            return {
                ...state,
                userData: action.value,
            };

        default:
            return state;
    }
};

export default reducer;
