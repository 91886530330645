export const Assets = {
    cardBg3: require('./img/cardBg4.png'),
    categoryPlaceholder: require('./img/categoryPlaceholder.png'),

    logo: require('./img/logo.png'),
    logoWhite: require('./img/etenders_logo_w.svg'),
    statisticsBg: require('./img/statisticsBg.png'),
    landing: require('./img/etenders_main_banner.jpg'),
    subBanner: require('./img/subBanner.png'),
    subBanner2: require('./img/subBanner2.png'),
    bannerSquare: require('./img/etenders_square_only.jpg'),
    regBuyer: require('./img/regBuyer.png'),
    regSeller: require('./img/regSeller.png'),
    back: require('./img/back.png'),
    biCheck: require('./img/biCkeck.png'),
    cardBg1: require('./img/cardBg1.png'),
    cardBg2: require('./img/cardBg3.png'),
    cardBg4: require('./img/cardBg4.png'),
    cardBg5: require('./img/package-top.png'),
    cat: require('./img/cat.png'),
    clientTest: require('./img/clientTest.png'),
    advFilter: require('./img/advFilter.png'),
    companyLogo: require('./img/companyLogo.png'),
    about1: require('./img/about1.png'),
    about2: require('./img/about2.png'),
    about3: require('./img/6.png'),
    vision: require('./img/vision.png'),
    target: require('./img/target.png'),
    empty: require('./img/no-data.png'),
    awarded: require('./img/awarded.png'),
    placeholderCompany: require('./img/placeholderCompany.png'),
    avatarPlaceholder: require('./img/avatarPlaceholder.png'),
    pt1: require('./img/pt1.png'),
    pt2: require('./img/pt2.png'),
    pt3: require('./img/pt3.png'),
    pt4: require('./img/pt4.png'),
    c1: require('./img/1.png'),
    c2: require('./img/2.png'),
    c3: require('./img/3.png'),
    c4: require('./img/4.png'),
    c5: require('./img/5.png'),
    blog1: require('./img/blog1.png'),
    blog2: require('./img/blog2.png'),
    blog3: require('./img/blog3.png'),
    blogBg: require('./img/blogBg.png'),
    aboutBg: require('./img/aboutBg.png'),
    sunday: require('./img/sunday.png'),
    dailyMirror: require('./img/dailyMirror.png'),
    readme: require('./img/readme.png'),
    comma: require('./img/comma.png'),
    comma2: require('./img/comma2.png'),
    productBack: require('./img/productBack.jpg'),
    test: require('./img/test.png'),
    ad: require('./img/ad.png'),
    sell: require('./img/9.png'),
    rent: require('./img/10.png'),
    aboutUsVideo: require('./img/aboutUsVideo.png'),
    aboutUsVideo2: require('./img/aboutUsVideo-2.png'),

    // clientLogo1:require('./img/client-logos/logo (1).jfif'),
    // clientLogo2:require('./img/client-logos/logo (1).jpg')
    // clientLogo3:require('./img/client-logos/logo (1).png')
    // clientLogo4:require('./img/client-logos/logo (2).jfif')
    // clientLogo5:require('./img/client-logos/')
    // clientLogo6:require('./img/client-logos/logo (1).jfif')
    // clientLogo7:require('./img/client-logos/logo (1).jfif')
    // clientLogo8:require('./img/client-logos/logo (1).jfif')
    // clientLogo9:require('./img/client-logos/logo (1).jfif')
    // clientLogo10:require('./img/client-logos/logo (1).jfif')
    // clientLogo11:require('./img/client-logos/logo (1).jfif')
    // clientLogo12:require('./img/client-logos/logo (1).jfif')
    // clientLogo13:require('./img/client-logos/logo (1).jfif')
    // clientLogo14:require('./img/client-logos/logo (1).jfif')
    // clientLogo15:require('./img/client-logos/logo (1).jfif')
}
