import React from "react";
import {Modal} from "react-bootstrap";
import './oauth.scss'
import 'antd/dist/antd.css';
import strings from "../../locals/string.json";
import {Assets} from "../../assets";
import {validatePasswordReset} from "../../constants/validation";
import {resetPassword} from "../../service/auth";
import {ToastUtil} from "../../constants/toastUtil";

const registration = strings.registration;

class ResetPasswordModal extends React.Component {

    state = {
        oldPassword: '',
        password: '',
        confirmPassword: '',
    }

    onTextChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        })
    }


    onButtonClick = () => {
        if (validatePasswordReset(this.state)) {
            resetPassword({
                oldPassword: this.state.oldPassword,
                newPassword: this.state.password
            }).then(response => {
                if (response.success) {
                    this.setState({oldPassword: '', password: '', confirmPassword: ''});
                    ToastUtil.showSuccessToast(response.msg);
                    this.props.close();
                } else {
                    ToastUtil.showErrorToast(response.msg);
                }
            })
        }
    }

    render() {
        return <Modal
            className="register forgot"
            show={this.props.open}
            onHide={this.props.close}
            backdrop="static"
            keyboard={false}
            size="md"
            style={{paddingLeft: 0}}
        >
            <Modal.Header closeButton={false}>
                {
                    this.state.step === 2 &&
                    <img className="pointer" src={Assets.back} alt="back"
                         onClick={() => this.setState({step: this.state.step - 1})}/>
                }
                <Modal.Title>Reset Your Password</Modal.Title>
            </Modal.Header>
            <Modal.Body>

                <p className="sub-title mt-4">Follow these simple steps to reset your password:</p>
                <p className="steps">1. Enter your current password.</p>
                <p className="steps">2. Enter your new password with 8 digits.</p>

                <div className="form-group rld-single-input mt-3 mb-4">
                    <input type="password" name="oldPassword" required="required" onChange={this.onTextChange}
                           value={this.state.oldPassword}/>
                    <label>{registration.oldPassword}</label>
                </div>
                <div className="form-group rld-single-input mb-4">
                    <input type="password" name="password" required="required" onChange={this.onTextChange}
                           value={this.state.password}/>
                    <label>{registration.newPassword}</label>
                </div>
                <div className="form-group rld-single-input mb-4">
                    <input type="password" name="confirmPassword" required="required"
                           onChange={this.onTextChange}
                           value={this.state.confirmPassword}/>
                    <label>{registration.confirmPassword}</label>
                </div>

                <button className="btn btn-yellow"
                        onClick={this.onButtonClick}>
                    {registration.resetPW}
                </button>
                <p className="dont">
                    <p className="register" onClick={() => {
                        this.setState({
                            oldPassword: '',
                            password: '',
                            confirmPassword: '',
                        })
                        this.props.close();
                    }}>Close</p>
                </p>


            </Modal.Body>
        </Modal>
    }
}

export default ResetPasswordModal;
