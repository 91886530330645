import React, { Fragment } from "react";
import './requests.scss'
import strings from "../../locals/string.json";
import { Assets } from "../../assets";
import 'antd/dist/antd.css';
import RequestItem from "./requestItem";
import ReactPaginate from 'react-paginate';
import { connect } from "react-redux";
import { withRouter } from "react-router";
import Filter from "../filter/filter";
import { isBrowser } from "react-device-detect";
import { ToastUtil } from "../../constants/toastUtil";
import { favRequest, getRequest, suggestTenders } from "../../service/request";
import { getCategories } from "../../service/category";
import * as common from '../../constants/commonFunc'
import moment from "moment";
import Cookies from "js-cookie";
import constants, { categoryActions, DISTRICTS } from "../../constants/constants";
import _ from 'lodash'


const pr = strings.purchaseRequest;


class AllRequests extends React.Component {
    constructor(props) {
        super(props);
        this.getsuggestTenders = _.debounce(this.getsuggestTenders, 500);
    }

    state = {
        advance: false,
        requests: [],
        action: "LIVE",
        modelOpen: false,
        myCategory: false,
        index: 0,
        size: 10,
        totalPages: 0,
        totalElements: 0,
        search: '',
        selectedSubCategory: null,
        location: null,
        publishedDate: null,
        closingDate: null,
        categoryList: [],
        source: null,
        company: null,
        companyName: '',
        filter: {
            publishStart: null,
            publishEnd: null,
            expireStart: null,
            expireEnd: null
        },
        isNoData: false,
        selectedMainCategory: null,
        selectedPopularSubCategory: null,
        autosuggestList: [],
        etTenderCountDTO: {},
        categories: [],
        allsubCategories: [],
        companySearchValue: '',
        findItemId: null
    }

    componentWillMount() {
        this.getCategories();
        const params = this.props.location.state;
        if (params !== undefined) {
            if (params.currentStatus === undefined) {
                this.setState({
                    search: params.search !== undefined ? params.search : '',
                    selectedSubCategory: params.subCategory,
                    company: params.id !== undefined ? params.id : null,
                    companyName: params.name !== undefined ? params.name : '',
                    selectedMainCategory: params.selectedMainCategory !== undefined ? params.selectedMainCategory : null,
                    selectedPopularSubCategory: params.selectedPopularSubCategory !== undefined ? params.selectedPopularSubCategory : null,
                    myCategory: false
                })
            } else {
                this.state = this.props.location.state.currentStatus
            }
        } else {
            this.setState({
                myCategory: Cookies.get(constants.ACCESS_TOKEN) === undefined ? false : true
            })
        }
    }

    getRequest = () => {
        this.setState({ autosuggestList: [] })
        getRequest(this.state.index, this.state.size, {
            action: this.state.action,
            location: this.state.location,
            name: this.state.search.trim(),
            source: this.state.source,
            company: this.state.company,
            category: this.state.selectedMainCategory,
            subCategory: this.state.selectedSubCategory,
            availability: this.state.myCategory,
            publishedDateStart: this.state.filter.publishStart,
            publishedDateEnd: this.state.filter.publishEnd,
            closingDateStart: this.state.filter.expireStart,
            closingDateEnd: this.state.filter.expireEnd
        })
            .then(response => {
                if (response.success) {
                    this.setState({
                        requests: response.body.content,
                        totalPages: response.body.totalPages,
                        totalElements: response.body.totalElements,
                        isNoData: response.body.content.length === 0,
                        etTenderCountDTO: response.etTenderCountDTO
                    })
                    document.querySelector('body').scrollTo(0, 0)
                } else {
                    this.setState({
                        isNoData: true
                    })
                    ToastUtil.showErrorToast(response.msg);
                }
            })
    }

    getsuggestTenders = () => {
        suggestTenders(this.state.search.trim())
            .then(response => {
                if (response.success) {
                    this.setState({
                        autosuggestList: response.body
                    })
                } else {
                    this.setState({
                        autosuggestList: []
                    })
                }
            })
    }

    onPageSelect = async data => {
        await this.setState({ index: data.selected });
        this.getRequest();
    }

    onFilterBtn = async action => {
        await this.setState({
            action: action,
            index: 0
        });
        this.getRequest();
    }

    onSeeAllBtn = async action => {
        await this.setState({
            action: action,
            index: 0,
            search: ''
        });
        this.getRequest();
    }

    searchHandler = async (value) => {
        await this.setState({ search: value })
        this.getsuggestTenders();
    }

    titleClickHandler = async (title) => {
        this.setState({ search: title })
        document.querySelector('body').scrollTo(0, 0)
    }

    onFilterItemChange = async (name, value, value1, allow, type, itemId) => {
        if (allow) {
            let dates = await common.getConvertedUTCDateTIme(value, value1)
            if (name === 'publishedDate') {
                await this.setState({
                    ...this.state,
                    filter: {
                        ...this.state.filter,
                        publishStart: dates.dateStart,
                        publishEnd: dates.dateEnd,
                    }
                });
            } else if (name === 'closingDate') {
                await this.setState({
                    ...this.state,
                    filter: {
                        ...this.state.filter,
                        expireStart: dates.dateStart,
                        expireEnd: dates.dateEnd
                    }
                });
            } else if (name === 'selectedSubCategory') {
                if (type === "SUB") {
                    await this.setState({
                        ...this.state,
                        [name]: value,
                        selectedMainCategory: null,
                        findItemId: itemId
                    });
                } else {
                    await this.setState({
                        ...this.state,
                        [name]: null,
                        selectedMainCategory: value
                    });
                }
            } else {
                await this.setState({
                    ...this.state,
                    [name]: value
                });
            }
        }
        this.getRequest();
    }

    onCompanySearch = async (value) => {
        await this.setState({
            ...this.state,
            companySearchValue: value
        });
    }

    onMyCategory = async (name, value) => {
        await this.setState({
            ...this.state,
            [name]: value,
            index: 0
        });
    }

    onClear = async () => {
        await this.setState({
            selectedSubCategory: null,
            location: null,
            publishedDate: null,
            closingDate: null,
            search: '',
            source: null,
            company: null,
            companyName: '',
            selectedMainCategory: null,
            selectedPopularSubCategory: null,
            filter: {
                publishStart: null,
                publishEnd: null,
                expireStart: null,
                expireEnd: null
            },
            categories: [],
            allsubCategories: [],
            companySearchValue: '',
            findItemId: null
        });

        this.getRequest();
    }

    onFav = (id, status) => {
        favRequest(id, status ? 'NOT_INTERESTED' : 'INTERESTED')
            .then(response => {
                if (response.success) {
                    this.getRequest();
                } else {
                    ToastUtil.showSuccessToast(response.msg)
                }
            })
    }

    getCategories = () => {
        getCategories(categoryActions.GET_ALL)
            .then(response => {
                if (response.success) {
                    let customArray = [];
                    response.body.map(obj => {
                        let subCustomArray = [];
                        let subArray = obj.subCategory;
                        subCustomArray = [{
                            "id": `${'S' + obj.id}`,
                            "name": "All",
                            "checked": false,
                            "categoryID": obj.id,
                        }, ...subArray];
                        obj.subCategory = subCustomArray;
                        customArray.push(obj)
                    });

                    let allsubCategories = [];
                    response.body.forEach((item, index, arr) => {
                        allsubCategories = allsubCategories.concat(item.subCategory);
                    });

                    this.setState({
                        categories: customArray,
                        allsubCategories: allsubCategories
                    })
                }
            })
    }

    render() {
        const { etTenderCountDTO } = this.state
        let fintMainCat;
        let fintSubCat;

        if (this.state.categories !== undefined && this.state.categories !== null && this.state.categories.length !== 0) {
            fintMainCat = this.state.categories.find(cat => cat.id === this.state.selectedMainCategory)
        }

        if (this.state.allsubCategories !== undefined && this.state.allsubCategories !== null && this.state.allsubCategories.length !== 0) {
            fintSubCat = this.state.allsubCategories.find(cat => cat.id === this.state.selectedSubCategory)
            this.state.categories.map((item, index) => {
                if (fintSubCat !== undefined) {
                    if (item.subCategory.find(cat => cat.id === fintSubCat.id)) {
                        fintMainCat = item
                    }
                }
            })
        }

        return <div>
            <div className="main-search-area requests">
                <div className="container">
                    <div className="banner-search-wrap">
                        <div className="tab-content">
                            <div className="tab-pane fade show active" id="tabs_1">
                                <div className="rld-main-search">
                                    <div className={'row'}>
                                        <div className="titleHolder col-md-3 mb-2">
                                            <h8 className="searchTitle-new">{pr.findRequest}</h8>
                                        </div>
                                        <div className="col-xl-7 col-lg-7 col-md-6">
                                            <div className="rld-single-input left-icon">
                                                <Fragment>
                                                    <input
                                                        // onKeyDown={e => e.key === 'Enter' && this.getRequest()} //search with all action types 
                                                        onKeyDown={e => e.key === 'Enter' && this.onFilterBtn('LIVE')}  //search with LIVE action type
                                                        onChange={event => this.searchHandler(event.target.value)}
                                                        type="text" placeholder={isBrowser ? pr.searchPH : pr.searchPH5}
                                                        value={this.state.search} />
                                                    <div style={{ backgroundColor: '#fff' }}>
                                                        <div>
                                                            {this.state.autosuggestList.length !== 0 ?
                                                                this.state.autosuggestList.map((val, index) => {
                                                                    return (
                                                                        <p onClick={() => this.titleClickHandler(val.title)} className="suggest-list-content mt-2">{val.title}</p>
                                                                    )
                                                                }) : null}
                                                            {this.state.autosuggestList.length !== 0 ?
                                                                <div className="mb-2">
                                                                    <button type={"button"}
                                                                        onClick={() => this.onSeeAllBtn('LIVE')}
                                                                        style={{ height: 0 }} //search with LIVE action type
                                                                        className="btn-seeAll custom-seeAllBtn">{pr.seeAll}</button>
                                                                </div>
                                                                : null}
                                                        </div>
                                                    </div>
                                                </Fragment>
                                            </div>
                                        </div>
                                        <div className="col-xl-2 col-lg-2 col-md-3 col-xs-12" align={'center'}>
                                            <button type={"button"}
                                                // onClick={this.getRequest} //search with all action types
                                                onClick={() => this.onFilterBtn('LIVE')} //search with LIVE action type
                                                className="btn btn-yellow searchBtn">{pr.findNow}</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row justify-content-center mg-top-40 ">

                        <div className="display-flex justify-content-end" style={{ width: '95%' }}>
                            {
                                !isBrowser ? this.state.advance ?
                                    <h6 className="advanceFilter"
                                        onClick={() => this.setState({ advance: !this.state.advance })}>{pr.hide}</h6> :
                                    <img className="advanceFilter" src={Assets.advFilter}
                                        alt="advance filter"
                                        onClick={() => this.setState({ advance: !this.state.advance })} /> : null
                            }
                        </div>

                        <Filter
                            fintMainCat={fintMainCat}
                            fintSubCat={fintSubCat}
                            myCategory={this.state.myCategory}
                            currentStatus={this.state}
                            categoryFilter={true}
                            category={true}
                            location={true}
                            publishedDate={true}
                            closingDate={true}
                            advance={this.state.advance}
                            onClear={this.onClear}
                            onFilterItemChange={this.onFilterItemChange}
                            onCompanySearch={this.onCompanySearch}
                            onMyCategory={this.onMyCategory}
                            iscompanyName={this.state.companyName}
                            selectedMainCategory={this.state.selectedMainCategory}
                            selectedPopularSubCategory={this.state.selectedPopularSubCategory}
                        />
                        <div className="col-xl-8 col-lg-8">
                            <div className="row ">
                                <div className="col-12 mb-3">
                                    <div className="row custom-gutter mt-3">
                                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                            <div className="property-filter-menu-wrap">
                                                <div className="property-filter-menu mb-0 portfolio-filter ">
                                                    {/*<button*/}
                                                    {/*    onClick={() => this.onFilterBtn('ALL')}*/}
                                                    {/*    className={this.state.action === "ALL" ? "active" : ''}>ALL*/}
                                                    {/*</button>*/}
                                                    <button
                                                        className={this.state.action === "LIVE" ? "active" : ''}
                                                        onClick={() => this.onFilterBtn('LIVE')}>LIVE ({etTenderCountDTO.liveTenderCount === undefined ? 0 : etTenderCountDTO.liveTenderCount})
                                                    </button>

                                                    <button
                                                        className={this.state.action === "LATEST" ? "active" : ''}
                                                        onClick={() => this.onFilterBtn('LATEST')}>LATEST ({etTenderCountDTO.latestTenderCount === undefined ? 0 : etTenderCountDTO.latestTenderCount})
                                                    </button>

                                                    {
                                                        this.props.loggedInStatus &&
                                                        <>
                                                            <button
                                                                className={this.state.action === "FAV" ? "active" : ''}
                                                                onClick={() => this.onFilterBtn('FAV')}>FAVOURITES ({etTenderCountDTO.favouriteTenderCount === undefined ? 0 : etTenderCountDTO.favouriteTenderCount})
                                                            </button>
                                                            <button
                                                                className={this.state.action === "WON" ? "active" : ''}
                                                                onClick={() => this.onFilterBtn('WON')}>WON ({etTenderCountDTO.wonTenderCount === undefined ? 0 : etTenderCountDTO.wonTenderCount})
                                                            </button>
                                                            <button
                                                                className={this.state.action === "LOST" ? "active" : ''}
                                                                onClick={() => this.onFilterBtn('LOST')}>LOST ({etTenderCountDTO.lostTenderCount === undefined ? 0 : etTenderCountDTO.lostTenderCount})
                                                            </button>
                                                        </>
                                                    }

                                                    <button
                                                        className={this.state.action === "EXPIRED" ? "active" : ''}
                                                        onClick={() => this.onFilterBtn('EXPIRED')}>EXPIRED ({etTenderCountDTO.expiredTenderCount === undefined ? 0 : etTenderCountDTO.expiredTenderCount})
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        {/* <div
                                            className={'col-xl-2 col-lg-3 col-md-3 col-sm-3 col-xs-12 text-right mt-3'}>
                                            <h6 className="filter-title mb-lg-0">{
                                                this.state.totalElements !== 0 &&
                                                this.state.totalElements + (this.state.totalElements > 1 ? ' TENDERS' : " TENDER")
                                            }&nbsp;</h6>
                                        </div> */}
                                    </div>
                                </div>
                                {
                                    this.state.requests.map((item, index) => (
                                        !this.state.myCategory ? <div className="col-md-12 col-sm-12">
                                            <RequestItem
                                                currentStatus={this.state}
                                                key={index}
                                                onFav={() => this.onFav(item.id, item.fav)}
                                                history={this.props.history}
                                                item={{ ...item, owner: this.props.myRequest }} />
                                        </div> : (
                                            item.availability &&
                                            <div className="col-md-12 col-sm-12">
                                                <RequestItem
                                                    currentStatus={this.state}
                                                    key={index}
                                                    onFav={() => this.onFav(item.id, item.fav)}
                                                    history={this.props.history}
                                                    item={{ ...item, owner: this.props.myRequest }} />
                                            </div>
                                        )
                                    ))
                                }

                                {
                                    this.state.requests.length === 0 && this.state.isNoData ?
                                        <div className="display-flex flex-column  align-items-center"
                                            style={{ width: '100%' }}>
                                            <img src={Assets.empty} alt="empty" />
                                        </div> : null
                                }

                            </div>
                        </div>
                    </div>

                    <div className="display-flex justify-content-center" style={{ width: '100%' }}>
                        {
                            this.state.requests.length !== 0 &&
                            <ReactPaginate
                                previousLabel={'<'}
                                nextLabel={'>'}
                                breakLabel={'...'}
                                breakClassName={'break-me'}
                                pageCount={this.state.totalPages}
                                marginPagesDisplayed={1}
                                pageRangeDisplayed={1}
                                onPageChange={this.onPageSelect}
                                containerClassName={'pagination'}
                                activeClassName={'active'}
                                forcePage={this.state.index}
                            />
                        }
                    </div>
                </div>
            </div>

        </div>
    }
}

const mapStateToProps = (state) => ({
    loggedInStatus: state.user.loggedInStatus,
    userType: state.user.userType,
});

export default connect(mapStateToProps, null)(withRouter(AllRequests));
