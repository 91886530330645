import React, { Component } from 'react';
import './globalComponents.scss'
import { breakPoint, partners } from "../../constants/constants";
import Slider from "react-slick";
import strings from "../../locals/string.json";
import routes from "../../constants/routes";
import { Link } from 'react-router-dom';

const landing = strings.landing;
// export default (props) => {

const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 3000,
    slidesToShow: 4,
    swipeToSlide: true,
    slidesToScroll: 1,
    responsive: breakPoint
};

class Partners extends Component {
    findTender = (id, name) => {
        this.props.history.push(routes.requests, { subCategory: null, search: "", id: id, name: name, myCategory: false });
    }
    render() {
        return <div className="properties-area pd-top-60 popularSupplier">
            <div className="container">
                <div className="partner-title section-title">
                    <span className="txt-sub-title">OUR</span>
                    <h2 className="title">{landing.partners}</h2>
                </div>
                <Slider {...settings}>
                    {partners.map((item, i) =>
                        // <div>
                        //     <Link to={routes.requests}>
                        <div onClick={() => this.findTender(item.id, item.name)} className="p-2 cursor-handle" key={i}>
                            <div className="single-feature partnerHolder">
                                <img className="cat" src={item.image} alt="img" />
                                <h6 className="title readeal-top">{item.name}</h6>
                            </div>
                        </div>
                        //     </Link>
                        // </div>
                    )}
                </Slider>
            </div>
        </div>
    }
}

export default Partners
